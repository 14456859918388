import { createSlice } from '@reduxjs/toolkit';
import Handsontable from 'handsontable';

const initialState = {
  spreadsheet: {
    initData: Handsontable.helper.createEmptySpreadsheetData(20, 3),
    isModalOpen: false
  },
  person: {
    isModalOpen: false,
    ticketExceedingAmount: 0
  },
  manualAllocation: {
    isModalOpen: false,
    editMode: false
  },
  removeTickets: { isModalOpen: false },
  editMode: false,
  recipientToBeEdited: [],
  recipients: [],
  saveDataLoaded: false,
  ticketAmount: 0,
  timeOfSave: {},
  shouldTicketsRefresh: false,
  shouldEventsRefresh: false,
  isVerifiedAccountModalOpen: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addRecipients: (state, action) => {
      state.recipients.push(...action.payload);
    },
    updateTicketAmount: (state, action) => {
      state.ticketAmount = action.payload;
    },
    openModal: (state, action) => {
      if (action.payload === 'spreadsheet') {
        state.person.isModalOpen = false;
        state.manualAllocation.isModalOpen = false;
        state.spreadsheet.isModalOpen = true;
      } else if (action.payload === 'person') {
        state.spreadsheet.isModalOpen = false;
        state.manualAllocation.isModalOpen = false;
        state.person.isModalOpen = true;
      } else if (action.payload === 'manual') {
        state.spreadsheet.isModalOpen = false;
        state.person.isModalOpen = false;
        state.manualAllocation.isModalOpen = true;
      } else if (action.payload === 'removeTickets') {
        state.spreadsheet.isModalOpen = false;
        state.person.isModalOpen = false;
        state.manualAllocation.isModalOpen = false;
        state.removeTickets.isModalOpen = true;
      }
    },
    closeModal: (state, action) => {
      if (action.payload === 'spreadsheet') {
        state.spreadsheet.isModalOpen = false;
      } else if (action.payload === 'person') {
        state.person.isModalOpen = false;
      } else if (action.payload === 'manual') {
        state.manualAllocation.isModalOpen = false;
      } else if (action.payload === 'removeTickets') {
        state.removeTickets.isModalOpen = false;
      } else {
        state.editMode = false;
        state.spreadsheet.isModalOpen = false;
        state.person.isModalOpen = false;
        state.manualAllocation.isModalOpen = false;
        state.removeTickets.isModalOpen = false;
      }
    },
    enableEditMode: (state, action) => {
      if (state.recipients.length > 0) {
        state.editMode = true;
        state.person.isModalOpen = true;
        state.recipientToBeEdited = state.recipients.filter((recipient) => recipient.recipientId === action.payload);
      }
    },
    enableManualAllocationEditMode: (state, action) => {
      state.manualAllocation.editMode = true;
      state.manualAllocation.isModalOpen = true;
      state.recipientToBeEdited = state.recipients.filter((recipient) => recipient.recipientId === action.payload);
    },
    disableManualAllocationEditMode: (state, action) => {
      state.manualAllocation.editMode = false;
      state.manualAllocation.isModalOpen = false;
    },
    updateRecipient: (state, action) => {
      if (state.recipients.length > 0) {
        const index = state.recipients.findIndex((recipient) => recipient.recipientId === action.payload.recipientId);
        state.recipients[index] = action.payload;
        state.editMode = false;
        state.person.isModalOpen = false;
      }
    },
    removeRecipient: (state, action) => {
      if (state.recipients.length > 0) {
        const index = state.recipients.findIndex((recipient) => recipient.recipientId === action.payload);
        const amountIncreased = index > -1 ? state.recipients[index]?.seatCount : 0;
        state.ticketAmount += Number(amountIncreased);
        state.recipients = state.recipients.filter((recipient) => recipient.recipientId !== action.payload);
        state.recipientToBeEdited = state.recipientToBeEdited.filter(
          (recipient) => recipient.recipientId !== action.payload
        );
        state.manualAllocation.editMode = false;
      }
    },
    removeRecipients: (state, action) => {
      const recipientsToRemove = action.payload;
      state.recipients = state.recipients.filter((recipient) => {
        return recipientsToRemove.every((recipientId) => recipientId !== recipient.recipientId);
      });
    },
    updateTicketExceedingAmount: (state, action) => {
      state.person.ticketExceedingAmount = action.payload;
    },
    removeAllRecipients: (state, action) => {
      state.recipients = [];
    },
    setSaveData: (state, action) => {
      state.saveDataLoaded = action.payload;
    },
    setTimeOfSave: (state, action) => {
      const { gamePk, time } = action.payload;
      state.timeOfSave[gamePk] = time;
    },
    setShouldTicketsRefresh: (state, action) => {
      state.shouldTicketsRefresh = action.payload;
    },
    setShouldEventsRefresh: (state, action) => {
      state.shouldEventsRefresh = action.payload;
    },
    setIsVerifiedAccountModalOpen: (state, action) => {
      state.isVerifiedAccountModalOpen = action.payload;
    }
  }
});

export const {
  openModal,
  closeModal,
  updateTicketAmount,
  addRecipients,
  enableEditMode,
  updateRecipient,
  removeRecipient,
  removeRecipients,
  updateTicketExceedingAmount,
  removeAllRecipients,
  setSaveData,
  setTimeOfSave,
  enableManualAllocationEditMode,
  disableManualAllocationEditMode,
  setShouldTicketsRefresh,
  setShouldEventsRefresh,
  setIsVerifiedAccountModalOpen
} = modalSlice.actions;
export default modalSlice.reducer;
export const selectPeopleModalOpen = (state) => state.modal.spreadsheet.isModalOpen;
export const selectPersonModalOpen = (state) => state.modal.person.isModalOpen;
export const selectSpreadsheetModalData = (state) => state.modal.spreadsheet.modalData;
export const selectPersonModalData = (state) => state.modal.person.modalData;
export const selectTicketAmount = (state) => state.modal.ticketAmount;
export const selectInitSpreadsheetData = (state) => state.modal.spreadsheet.initData;
export const selectRecipients = (state) => state.modal.recipients;
export const selectRecipient = (state) => state.modal.recipientToBeEdited;
export const selectEditMode = (state) => state.modal.editMode;
export const selectNumberOfRecipients = (state) => state.modal.recipients.length;
export const selectAreThereRecipients = (state) => state.modal.recipients.length > 0;
export const selectPersonTicketExceedingAmount = (state) => state.modal.person.ticketExceedingAmount;
export const selectIsSaveDataLoaded = (state) => state.modal.saveDataLoaded;
export const selectTimeOfSave = (state) => state.modal.timeOfSave;
export const selectManualAllocationModalOpen = (state) => state.modal.manualAllocation.isModalOpen;
export const selectManualAllocationModalEditMode = (state) => state.modal.manualAllocation.editMode;
export const selectShouldTicketsRefresh = (state) => state.modal.shouldTicketsRefresh;
export const selectShouldEventsRefresh = (state) => state.modal.shouldEventsRefresh;
export const selectRemoveTicketsModalState = (state) => state.modal.removeTickets.isModalOpen;
export const selectIsVerifiedAccountModalOpen = (state) => state.modal.isVerifiedAccountModalOpen;
