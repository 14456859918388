import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environmentConfig from '../../../../config/config';
import { addRecipients, setSaveData } from '../../../../features/modal/modalSlice';

export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environmentConfig.groupTicketingService,
    tagTypes: ['Events', 'Tickets', 'SavedData', 'Status'],
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('okta-token-storage'));
      if (token?.accessToken) {
        headers.set('Authorization', `Bearer ${token.accessToken.accessToken}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (args) => ({
        url: args?.refresh ? '/events?refresh=true' : '/events'
      }),
      providesTags: ['Events']
    }),
    getSave: builder.query({
      query: (args) => ({
        url: `/events/${args.gamePk}/autosave`
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        dispatch(setSaveData(true));
        const dataLoaded = await queryFulfilled;
        const state = getState();
        if (
          dataLoaded &&
          dataLoaded.data &&
          dataLoaded.data.recipients &&
          dataLoaded.data.recipients.length &&
          state.modal.recipients.length === 0
        ) {
          dispatch(addRecipients(dataLoaded.data.recipients));
        }
      },
      providesTags: (result, error, arg) => [{ type: 'SavedData', id: arg.gamePk }]
    }),
    setSave: builder.mutation({
      query: (args) => ({
        url: `/events/${args.gamePk}/autosave`,
        method: 'PUT',
        body: { recipients: args.recipients, allocationData: args.allocationData }
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SavedData', id: arg.gamePk }] // so it targets a more specific tag
    }),
    getTickets: builder.query({
      query: (args) => {
        return {
          url:
            args?.refresh === true
              ? `/events/${args.gamePk}?teamId=${args.homeTeamId}&refresh=true`
              : `/events/${args.gamePk}?teamId=${args.homeTeamId}`
        };
      },
      providesTags: (result, error, arg) => [{ type: 'Tickets', id: arg.gamePk }, 'Tickets'],
      transformResponse: addTicketOrder
    }),
    getAccounts: builder.query({
      query: (args) => {
        return {
          url: `/accounts`
        };
      },
      transformResponse: (response) => {
        return response.map((emailAddress) => {
          return emailAddress.toLowerCase();
        });
      }
    }),
    getForwardProgress: builder.query({
      query: (args) => {
        return {
          url: `/events/${args.gamePk}/progress`
        };
      }
    }),
    forward: builder.mutation({
      query: (forward) => ({
        url: '/forwards',
        method: 'POST',
        body: forward
      }),
      invalidatesTags: ['Events', 'Tickets']
    }),
    cancelTicket: builder.mutation({
      query: ({ currentTicketId, gamePk, homeTeamId, forwardId }) => ({
        url: forwardId
          ? `/forwards?ticketId=${currentTicketId}&forwardId=${forwardId}`
          : `/forwards?ticketId=${currentTicketId}&eventId=${gamePk}&teamId=${homeTeamId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Events', 'Tickets']
    }),
    recallTicket: builder.mutation({
      query: ({ currentTicketId, forwardId }) => ({
        url: `/forwards?ticketId=${currentTicketId}&forwardId=${forwardId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Events', 'Tickets']
    }),
    removeInvalidEmail: builder.mutation({
      query: (forwardId) => ({
        url: `/forwards?forwardId=${forwardId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Events', 'Tickets']
    }),
    bulkRecall: builder.mutation({
      query: ({ gamePk, body }) => ({
        url: `/forwards?eventId=${gamePk}`,
        method: 'DELETE',
        body: body
      })
    }),
    getGitVersion: builder.query({
      query: (args) => ({
        url: '/actuator/info'
      }),
      providesTags: ['Status']
    })
  })
});

export const {
  useGetEventsQuery,
  useGetSaveQuery,
  useSetSaveMutation,
  useGetTicketsQuery,
  useGetAccountsQuery,
  useGetForwardProgressQuery,
  useForwardMutation,
  useCancelTicketMutation,
  useRecallTicketMutation,
  useBulkRecallMutation,
  useGetGitVersionQuery,
  useRemoveInvalidEmailMutation
} = ticketsApi;

export const selectSaveMutation = (state) => {
  const ticketsMutations = state.ticketsApi.mutations;
  for (const value in ticketsMutations) {
    if (ticketsMutations[value].endpointName === 'setSave') {
      return ticketsMutations[value];
    }
  }
};

export function addTicketOrder(response) {
  //Adds the order in which box office passes each ticket to the ticket so we can make sure they stay in that order
  if (response.availableTickets && response.availableTickets.length) {
    response.availableTickets.map((ticket, index) => (ticket.displayOrder = index));
  }
  return response;
}
