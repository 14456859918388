// this is where all formatters will live

export const formatDate = (dateTime) => {
  const date = new Date(dateTime);
  return `${date.toLocaleString('en-us', { weekday: 'short' })}, ${date.toLocaleString('en-us', {
    month: 'short'
  })} ${date.toLocaleString('en-us', { day: 'numeric' })}`;
};

export const formatTime = (dateTime) => {
  const date = new Date(dateTime);
  return `${date.toLocaleTimeString('en-us', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  })}`;
};

export const removeWhiteSpace = (string) => {
  //Removes new lines, and any spaces at the beginning or end of strings
  return string.replace(/\s+/g, ' ').trim();
};

export const sortInvalidEmailsByDateAsc = (a, b) => {
  return new Date(b.dateTimeCreated) - new Date(a.dateTimeCreated);
};
