import Loading from '../../Shared/Loading/Loading';
import { useTranslation } from 'react-i18next';
import './SharedTicketList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { userEvent } from '../../../utils/analytics';
import {
  updateForwardId,
  updateSharedTicketId,
  openModal
} from '../../../features/modal/SharedTicketModals/sharedTicketModalSlice';
import { Link } from 'react-router-dom';
import SharedTicketSection from './SharedTicketsSection';
import NoSearchResults from '../TicketSearch/NoSearchResults';
import { selectSearchTerm } from '../../../stores/searchSlice';
import SelectAllHeader from './SelectAllHeader';
import ManageListButton from './ManageListButton';
import { createBins, sortBinsAlphabetically } from '../../../utils/autoAllocation';
import { sortInvalidEmailsByDateAsc } from '../../../utils/helpers/formatters';

const SharedTicketList = ({
  ownProps,
  pendingForwards,
  acceptedForwards,
  failingForwards,
  inProgressForwards,
  homeTeamId,
  sharedTickets,
  isManageBtnActive,
  setIsManageBtnActive,
  forwardedTickets,
  setIsBulkRecallActive,
  setCheckedIds,
  invalidEmailMapping,
  setInvalidEmailMapping
}) => {
  const { isOnStealthMode, isLoading, error, gamePk, ticketData } = ownProps;
  const [checkedTicketsByEmail, setcheckedTicketsByEmail] = useState([]);
  const [invalidEmailForwards, setInvalidEmailForwards] = useState([]);
  const [otherFailingForwards, setOtherFailingForwards] = useState([]);
  const { t } = useTranslation();
  const searchTerm = useSelector(selectSearchTerm);
  const dispatch = useDispatch();
  const isManageBtnEnabled = useMemo(
    () =>
      (inProgressForwards?.length > 0 || failingForwards?.length > 0 || acceptedForwards?.length > 0) &&
      pendingForwards?.length === 0 &&
      forwardedTickets?.length > 0,
    [
      acceptedForwards?.length,
      failingForwards?.length,
      inProgressForwards?.length,
      pendingForwards?.length,
      forwardedTickets?.length
    ]
  );

  const separateFailingForwards = useCallback(() => {
    const undeliverableForwards = [],
      others = [];
    failingForwards?.forEach((tickets) =>
      tickets?.forEach((ticket) => {
        ticket.emailUndeliverable === true ? undeliverableForwards.push(ticket) : others.push(ticket);
      })
    );
    if (undeliverableForwards.length > 0) {
      undeliverableForwards.sort(sortInvalidEmailsByDateAsc); // sort the list from newest to oldest
      setInvalidEmailForwards(undeliverableForwards);
    } else {
      invalidEmailForwards?.length > 0 && setInvalidEmailForwards([]);
    }
    others?.length > 0
      ? setOtherFailingForwards(createBins(others, sortBinsAlphabetically))
      : otherFailingForwards?.length > 0 && setOtherFailingForwards([]);
  }, [failingForwards, invalidEmailForwards?.length, otherFailingForwards?.length]);

  useEffect(() => {
    failingForwards?.length > 0 && separateFailingForwards();
  }, [failingForwards?.length, separateFailingForwards]);

  const invalidEmailAddressMapping = useCallback(() => {
    const mapping = {};
    invalidEmailForwards.forEach((ticket) => {
      mapping[ticket.forwardId] = mapping[ticket.forwardId]
        ? (mapping[ticket.forwardId] = [...mapping[ticket.forwardId], ticket])
        : [ticket];
    });
    setInvalidEmailMapping(mapping);
  }, [invalidEmailForwards, setInvalidEmailMapping]);

  useEffect(() => {
    invalidEmailForwards?.length > 0 && invalidEmailAddressMapping();
  }, [invalidEmailAddressMapping, invalidEmailForwards?.length]);

  const bulkRecallIdsMapping = useCallback(() => {
    const ids = [];
    checkedTicketsByEmail.forEach((checkedEmail) => {
      forwardedTickets?.forEach((ticket) => {
        if (ticket.recipientAddress === checkedEmail) {
          ids.push({ forwardId: ticket.forwardId, ticketId: ticket.ticketId });
        }
      });
    });
    setCheckedIds(ids);
  }, [checkedTicketsByEmail, forwardedTickets, setCheckedIds]);

  useEffect(() => {
    checkedTicketsByEmail?.length > 0 && bulkRecallIdsMapping();
  }, [bulkRecallIdsMapping, checkedTicketsByEmail?.length]);

  useEffect(() => {
    checkedTicketsByEmail.length > 0 ? setIsBulkRecallActive(true) : setIsBulkRecallActive(false);
  }, [checkedTicketsByEmail.length, setIsBulkRecallActive]);

  useEffect(() => {
    !isManageBtnActive && setcheckedTicketsByEmail([]);
  }, [isManageBtnActive, setcheckedTicketsByEmail]);

  const handleTicketBtnClick = useCallback(
    (id, forwardId = '', type) => {
      if (id && forwardId && type === 'recall') {
        dispatch(updateForwardId(forwardId));
        dispatch(updateSharedTicketId(id));
        dispatch(openModal('recall'));
      } else if (id && (type === 'cancel' || type === 'view' || type === 'fix')) {
        if (forwardId) {
          dispatch(updateForwardId(forwardId));
        }
        dispatch(updateSharedTicketId(id));
        type === 'cancel' && dispatch(openModal('cancel'));
        type === 'view' && dispatch(openModal('view'));
        type === 'fix' && dispatch(openModal('invalid_email'));
      }
      userEvent({
        event: 'button-click',
        action: {
          element: {
            index: id && forwardId && (type === 'recall' ? 2 : 1),
            text: id && forwardId && (type === 'recall' ? 'recall' : type === 'fix' ? 'fix' : 'cancel')
          }
        },
        page: {
          title: 'group tickets',
          type: 'ticket display',
          gamePk: gamePk,
          subPage: {
            title: 'shared tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        }
      });
    },
    [dispatch, gamePk]
  );

  const handleManageListBtnClick = useCallback(() => {
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 4,
          text: 'manage list:shared'
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    setIsManageBtnActive(true);
  }, [setIsManageBtnActive, gamePk]);

  const handleCancelBtnClick = useCallback(() => {
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'cancel'
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    setIsManageBtnActive(false);
  }, [setIsManageBtnActive, gamePk]);

  return (
    <>
      {error && <h1> {error.data.message}</h1>}
      {isLoading && <Loading />}
      {!isManageBtnActive && ticketData ? (
        <ManageListButton
          manageable={isManageBtnEnabled}
          handleManageListBtnClick={handleManageListBtnClick}
          isOnStealthMode={isOnStealthMode}
          isInline={true}
        />
      ) : isManageBtnActive ? (
        <SelectAllHeader
          isBulkRecallActive={true}
          handleCancelBtnClick={handleCancelBtnClick}
          isOnStealthMode={isOnStealthMode}
        />
      ) : null}
      {pendingForwards?.length > 0 && sharedTickets?.length !== 0 && (
        <SharedTicketSection
          pendingForwards={pendingForwards}
          isOnStealthMode={isOnStealthMode}
          handleTicketBtnClick={handleTicketBtnClick}
          isManageBtnActive={isManageBtnActive}
          recallableTickets={forwardedTickets}
          areThereForwardingTickets={pendingForwards?.length > 0}
        />
      )}
      {invalidEmailForwards?.length > 0 && sharedTickets?.length !== 0 && (
        <SharedTicketSection
          invalidEmailForwards={invalidEmailForwards}
          isOnStealthMode={isOnStealthMode}
          handleTicketBtnClick={handleTicketBtnClick}
          isManageBtnActive={isManageBtnActive}
          recallableTickets={forwardedTickets}
          checkedTicketsByEmail={checkedTicketsByEmail}
          setcheckedTicketsByEmail={setcheckedTicketsByEmail}
          areThereForwardingTickets={pendingForwards?.length > 0}
          invalidEmailMapping={invalidEmailMapping}
        />
      )}
      {otherFailingForwards?.length > 0 && sharedTickets?.length !== 0 && (
        <SharedTicketSection
          otherFailingForwards={otherFailingForwards}
          isOnStealthMode={isOnStealthMode}
          handleTicketBtnClick={handleTicketBtnClick}
          isManageBtnActive={isManageBtnActive}
          recallableTickets={forwardedTickets}
          checkedTicketsByEmail={checkedTicketsByEmail}
          setcheckedTicketsByEmail={setcheckedTicketsByEmail}
          areThereForwardingTickets={pendingForwards?.length > 0}
        />
      )}
      {inProgressForwards?.length > 0 && sharedTickets?.length !== 0 && (
        <SharedTicketSection
          inProgressForwards={inProgressForwards}
          isOnStealthMode={isOnStealthMode}
          handleTicketBtnClick={handleTicketBtnClick}
          isManageBtnActive={isManageBtnActive}
          recallableTickets={forwardedTickets}
          checkedTicketsByEmail={checkedTicketsByEmail}
          setcheckedTicketsByEmail={setcheckedTicketsByEmail}
          areThereForwardingTickets={pendingForwards?.length > 0}
        />
      )}
      {acceptedForwards?.length > 0 && sharedTickets?.length !== 0 && (
        <SharedTicketSection
          acceptedForwards={acceptedForwards}
          isOnStealthMode={isOnStealthMode}
          handleTicketBtnClick={handleTicketBtnClick}
          isManageBtnActive={isManageBtnActive}
          recallableTickets={forwardedTickets}
          checkedTicketsByEmail={checkedTicketsByEmail}
          setcheckedTicketsByEmail={setcheckedTicketsByEmail}
          areThereForwardingTickets={pendingForwards?.length > 0}
        />
      )}
      {sharedTickets?.length !== 0 &&
      failingForwards?.length === 0 &&
      pendingForwards?.length === 0 &&
      acceptedForwards?.length === 0 &&
      inProgressForwards?.length === 0 &&
      searchTerm !== '' ? (
        <NoSearchResults homeTeamId={homeTeamId} />
      ) : null}
      {sharedTickets?.length === 0 ? (
        <div className="shared_tix_cont">
          <div className="no_tickets_cont">
            <p className="no_tickets">
              {t('No_Shared_Tickets')} <Link to={`/events/${gamePk}/available-tickets`}>{t('Available_Tickets')}</Link>
            </p>
            <img
              className="no_tickets_logo"
              src={`https://www.mlbstatic.com/team-logos/${homeTeamId}.svg`}
              alt={t('EventCard_logo_alt')}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SharedTicketList;
