import ModalOverlay from '../../features/modal/ModalOverlay';
import ModalContainer from '../../features/modal/ModalContainer';
import './Toast.scss';

const Toast = (props) => {
  const isModalOpen = props.isToastOpen;

  return (
    <ModalOverlay
      className={'toast modal_overlay' + (isModalOpen ? ' opened' : '')}
      aria-hidden={isModalOpen ? 'false' : 'true'}
    >
      <ModalContainer className={'toast modal_dialog' + (isModalOpen ? ' opened' : '')}>
        <div data-testid="toast_content">{props.children}</div>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Toast;
