import { datadogRum } from '@datadog/browser-rum';

export const allowedTracing = (url) => {
  const serviceUrls = [
    'https://qa-grouptix-service.mlb.com',
    'https://uat-grouptix-service.mlb.com',
    'https://grouptix-service.mlb.com'
  ];
  return serviceUrls.some((serviceUrl) => url.startsWith(serviceUrl));
};

if (process.env.REACT_APP_ENVIRONMENT) {
  datadogRum.init({
    applicationId: '04d95476-e826-4bb8-83d0-a5bb30bfef05',
    clientToken: 'pubd5a825197dacfd3c4c40d66019e0e3d1',
    site: 'datadoghq.com',
    service: 'group-ticket-management',

    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_VERSION,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    env: process.env.REACT_APP_ENVIRONMENT,
    allowedTracingUrls: [
      'https://qa-grouptix-service.mlb.com',
      'https://uat-grouptix-service.mlb.com',
      'https://grouptix-service.mlb.com',
      allowedTracing
    ],
    trackResources: true,
    trackLongTasks: true
  });
}
