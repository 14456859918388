import { v4 as uuid } from 'uuid';

export function autoAllocate(ticketsArray, groupsArray) {
  //Wrapper function to make testing easier
  const { manualTickets, autoTickets } = manualAllocate(ticketsArray, groupsArray);
  const bins = createBins(autoTickets);
  const allocation = combine(allocate(bins, groupsArray), manualTickets);
  return allocation;
}

export function combine(autoAllocation, manualTickets) {
  return createBins(autoAllocation.flat().concat(manualTickets));
}

export function manualAllocate(ticketsArray, groupsArray) {
  const manualTickets = [];
  const autoTickets = [];
  ticketsArray.forEach((ticket) => {
    let ticketAdded = false;
    if (ticket.access !== 'VENUE') {
      manualTickets.push({ ...ticket });
      ticketAdded = true;
    } // we start by taking non-venue tickets out of the auto allocation flow
    groupsArray.forEach((group) => {
      if (group.ticketId && group.ticketId === ticket.ticketId) {
        if (ticket.access !== 'VENUE') {
          // update only those non-venue tickets previsouly set aside upon manual allocation
          const targetTicket = manualTickets.find((ticket) => group.ticketId === ticket.ticketId);
          targetTicket.name = group.name;
          targetTicket.email = group.email;
          targetTicket.recipientId = group.recipientId;
          targetTicket.seatCount = 1;
          targetTicket.assigned = true;
        } else {
          // manual allocation for venue tickets remains untouched
          manualTickets.push({
            name: group.name,
            email: group.email,
            recipientId: group.recipientId,
            seatCount: 1,
            assigned: true,
            ...ticket
          });
          ticketAdded = true;
        }
      }
    });
    if (ticketAdded === false) {
      autoTickets.push(ticket);
    }
  });
  return { manualTickets, autoTickets };
}

export function createBins(tickets, sortFunction) {
  let sortedTickets = {};
  if (tickets && tickets.length && tickets.every((ticket) => ticket.hasOwnProperty('displayOrder'))) {
    sortedTickets = sortTicketsInDisplayOrder(tickets);
  } else {
    sortedTickets = sortSeatsNumerically(tickets);
  }
  //Takes the tickets on the event and creates an array of arrays of tickets which are
  //contiguous, thus creating our bins
  const bins = [];
  sortedTickets.forEach((ticket) => {
    //Iterate over each ticket the user has
    let ticketAdded = false;
    bins.forEach((bin) => {
      //Iterate over each bin so we can check each ticket to see if it is contiguous
      if (ticketAdded) {
        //Ticket has been placed in an existing bin
        //Move on to the next ticket
        return;
      }
      bin.forEach((seat, i, binArray) => {
        if (ticketAdded) {
          //Ticket has been placed in an existing bin
          //Move on to the next ticket
          return;
        }
        //Check each ticket in this bin to see if the current ticket is next to it
        if (ticket.location.section.value === seat.location.section.value) {
          if (ticket.location.row.value === seat.location.row.value) {
            if (
              ticket.location.seat.value === seat.location.seat.value ||
              Number(ticket.location.seat.value) - 1 === Number(seat.location.seat.value) ||
              Number(ticket.location.seat.value) + 1 === Number(seat.location.seat.value)
            ) {
              //Current ticket is next to the ticket in this Bin
              //Add ticket to this bin
              binArray.push(ticket);
              ticketAdded = true;
              return;
            }
          }
        }
      });
    });
    if (ticketAdded === false) {
      //The current ticket is not next to any of the tickets in any bins
      //Create a new bin and add this ticket to it
      bins.push([ticket]);
    }
  });
  if (sortFunction) {
    // Allowing us to pass in a sorting function if we need to sort differently
    return sortFunction(bins);
  }

  return bins;
}

export function sortBinsAlphabetically(bins) {
  return bins.sort((a, b) => {
    if (a[0].location.section.value === b[0].location.section.value) {
      // If we have the same section, sort by rows
      if (!isNaN(Number(a[0].location.row.value)) && !isNaN(Number(b[0].location.row.value))) {
        // If rows are numbered, sort by number.  If not, sort alphabetically
        return Number(a[0].location.row.value) < Number(b[0].location.row.value) ? -1 : 1;
      } else {
        return a[0].location.row.value < b[0].location.row.value ? -1 : 1;
      }
    } else {
      if (!isNaN(Number(a[0].location.section.value)) && !isNaN(Number(b[0].location.section.value))) {
        // If sections are different, sort numerically if they are numeric, otherwise sort alphabetically
        return Number(a[0].location.section.value) < Number(b[0].location.section.value) ? -1 : 1;
      } else {
        return a[0].location.section.value < b[0].location.section.value ? -1 : 1;
      }
    }
  });
}

export function getBinIndex(bins, ticketCount) {
  //Takes bins and ticket count and returns the index of the bin where
  //You will be able to fit all of the tickets
  let binCounter = 0;
  for (let i = 0; i < bins.length; i++) {
    binCounter += availableSeatCount(bins[i]);

    if (binCounter >= ticketCount) {
      return i;
    }
  }
}

export function allocate(bins, recipients) {
  //First determine how many bins we will need to fit all the recipients
  let totalTicketCount;
  if (recipients && recipients.length) {
    totalTicketCount = recipients.reduce((totalRecipients, recipient) => {
      //Determine how many total tickets are being requested by all recipients
      return Number(totalRecipients) + (recipient.ticketId ? 0 : Number(recipient.seatCount));
    }, 0);
  }

  const binIndex = getBinIndex(bins, totalTicketCount);
  //This is the index of the bin where all of the tickets will fit

  recipients.forEach((recipient) => {
    if (recipient.ticketId) {
      //If recipient is manually allocated, skip them here since they already have a ticket
      return;
    }
    //Start with all recipients ordered by number of seats required in descending order
    let recipientAssigned = false;
    for (let j = 0; j <= binIndex && !recipientAssigned; j++) {
      //Check each bin
      if (recipient.seatCount <= availableSeatCount(bins[j])) {
        //See if we can fit the entire recipient into the bin
        //if we can, do it.  If we can't then move to the next bin
        let assignedCount = 0;
        for (let i = 0; !recipientAssigned; i++) {
          if (!bins[j][i].name && !bins[j][i].email) {
            bins[j][i] = {
              name: `${recipient.name}${
                recipient.name && Number(assignedCount) === 0 ? '' : ' #' + (Number(assignedCount) + 1)
              }`,
              email: recipient.email,
              recipientId: recipient.recipientId,
              ...bins[j][i]
            };
            assignedCount++;
            if (assignedCount === Number(recipient.seatCount)) {
              //Check if the recipient has had everyone in their group assigned
              recipientAssigned = true;
            }
          }
        }
      }
    }
    if (recipientAssigned === false) {
      //None of the bins were large enough for this recipient, we will need
      //to combine multiple bins
      let assignedCount = 0;
      for (let j = binIndex; j >= 0; j--) {
        //Starting at the last bin as it is likely the largest
        //With the most empty seats
        for (let i = bins[j].length - 1; assignedCount < recipient.seatCount && i >= 0; i--) {
          if (!bins[j][i].name && !bins[j][i].email) {
            bins[j][i] = {
              name: `${recipient.name}${
                recipient.name && Number(assignedCount) + 1 === Number(recipient.seatCount)
                  ? ''
                  : ' #' + (recipient.seatCount - Number(assignedCount))
              }`,
              email: recipient.email,
              recipientId: recipient.recipientId,
              ...bins[j][i]
            };
            assignedCount++;
          }
        }
      }
    }
  });
  return bins;
}

export function availableSeatCount(bin) {
  //Counts how many seats are available in a particular bin
  let counter = 0;
  bin.forEach((seat) => {
    if (!seat.name && !seat.email) {
      counter++;
    }
  });
  return counter;
}

export function cleanUserData(groupArray) {
  //Changes the array data from the add multiple people modal into
  //an array of objects
  const cleanData = [];
  groupArray.forEach((group) => {
    if (group[0] && group[0] !== '' && group[1] && group[1] !== '' && group[2] && group[2] !== '') {
      cleanData.push({ recipientId: uuid(), name: group[0], email: group[1], seatCount: group[2] });
    }
  });
  cleanData.sort((a, b) => b.seatCount - a.seatCount);
  return cleanData;
}

export function sortTicketsInDisplayOrder(ticketsArray) {
  return ticketsArray.sort((a, b) => a.displayOrder - b.displayOrder);
}

export function sortSeatsNumerically(tickets) {
  return tickets.sort((a, b) => {
    if (!isNaN(Number(a.location.seat.value)) && !isNaN(Number(b.location.seat.value))) {
      return Number(a.location.seat.value) < Number(b.location.seat.value) ? -1 : 1;
    } else {
      return a.location.seat.value < b.location.seat.value ? -1 : 1;
    }
  });
}
