import { useEffect, useState } from 'react';

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return {
    innerWidth,
    innerHeight
  };
};

const useResize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowSize;
};

export default useResize;

// decided to write a custom hook for conditional rendering depending on screen size
