const TEAM_THEMES = {
  109: {
    teamColor1: '#A71930',
    abbreviation: 'ARI'
  },
  144: {
    teamColor1: '#0C2340',
    abbreviation: 'ATL'
  },
  110: {
    teamColor1: '#DF4601',
    abbreviation: 'BAL'
  },
  111: {
    teamColor1: '#0D2B56',
    abbreviation: 'BOS'
  },
  112: {
    teamColor1: '#0E3386',
    abbreviation: 'CHC'
  },
  113: {
    teamColor1: '#C6011F',
    abbreviation: 'CIN'
  },
  114: {
    teamColor1: '#002B5C',
    abbreviation: 'CLE'
  },
  115: {
    teamColor1: '#33006F',
    abbreviation: 'COL'
  },
  145: {
    teamColor1: '#000000',
    abbreviation: 'CWS'
  },
  116: {
    teamColor1: '#0C2C56',
    abbreviation: 'DET'
  },
  117: {
    teamColor1: '#002D62',
    abbreviation: 'HOU'
  },
  118: {
    teamColor1: '#004687',
    abbreviation: 'KC'
  },
  108: {
    teamColor1: '#BA0021',
    abbreviation: 'LAA'
  },
  119: {
    teamColor1: '#005A9C',
    abbreviation: 'LAD'
  },
  146: {
    teamColor1: '#000000',
    abbreviation: 'MIA'
  },
  158: {
    teamColor1: '#11284B',
    abbreviation: 'MIL'
  },
  142: {
    teamColor1: '#041E42',
    abbreviation: 'MIN'
  },
  121: {
    teamColor1: '#002D72',
    abbreviation: 'NYM'
  },
  147: {
    teamColor1: '#132448',
    abbreviation: 'NYY'
  },
  133: {
    teamColor1: '#003831',
    abbreviation: 'OAK'
  },
  143: {
    teamColor1: '#003278',
    abbreviation: 'PHI'
  },
  134: {
    teamColor1: '#000000',
    abbreviation: 'PIT'
  },
  135: {
    teamColor1: '#2F241D',
    abbreviation: 'SD'
  },
  136: {
    teamColor1: '#0C2C56',
    abbreviation: 'SEA'
  },
  137: {
    teamColor1: '#FD5A1E',
    abbreviation: 'SF'
  },
  138: {
    teamColor1: '#C41E3A',
    abbreviation: 'STL'
  },
  139: {
    teamColor1: '#092C5C',
    abbreviation: 'TB'
  },
  140: {
    teamColor1: '#003278',
    abbreviation: 'TEX'
  },
  141: {
    teamColor1: '#134A8E',
    abbreviation: 'TOR'
  },
  120: {
    teamColor1: '#AB0003',
    abbreviation: 'WSH'
  }
};

export default TEAM_THEMES;
