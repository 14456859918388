import EventCard from '../EventCard/EventCard';
import Loading from '../../Shared/Loading/Loading';
import { useTranslation } from 'react-i18next';
import './EventList.scss';
import { useLocation } from 'react-router-dom';

const EventList = (props) => {
  const { data, isLoading, error } = props;
  const { t } = useTranslation();
  const location = useLocation()?.pathname;

  return (
    <>
      {error ? (
        <h1>{error.data?.message}</h1>
      ) : isLoading ? (
        <Loading />
      ) : data?.length === 0 ? (
        <p className="no_event_text" data-testid="no_event_text">
          {t('no_event_text')}
        </p>
      ) : data?.length > 0 ? (
        <section className="event_list_component">
          {data.map((item, index) => {
            return (
              <EventCard hideTicketCounts={location === '/events'} data={item} key={index} id={`event_${index + 1}`} />
            );
          })}
        </section>
      ) : null}
    </>
  );
};

export default EventList;
