import { useTranslation } from 'react-i18next';
import Toast from '../../Toast/Toast';
import Loading from '../../Shared/Loading/Loading';
import { ReactComponent as SuccessIcon } from '../../../assets/images/success.svg';

const ShareToast = ({ isToastOpen, result, numberSent }) => {
  const { t } = useTranslation();

  return (
    <Toast isToastOpen={isToastOpen}>
      {result.isLoading && <Loading />}
      {result.isSuccess && (
        <div>
          <SuccessIcon />
          <output id="toast_text">
            {numberSent} {t('Tickets_Shared')}
            {numberSent >= 500 ? (
              <>
                <br />
                {t('Large_Quantity')}
              </>
            ) : null}
          </output>
        </div>
      )}
      {result.isError && <output>{t('Error')}</output>}
    </Toast>
  );
};

export default ShareToast;
