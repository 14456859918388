import { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../Shared/Loading/Loading';
import Ticket from '../Ticket/Ticket';
import './TicketList.scss';
import {
  selectRecipients,
  setTimeOfSave,
  selectManualAllocationModalOpen,
  removeRecipients,
  closeModal
} from '../../../features/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { autoAllocate } from '../../../utils/autoAllocation';
import {
  updateAllocation,
  filteredAllocation,
  uncheckAllBoxes,
  getCheckedRecipientIds
} from '../../../stores/allocationSlice';
import { selectSearchTerm } from '../../../stores/searchSlice';
import { useSetSaveMutation } from '../TicketList/api/ticketDisplayListApi';
import { userEvent } from '../../../utils/analytics';
import ManualAllocationModal from '../../../features/modal/ManualAllocation/ManualAllocationModal';
import TicketsRowContainer from './TicketsRowContainer';
import NoSearchResults from '../TicketSearch/NoSearchResults';
import SelectAllHeader from './SelectAllHeader';
import ManageListModal from './ManageListModal';
import ManageListButton from './ManageListButton';

const TicketList = ({
  ownProps,
  isShareActive,
  setIsShareActive,
  hasBeenEdited,
  saveData,
  setHasBeenEdited,
  unassignableTickets,
  assignableTickets,
  accounts,
  isManageBtnActive,
  setIsManageBtnActive,
  nonforwardableTicketsOnly,
  shouldDisplayLoader,
  setShouldDisplayLoader
}) => {
  const { isOnStealthMode, ticketData, isLoading, error, gamePk, homeTeamId } = ownProps;
  const [save, result] = useSetSaveMutation();
  const dispatch = useDispatch();
  const recipients = useSelector(selectRecipients);
  const allocatedData = useSelector(filteredAllocation);
  const searchTerm = useSelector(selectSearchTerm);
  const { t } = useTranslation();
  const [currentSeat, setCurrentSeat] = useState();
  const isManualAllocOn = useSelector(selectManualAllocationModalOpen);
  const recipientIds = useSelector(getCheckedRecipientIds);
  const sortedAllocatedData = useMemo(() => {
    if (allocatedData) {
      const allocationData = [...allocatedData];
      const nonVenueTixIdx = allocationData.findIndex((ticket) => ticket[0].access !== 'VENUE');
      const lastIdx = allocationData.length - 1;
      if (nonVenueTixIdx === -1 || nonVenueTixIdx === lastIdx || lastIdx === 0) {
        return allocatedData;
      }
      if (nonVenueTixIdx > -1 && lastIdx && nonVenueTixIdx < lastIdx) {
        const parkingTickets = allocationData.splice(nonVenueTixIdx, 1);
        allocationData.push(parkingTickets[0]);
      }
      return allocationData;
    }
  }, [allocatedData]); // this code ensures that non-venue tickets are displayed after venue tickets and before non-forwardable tickets
  const onlyHasSelfAssignedTickets = useMemo(
    () => recipients?.every((ticket) => accounts?.data?.includes(ticket?.email?.toLowerCase())),
    [accounts?.data, recipients]
  );
  const noSearchResults =
    ticketData?.availableTickets.length !== 0 && sortedAllocatedData?.length === 0 && searchTerm !== '';
  const isManageListBtnIdle = noSearchResults || nonforwardableTicketsOnly;
  const isManageListBtnEnabled = allocatedData && isManageBtnActive && !noSearchResults;

  useEffect(() => {
    if (recipients && assignableTickets) {
      const newData = autoAllocate([...assignableTickets], recipients);
      dispatch(updateAllocation(newData));
      if (hasBeenEdited) {
        // we ONLY want to save the data after user changes it, be it an addition, edit or removal
        save({ gamePk: gamePk, allocationData: newData, recipients: recipients }); // this code is the reason why data got wiped out
      }
    }
  }, [recipients, dispatch, gamePk, save, hasBeenEdited, assignableTickets]);

  useEffect(() => {
    if (saveData?.isSuccess && saveData?.data?.recipients?.length > 0) {
      saveData.refetch();
    } // this step is crucial for UI to update. It is also recommended by React to have seperate useEffect hooks for different side effects
  }, []);

  useEffect(() => {
    if (hasBeenEdited && result.fulfilledTimeStamp) {
      dispatch(setTimeOfSave({ gamePk: gamePk, time: result.fulfilledTimeStamp }));
    } // ONLY shows the timer upon a save, instead of triggering it everytime a new event loads even without edits
  }, [hasBeenEdited, dispatch, gamePk, result.fulfilledTimeStamp]);

  useEffect(() => {
    if (shouldDisplayLoader) {
      const timer = setTimeout(() => {
        setShouldDisplayLoader(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [setShouldDisplayLoader, shouldDisplayLoader]); // only display the loader for 1 sec

  const handleManageListBtnClick = useCallback(() => {
    setShouldDisplayLoader(true);
    setIsManageBtnActive(true);
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 4,
          text: 'manage list'
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
  }, [setIsManageBtnActive, gamePk, setShouldDisplayLoader]);

  const handleCancelBtnClick = useCallback(() => {
    setIsManageBtnActive(false);
    dispatch(uncheckAllBoxes());
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'cancel'
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
  }, [setIsManageBtnActive, dispatch, gamePk]);

  const removeTickets = useCallback(() => {
    dispatch(removeRecipients(recipientIds));
    setHasBeenEdited(true);
    dispatch(closeModal());
    setIsManageBtnActive(false);
  }, [dispatch, recipientIds, setIsManageBtnActive, setHasBeenEdited]);

  return shouldDisplayLoader ? (
    <Loading />
  ) : (
    <>
      {error && <h1>{error.data.message}</h1>}
      {isLoading && <Loading />}
      <ManageListModal gamePk={gamePk} removeTickets={removeTickets} />
      {allocatedData && isShareActive ? (
        <SelectAllHeader isShareActive={isShareActive} isOnStealthMode={isOnStealthMode} />
      ) : null}
      {isManageListBtnIdle ? (
        <ManageListButton isIdle={noSearchResults} isOnStealthMode={isOnStealthMode} />
      ) : isManageListBtnEnabled ? (
        <SelectAllHeader
          handleCancelBtnClick={handleCancelBtnClick}
          isOnStealthMode={isOnStealthMode}
          noSearchResults={noSearchResults}
        />
      ) : null}
      {isManualAllocOn && (
        <ManualAllocationModal currentSeat={currentSeat} gamePk={gamePk} setHasBeenEdited={setHasBeenEdited} />
      )}
      {ticketData?.availableTickets.length !== 0 &&
        sortedAllocatedData?.map(
          (tik, i) =>
            !((isManageBtnActive || isShareActive) && !tik?.some((ticket) => ticket.name)) && (
              <Fragment key={i}>
                {tik[0]?.access !== 'VENUE' && <h5 className="nonvenue_tix_header">{t('NonVenueTitle')}</h5>}
                <TicketsRowContainer
                  tik={tik}
                  key={i}
                  leading={i === 0}
                  manageable={recipients?.length > 0 && !onlyHasSelfAssignedTickets}
                  handleManageListBtnClick={handleManageListBtnClick}
                  isManageBtnActive={isManageBtnActive}
                  isShareActive={isShareActive}
                  isOnStealthMode={isOnStealthMode}
                  ticketCountByRow={tik?.length}
                  hasNonVenueTickets={tik[0]?.access !== 'VENUE'}
                >
                  {tik?.map((ticket, i) => {
                    return (
                      <Ticket
                        key={i}
                        seat={ticket}
                        recipients={recipients}
                        isOnStealthMode={isOnStealthMode}
                        gamePk={gamePk}
                        isShareActive={isShareActive}
                        setCurrentSeat={setCurrentSeat}
                        accounts={accounts.data}
                        isManageBtnActive={isManageBtnActive}
                      />
                    );
                  })}
                </TicketsRowContainer>
              </Fragment>
            )
        )}
      {noSearchResults ? <NoSearchResults homeTeamId={homeTeamId} /> : null}
      {unassignableTickets?.length > 0 && searchTerm === '' && !isShareActive && !isManageBtnActive ? (
        <>
          <h5 className="nonforwardable_tix_header">{t('NonForwardableText')}</h5>
          {unassignableTickets?.map((tik, i) => (
            <TicketsRowContainer tik={tik} key={i} ticketCountByRow={tik?.length}>
              {tik?.map((ticket, i) => {
                return (
                  <Ticket
                    key={i}
                    seat={ticket}
                    recipients={recipients}
                    isOnStealthMode={isOnStealthMode}
                    gamePk={gamePk}
                    isShareActive={isShareActive}
                    setCurrentSeat={setCurrentSeat}
                    unassignable={unassignableTickets?.length > 0}
                    accounts={accounts.data}
                  />
                );
              })}
            </TicketsRowContainer>
          ))}
        </>
      ) : null}
      {!isLoading && ticketData && ticketData.availableTickets && ticketData.availableTickets.length === 0 ? (
        <div className="shared_tix_cont">
          <div className="no_tickets_cont">
            <p className="no_tickets">
              {t('No_Available_Tickets')} <Link to={`/events/${gamePk}/shared-tickets`}>{t('Shared_Tickets')}</Link>
            </p>
            <img
              className="no_tickets_logo"
              src={`https://www.mlbstatic.com/team-logos/${homeTeamId}.svg`}
              alt={t('EventCard_logo_alt')}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TicketList;
