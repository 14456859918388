import { useEffect } from 'react';
import Loading from '../Loading/Loading';
import './SkeletonLoader.scss';

const SkeletonLoader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="sl_cont">
      <Loading />;
    </div>
  );
};
// created this component to be served as a placeholder for skeleton loader IF we really wanted to go down that route
export default SkeletonLoader;
