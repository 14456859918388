// this file is to house sharable handlers

export const handleModalTab = (modalRef) => (e) => {
  let focusables, firstFocusable, lastFocusable;
  if (e.key !== 'Tab' || !modalRef) {
    return;
  } else {
    focusables = modalRef.current?.querySelectorAll(' button:not([disabled]), select, input, a[href], textarea ');
    if (!focusables) return;
    firstFocusable = focusables[0];
    lastFocusable = focusables[focusables.length - 1];
  }

  if (!e.shiftKey && document.activeElement === lastFocusable) {
    e.preventDefault();
    firstFocusable.focus();
  }
  if (e.shiftKey && document.activeElement === firstFocusable) {
    e.preventDefault();
    lastFocusable.focus();
  }
};
