import { createSlice, createSelector } from '@reduxjs/toolkit';
import { ticketsApi } from '../components/TicketDisplay/TicketList/api/ticketDisplayListApi';

const allocationSlice = createSlice({
  name: 'allocation',
  initialState: {
    allocationData: [],
    timeOfRefresh: {}
  },
  reducers: {
    updateAllocation: (state, action) => {
      state.allocationData = action.payload;
    },
    checkboxesByRecipientId: (state, action) => {
      state.allocationData.forEach((row) => {
        row.forEach((seat) => {
          if (seat.recipientId === action.payload) {
            seat.isChecked = !Boolean(seat.isChecked);
          }
        });
      });
    },
    checkAllBoxes: (state) => {
      state.allocationData.forEach((row) => {
        row.forEach((seat) => {
          seat.isChecked = true;
        });
      });
    },
    uncheckAllBoxes: (state) => {
      state.allocationData.forEach((row) => {
        row.forEach((seat) => {
          seat.isChecked = false;
        });
      });
    },
    setTimeOfRefresh: (state, action) => {
      const { gamePk, time } = action.payload;
      state.timeOfRefresh[gamePk] = time;
    }
  }
});

export const { updateAllocation, checkboxesByRecipientId, checkAllBoxes, uncheckAllBoxes, setTimeOfRefresh } =
  allocationSlice.actions;

export default allocationSlice.reducer;

export const selectTimeOfInventoryRefresh = (state) => state.allocation.timeOfRefresh;

export const allocationState = (state) => (state.allocation?.allocationData ? state.allocation.allocationData : []);
export const accounts = ticketsApi.endpoints.getAccounts.select();
const searchTerm = (state) => (state.search?.searchTerm ? state.search.searchTerm : '');

export const filteredAllocation = createSelector(searchTerm, allocationState, (search, bins) => {
  if (search !== '') {
    return bins
      .map((bin) => {
        return bin.filter((seat) => {
          return (
            seat.name?.toLowerCase()?.includes(search.toLowerCase()) ||
            seat.email?.toLowerCase()?.includes(search.toLowerCase())
          );
        });
      })
      .filter((row) => row.length !== 0);
  }
  return bins;
});

export const areAllSeatsChecked = createSelector(accounts, filteredAllocation, (userAccounts, tickets) => {
  return tickets.flat().every((ticket) => {
    if (ticket.email && !userAccounts?.data?.includes(ticket.email.toLowerCase())) {
      return ticket.isChecked;
    }
    return true;
  });
});

export const areNoSeatsChecked = createSelector(accounts, filteredAllocation, (userAccounts, tickets) => {
  return tickets.flat().every((ticket) => {
    if (ticket.email && !userAccounts?.data?.includes(ticket.email.toLowerCase())) {
      return !ticket.isChecked;
    }
    return true;
  });
});

export const getForwardPayload = createSelector(accounts, filteredAllocation, (userAccounts, tickets) => {
  const payload = [];
  const data = tickets.flat().filter((ticket) => {
    return ticket.email && ticket.isChecked && !userAccounts.data.includes(ticket.email.toLowerCase());
  });
  data.forEach((ticket) => {
    let ticketAdded = false;
    payload.forEach((recipient) => {
      if (ticket.email.toLowerCase() === recipient.recipientAddress.toLowerCase()) {
        recipient.ticketIds.push(ticket.ticketId);
        ticketAdded = true;
        return;
      }
    });
    if (ticketAdded === false) {
      payload.unshift({ recipientName: ticket.name, recipientAddress: ticket.email, ticketIds: [ticket.ticketId] });
    }
  });
  return payload;
});

export const getForwardAllocationTypeCount = createSelector(accounts, filteredAllocation, (userAccounts, tickets) => {
  let autoAllocatedCount = 0;
  let manualAllocatedCount = 0;
  const data = tickets.flat().filter((ticket) => {
    return ticket.email && ticket.isChecked && !userAccounts?.data?.includes(ticket.email.toLowerCase());
  });
  data.forEach((ticket) => {
    if (ticket.assigned) {
      manualAllocatedCount++;
    } else {
      autoAllocatedCount++;
    }
  });
  return { manual: manualAllocatedCount, auto: autoAllocatedCount };
});

export const getCheckedRecipientIds = createSelector(accounts, filteredAllocation, (userAccounts, userTickets) => {
  const recipientIds = [];
  const data = userTickets.flat().filter((ticket) => {
    return (
      ticket.email &&
      ticket.isChecked &&
      userAccounts?.data?.length &&
      !userAccounts.data.includes(ticket.email.toLowerCase())
    );
  });
  data.forEach((ticket) => {
    if (ticket.recipientId && ticket.isChecked && ticket.email) {
      recipientIds.push(ticket.recipientId);
    }
  });
  return [...new Set(recipientIds)];
});

export const selectAreThereForwardableTickets = createSelector(
  accounts,
  filteredAllocation,
  (userAccounts, userTickets) => {
    return userTickets.flat().some((ticket) => {
      if (userAccounts?.data?.length) {
        return ticket.email && userAccounts?.data?.length && !userAccounts.data.includes(ticket.email.toLowerCase());
      }
      return false;
    });
  }
);

export const selectManuallyAllocatableTicketCount = createSelector(allocationState, (bins) => {
  return bins.flat().reduce((acc, ticket) => {
    if (ticket.email) {
      return acc;
    }
    return acc + 1;
  }, 0);
});
