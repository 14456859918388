import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { handleModalTab } from '../../../utils/helpers/handlers';
import ModalOverlay from '../../../features/modal/ModalOverlay';
import ModalContainer from '../../../features/modal/ModalContainer';
import CloseBtn from '../../../assets/icons/CloseButton.svg';
import './GenericModal.scss';
import '../../../features/modal/Modal.scss';

const GenericModal = ({ children, isModalOpen = false, setIsModalOpen, closeable = true }) => {
  const { t } = useTranslation();
  const modalOverlayRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    document.body.addEventListener('keydown', handleModalTab(modalRef), false);
    return () => {
      document.body.removeEventListener('keydown', handleModalTab(modalRef), false);
    };
  }, []);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27 && closeable) {
        setIsModalOpen(false);
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
  }, [setIsModalOpen, closeable]);

  useEffect(() => {
    const closeOnClickOutsideModal = (e) => {
      if (isModalOpen && modalOverlayRef.current === e.target && closeable) {
        setIsModalOpen(false);
      }
    };
    document.body.addEventListener('click', closeOnClickOutsideModal);
    return () => document.body.removeEventListener('click', closeOnClickOutsideModal);
  }, [isModalOpen, setIsModalOpen, closeable]);

  return (
    <ModalOverlay
      ref={modalOverlayRef}
      className={'modal_overlay' + (isModalOpen ? ' opened' : '')}
      aria-hidden={isModalOpen ? 'false' : 'true'}
      data-testid="modalOverlay"
    >
      <ModalContainer
        className={'add_modal_dialog generic' + (isModalOpen ? ' opened' : '')}
        ref={modalRef}
        aria-labelledby="modal_desc remaining_tix_desc"
        aria-modal={isModalOpen ? 'true' : 'false'}
        aria-hidden={isModalOpen ? 'false' : 'true'}
        role="dialog"
      >
        {closeable ? (
          <button
            id="modal_close_btn"
            aria-label={t('Modal_Close_Btn')}
            className="modal_close"
            type="button"
            onClick={(e) => setIsModalOpen(false)}
            autoFocus
          >
            <img className="modal_close_icon" src={CloseBtn} alt={t('Modal_Close_Btn')} />
          </button>
        ) : null}
        {children}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default GenericModal;
