import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircleProgress } from '../../../../Shared/CircleProgress/CircleProgress';
import { useGetForwardProgressQuery, ticketsApi } from '../../../../TicketDisplay/TicketList/api/ticketDisplayListApi';

export const SharedTicketProgress = ({ gamePk }) => {
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const { data, refetch } = useGetForwardProgressQuery({ gamePk: gamePk }, { skip: !gamePk });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data?.totalRequestsPending > 0) {
      setShowProgress(true);
    }
  }, [data?.totalRequestsPending]);

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
      if (data?.requestsRemaining === 0) {
        dispatch(ticketsApi.util.invalidateTags(['Events', 'Tickets']));
      }
    }, 3000);
    return () => clearInterval(timer);
  }, [dispatch, data?.requestsRemaining, refetch]);

  return (
    <>
      {data ? (
        <CircleProgress
          size={58}
          strokeWidth={8}
          progress={
            showProgress && ((data?.totalRequestsPending - data?.requestsRemaining) / data?.totalRequestsPending) * 100
          }
        />
      ) : null}
    </>
  );
};
