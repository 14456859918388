import environmentConfig from '../config/config';

const analyticsLink = document.getElementById('adobe');
if (analyticsLink) {
  analyticsLink.src = environmentConfig.analyticsUrl;
}

export function updateDataLayer(data) {
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push(data);
  }
}

export function userEvent(e) {
  if (window.adobeDataLayer) {
    const app = {version: process.env.REACT_APP_VERSION};
    let page;
    if (e.page && e.page.app) {
      page = {...e.page, app: {...e.page.app, ...app }};
    }
    else if (e.page) {
      page = {...e.page, app: app };
    }
    else {
      page = {app: app}
    }
    e.page = page;
    window.adobeDataLayer.push(e);
  }
}
