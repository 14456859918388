import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environmentConfig from '../../../config/config';

export const profileServiceApi = createApi({
  reducerPath: 'profileServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environmentConfig.profileServiceUrl,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('okta-token-storage'));
      if (token?.accessToken) {
        headers.set('Authorization', `Bearer ${token.accessToken.accessToken}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    verificationEmail: builder.mutation({
      query: (email) => ({
        url: '/api/v1/verification/emails',
        method: 'POST',
        body: { email: email, source: 'GROUPTICKETS' }
      })
    })
  })
});

export const { useVerificationEmailMutation } = profileServiceApi;
