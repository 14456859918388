import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import { useTranslation } from 'react-i18next';
import './Spreadsheet.scss';
import 'handsontable/dist/handsontable.full.css';
import { emailValidator, integerValidator } from '../../utils/helpers/validators';
import { removeWhiteSpace } from '../../utils/helpers/formatters';

const Spreadsheet = ({
  hotTableRef,
  validationActive,
  initData,
  handleTicketCountChange,
  addTooltipOnSelect,
  setIsSaveDisabled,
  ticketRemainingAmount,
  modalRef
}) => {
  const hot = hotTableRef?.current?.hotInstance;
  const headers = ['Name', 'Email', 'Seat Qty'];
  const { t } = useTranslation();

  const handleChanges = (changes) => {
    //This wrapper function is so that we can execute multiple functions on change
    //Handsontable's support of multiple functions this way causes the functions to be executed twice
    handleTicketInputChange(changes);
    validateSaveButton();
  };

  const handleTicketInputChange = (changes) => {
    if (changes) {
      for (let [row, col, oldVal, newVal] of changes) {
        if (col === 2) {
          const oldTixCount = isNaN(parseInt(oldVal, 10)) ? 0 : parseInt(oldVal, 10);
          const newTixCount = isNaN(parseInt(newVal, 10)) ? 0 : parseInt(newVal, 10);
          if (oldTixCount >= 0 && newTixCount >= 0 && oldTixCount !== newTixCount) {
            const difference = Math.abs(newVal - oldVal);
            if (oldTixCount > newTixCount) {
              handleTicketCountChange((count) => count + difference);
            } else {
              handleTicketCountChange((count) => count - difference);
            }
          }
        }
      }
    }
  };

  const validateSaveButton = () => {
    //Disables the save and continue button when the table is empty or there are too many tickets
    if (hotTableRef && hotTableRef.current && hotTableRef.current.hotInstance) {
      const hot = hotTableRef?.current?.hotInstance;
      if (hot.countEmptyRows() === hot.countRows() || ticketRemainingAmount < 0) {
        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }
    }
  };

  const scrollTooltip = () => {
    //Hides the tooltip if it would scroll out of the view of the spreadsheet
    if (validationActive) {
      const selected = hot.getSelected();
      if (selected && selected.length === 1 && selected[0][0] === selected[0][2] && selected[0][1] === selected[0][3]) {
        const tooltip = document.querySelector('.hot-tooltip');
        const spreadsheetRect = document.querySelector('.wtHolder').getBoundingClientRect();
        const selectedCell = hot.getCell(selected[0][0], selected[0][1]);
        const tdRect = selectedCell.getBoundingClientRect();
        const modalRect = modalRef.current.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();
        if (
          tdRect.top < spreadsheetRect.top + tdRect.height ||
          tdRect.top > spreadsheetRect.bottom ||
          hot.isEmptyRow(selected[0][0])
        ) {
          tooltip.style.visibility = 'hidden';
          return;
        }
        tooltip.style.visibility = 'visible';
        tooltip.style.top = `${tdRect.top - modalRect.top - 35}px`;
        tooltip.style.left = `${tdRect.x - modalRect.x + (tdRect.width - tooltipRect.width) / 2}px`;
      }
    }
  };

  const handleFocus = () => {
    if (hotTableRef && hotTableRef.current && hotTableRef.current.hotInstance) {
      hot?.selectCell(0, 0);
    }
  };

  const handleTabatLastCell = (e) => {
    const rowCount = hot?.countRows();
    const cellCoords = hot?.getSelectedLast();
    if (cellCoords && cellCoords[0] === rowCount - 1 && cellCoords[1] === 2) {
      if (e.shiftKey && e.key === 'Tab') {
        hot.selectCell(0, rowCount);
      } else if (e.key === 'Tab') {
        hot.deselectCell();
      }
    }
  };

  const customRenderer = (instance, td, row, col, prop, value) => {
    Handsontable.dom.empty(td);
    let text = document.createTextNode(value ? value : '');
    td.appendChild(text);
    if (validationActive) {
      if (!instance.isEmptyRow(row)) {
        if (!value || value === '' || (col === 1 && !emailValidator.test(value))) {
          let img = document.createElement('IMG');
          img.src = `./ValidationErrorIcon.svg`;
          img.style.height = '16px';
          img.style.marginTop = '5px';
          img.style.float = 'right';
          img.setAttribute('alt', t('Validation_Error'));
          td.appendChild(img);
          td.setAttribute('aria-describedby', 'tooltip');
        }
      }
    }
    return td;
  };

  return (
    <>
      <div tabIndex={0} onFocus={handleFocus} />
      <HotTable
        data={initData}
        id="embedded_spreadsheet"
        ref={hotTableRef}
        colHeaders={headers}
        width={610}
        height={400}
        allowEmpty={false}
        minSpareRows={1}
        afterScrollVertically={scrollTooltip}
        afterSelection={addTooltipOnSelect}
        afterDocumentKeyDown={handleTabatLastCell}
        columns={[
          {
            width: 230,
            renderer: customRenderer
          },
          {
            width: 287,
            renderer: customRenderer
          },
          {
            width: 78,
            className: 'centeredText',
            renderer: customRenderer
          }
        ]}
        beforeChange={onBeforeChangeWrapper}
        afterChange={handleChanges}
      />
    </>
  );
};

export default Spreadsheet;

export const onBeforeChangeWrapper = (changes) => {
  spreadsheetFormatter(changes);
  validateTicketQuantity(changes);
};

export const validateTicketQuantity = (changes) => {
  //Removes any input into the ticket quantity column that isn't a number
  changes.forEach((element, index, array) => {
    if (element[1] === 2 && (!integerValidator.test(Number(element[3])) || Number(element[3]) === 0)) {
      element[3] = '';
    }
  });
};

const spreadsheetFormatter = (changes) => {
  //Removes newlines and whitespace from spreadsheet changes
  changes.forEach((element) => {
    element[3] = removeWhiteSpace(element[3]);
  });
};
