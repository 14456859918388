import { configureStore } from '@reduxjs/toolkit';
import logoutReducer from '../features/logout/logoutSlice';
import modalReducer from '../features/modal/modalSlice';
import allocationReducer from './allocationSlice';
import searchReducer from './searchSlice';
import sharedTicketModalReducer from '../features/modal/SharedTicketModals/sharedTicketModalSlice';
import { ticketsApi } from '../components/TicketDisplay/TicketList/api/ticketDisplayListApi';
import { profileServiceApi } from '../components/EventDisplay/api/profileServiceApi';

export const store = configureStore({
  reducer: {
    logout: logoutReducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [profileServiceApi.reducerPath]: profileServiceApi.reducer,
    modal: modalReducer,
    sharedTicketModal: sharedTicketModalReducer,
    allocation: allocationReducer,
    search: searchReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ticketsApi.middleware).concat(profileServiceApi.middleware)
});
