import ModalContainer from '../ModalContainer';
import ModalOverlay from '../ModalOverlay';
import { useCallback, useEffect, useRef, useState } from 'react';
import { handleModalTab } from '../../../utils/helpers/handlers';
import ModalHeader from '../ModalHeader';
import {
  closeModal,
  disableManualAllocationEditMode,
  removeRecipient,
  selectManualAllocationModalEditMode,
  selectManualAllocationModalOpen,
  selectRecipient,
  updateRecipient
} from '../modalSlice';
import { addRecipients } from '../../modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import ManualAllocationForm from './ManualAllocationForm';
import { emailValidator } from '../../../utils/helpers/validators';
import { userEvent } from '../../../utils/analytics';
import './ManualAllocationModal.scss';

const ManualAllocationModal = ({ currentSeat, gamePk, setHasBeenEdited }) => {
  const modalRef = useRef();
  const modalOverlayRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isModalOpen = useSelector(selectManualAllocationModalOpen);
  const [formErrors, setFormErrors] = useState({ name: '', email: '' });
  const [formData, setFormData] = useState({ recipientId: uuid(), name: '', email: '' });
  const isManAllocEditModeOn = useSelector(selectManualAllocationModalEditMode);
  const targetRecipient = useSelector(selectRecipient);
  const isCurrentTicketNonVenue = currentSeat?.access !== 'VENUE';

  const handleModalClose = useCallback(() => {
    setFormData({ ...formData, name: '', email: '' });
    setFormErrors({ name: '', email: '' });
    isManAllocEditModeOn ? dispatch(disableManualAllocationEditMode()) : dispatch(closeModal());
  }, [dispatch, formData, isManAllocEditModeOn]);

  const handleCancelBtnClick = useCallback(() => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 1,
          text: 'cancel',
          targetUrl: `/events/${gamePk}/available-tickets`
        }
      },
      page: {
        app: {
          name: 'bulk ticket management',
          version: '1.0'
        },
        title: 'group tickets',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        type: 'ticket display'
      },
      modal: {
        title: 'assign ticket',
        option: {
          indexTotal: 2
        }
      }
    });
    handleModalClose();
  }, [handleModalClose, gamePk]);

  const handleInputChange = useCallback(
    (e) => {
      let { name, value } = e.target;
      if (formErrors[name] && value) {
        setFormErrors({ [name]: '' });
      }
      setFormData({
        ...formData,
        [name]: value
      });
    },
    [formData, formErrors, setFormErrors]
  );

  const handleSubmit = useCallback(
    (e, leftBtnClicked) => {
      let isValid = true;
      userEvent({
        event: 'modal-click',
        action: {
          element: {
            index: '2',
            text: 'save assigned ticket',
            targetUrl: `/events/${gamePk}/available-tickets`
          }
        },
        page: {
          app: {
            name: 'bulk ticket management',
            version: '1.0'
          },
          gamePk: gamePk,
          subPage: {
            title: 'available tickets'
          },
          title: 'group tickets',
          type: 'ticket display'
        },
        modal: {
          title: 'assign ticket',
          option: {
            indexTotal: '2'
          }
        }
      });
      const validateManualAllocationForm = (formData) => {
        Object.keys(formData).forEach((key) => {
          if (formData[key] === '') {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Required_field') };
            });
            isValid = false;
          } else if (key === 'email' && !emailValidator.test(formData[key])) {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Email_invalid') };
            });
            isValid = false;
          }
        });
      };
      e.preventDefault();
      if (leftBtnClicked && !isManAllocEditModeOn) {
        handleModalClose();
        return;
      }
      validateManualAllocationForm(formData);
      if (isValid) {
        isManAllocEditModeOn
          ? dispatch(updateRecipient(formData))
          : dispatch(
              addRecipients([
                { ...formData, ticketId: currentSeat.ticketId, seatCount: isCurrentTicketNonVenue ? 0 : 1 }
              ]) // we only want to subtract 1 upon manual allocation from modal ticket counter if it's a venue ticket
            );
        userEvent({
          event: 'modal-click',
          action: {
            element: {
              index: leftBtnClicked ? 1 : 2,
              text:
                leftBtnClicked && isManAllocEditModeOn
                  ? 'remove recipient'
                  : isManAllocEditModeOn
                  ? 'save changes'
                  : leftBtnClicked
                  ? '' // undefined in the ticket
                  : 'save assigned ticket',
              targetUrl: `/events/${gamePk}/available-tickets`
            }
          },
          modal: {
            option: {
              indexTotal: 2
            },
            title: isManAllocEditModeOn ? 'edit ticket' : 'assign ticket'
          },
          page: {
            app: {
              name: 'bulk ticket management',
              version: '1.0'
            },
            gamePk: gamePk,
            subPage: {
              title: 'available tickets'
            },
            title: 'group tickets',
            type: 'ticket display'
          }
        });
        setHasBeenEdited(true);
        setFormData({ recipientId: uuid(), name: '', email: '' });
        leftBtnClicked && isManAllocEditModeOn && dispatch(removeRecipient(targetRecipient[0]?.recipientId));
        handleModalClose();
      }
    },
    [
      dispatch,
      formData,
      t,
      currentSeat,
      setHasBeenEdited,
      isManAllocEditModeOn,
      targetRecipient,
      gamePk,
      handleModalClose,
      isCurrentTicketNonVenue
    ]
  );

  const handleModalCloseClick = useCallback(() => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 0,
          text: 'X',
          targetUrl: `/events/${gamePk}/available-tickets`
        }
      },
      page: {
        title: 'group tickets',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        type: 'ticket display',
        app: {
          name: 'bulk ticket management',
          version: '1.0'
        }
      },
      modal: {
        title: isManAllocEditModeOn ? 'edit ticket' : 'assign ticket',
        option: {
          indexTotal: 2
        }
      }
    });
    isManAllocEditModeOn && dispatch(disableManualAllocationEditMode());
    handleModalClose();
  }, [gamePk, handleModalClose, isManAllocEditModeOn, dispatch]);

  useEffect(() => {
    if (gamePk && isModalOpen) {
      userEvent({
        event: 'modal-impression',
        page: {
          title: 'group tickets',
          gamePk: gamePk,
          subPage: {
            title: 'available tickets'
          },
          app: {
            name: 'bulk ticket management',
            version: '1.0'
          }
        },
        modal: {
          title: isManAllocEditModeOn ? 'edit ticket' : 'assign ticket',
          option: {
            indexTotal: 1
          }
        }
      });
    }
  }, [gamePk, isModalOpen, isManAllocEditModeOn]);

  useEffect(() => {
    document.body.addEventListener('keydown', handleModalTab(modalRef), false);
    return () => {
      document.body.removeEventListener('keydown', handleModalTab(modalRef), false);
    };
  }, []);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        handleModalClose();
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
  }, [handleModalClose]);

  useEffect(() => {
    const closeOnClickOutsideModal = (e) => {
      if (isModalOpen && modalOverlayRef.current === e.target) {
        handleModalCloseClick();
      }
    };
    document.body.addEventListener('click', closeOnClickOutsideModal);
    return () => document.body.removeEventListener('click', closeOnClickOutsideModal);
  }, [isModalOpen, handleModalCloseClick]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = null;
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (isManAllocEditModeOn && targetRecipient[0]) {
      setFormData(targetRecipient[0]);
    }
  }, [targetRecipient, isManAllocEditModeOn]);

  const headerProps = {
    modalHeader:
      (isManAllocEditModeOn ? t('Edit') : t('Assign')) +
      ' ' +
      (isCurrentTicketNonVenue
        ? t('Parking')
        : t('Section') +
          ' ' +
          currentSeat?.location?.section?.value +
          ' - ' +
          t('Row') +
          ' ' +
          currentSeat?.location?.row?.value +
          ' - ' +
          t('Seat') +
          ' ' +
          currentSeat?.location?.seat?.value),
    modalDesc: t('Manual_Allocation_Modal_Desc'),
    isManualAllocOn: isModalOpen,
    isManAllocEditModeOn: isManAllocEditModeOn,
    handleManAllocModalClose: handleModalCloseClick
  };

  return (
    <ModalOverlay
      ref={modalOverlayRef}
      className={'modal_overlay' + (isModalOpen ? ' opened' : '')}
      aria-hidden={isModalOpen ? 'false' : 'true'}
    >
      <ModalContainer
        className={'add_modal_dialog' + (isModalOpen ? ' opened' : '')}
        id="man_alloc_modal"
        ref={modalRef}
        aria-labelledby="modal_title modal_desc"
        aria-modal={isModalOpen ? 'true' : 'false'}
        aria-hidden={isModalOpen ? 'false' : 'true'}
        role="dialog"
      >
        <ModalHeader ownProps={headerProps} />
        <ManualAllocationForm
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          formData={formData}
          handleCancelBtnClick={handleCancelBtnClick}
          isManAllocEditModeOn={isManAllocEditModeOn}
        />
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ManualAllocationModal;
