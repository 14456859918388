import { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloseBtn from '../../../assets/icons/CloseButton.svg';
import WarningIcon from '../../../assets/icons/WarningIcon.svg';
import RedWarningIcon from '../../../assets/icons/RedWarningIcon.svg';
import {
  useCancelTicketMutation,
  useForwardMutation,
  useRecallTicketMutation,
  useRemoveInvalidEmailMutation
} from '../../../components/TicketDisplay/TicketList/api/ticketDisplayListApi';
import { handleModalTab } from '../../../utils/helpers/handlers';
import ModalContainer from '../ModalContainer';
import ModalOverlay from '../ModalOverlay';
import {
  closeModal,
  openModal,
  selectCurrentForwardId,
  selectCurrentTicketId,
  selectTicketInfoForViewModal
} from './sharedTicketModalSlice';
import { userEvent } from '../../../utils/analytics';
import Loading from '../../../components/Shared/Loading/Loading';
import InvalidEmailModalMain from './InvalidEmailModalMain';
import './SharedTicketModal.scss';

const SharedTicketModal = ({ modalProps }) => {
  const {
    gamePk,
    homeTeamId,
    isRecallModalOpen,
    isCancelModalOpen,
    isViewModalOpen,
    isInvalidEmailModalOpen,
    isSharedTicketModalOpen,
    isBulkRecallActive,
    setManageBtnActive,
    checkedRecallIds,
    setHasBeenEdited,
    ticketsRefetch,
    bulkRecall,
    bulkRecallProgressRefetch,
    invalidEmailInfo
  } = modalProps;
  const modalRef = useRef();
  const modalOverlayRef = useRef();
  const descRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentTicketId, setCurrentTicketId] = useState();
  const currentModalTicketId = useSelector(selectCurrentTicketId);
  const forwardId = useSelector(selectCurrentForwardId);
  const [cancelTicket, { isLoading, error }] = useCancelTicketMutation();
  const [recallTicket, result] = useRecallTicketMutation();
  const currentTicketInfo = useSelector(selectTicketInfoForViewModal);
  const isBulkRecallModalOpen = isBulkRecallActive && isRecallModalOpen;
  const [invalidEmailModalData, setInvalidEmailModalData] = useState({
    name: '',
    email: '',
    ticketIds: []
  });
  const [formErrors, setFormErrors] = useState({ name: '', email: '' });
  const [removeInvalidEmail, removeEmailResult] = useRemoveInvalidEmailMutation();
  const [forward, forwardResult] = useForwardMutation();
  const isRecallInProgress = isLoading || result.isLoading || removeEmailResult.isLoading || forwardResult.isLoading;

  const handleInvalidEmailModalClose = useCallback(() => {
    setFormErrors({ name: '', email: '' });
    setInvalidEmailModalData({ name: '', email: '', ticketIds: [] });
    dispatch(closeModal());
  }, [dispatch]);

  const handleRemoveBtnClick = async () => {
    if (invalidEmailInfo[0]?.forwardId) {
      await removeInvalidEmail(invalidEmailInfo[0]?.forwardId);
      handleInvalidEmailModalClose();
    }
  };

  const handleRetryBtnClick = async () => {
    if (invalidEmailInfo[0]?.forwardId) {
      await removeInvalidEmail(invalidEmailInfo[0]?.forwardId);
      await forward([
        {
          recipientName: invalidEmailModalData?.name,
          recipientAddress: invalidEmailModalData?.email,
          ticketIds: invalidEmailModalData?.ticketIds
        }
      ]);
      handleInvalidEmailModalClose();
    }
  };

  useEffect(() => {
    if (isRecallModalOpen || isCancelModalOpen || isBulkRecallModalOpen || isInvalidEmailModalOpen) {
      userEvent({
        event: 'modal-impression',
        modal: {
          title: isBulkRecallModalOpen
            ? 'bulk recall alert'
            : isRecallModalOpen
            ? 'recall alert'
            : isCancelModalOpen
            ? 'cancel alert'
            : isInvalidEmailModalOpen
            ? 'invalid email'
            : '',
          option: {
            indexTotal: 1
          }
        },
        page: {
          title: 'group tickets',
          gamePk: gamePk,
          subPage: {
            title: 'shared tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        }
      });
    }
  }, [isRecallModalOpen, isCancelModalOpen, gamePk, isBulkRecallModalOpen, isInvalidEmailModalOpen]);

  useEffect(() => {
    if (currentModalTicketId && isSharedTicketModalOpen) {
      setCurrentTicketId(currentModalTicketId);
    }
  }, [currentModalTicketId, isSharedTicketModalOpen]);

  useEffect(() => {
    document.body.addEventListener('keydown', handleModalTab(modalRef), false);
    return () => {
      document.body.removeEventListener('keydown', handleModalTab(modalRef), false);
    };
  }, []);

  useEffect(() => {
    if (!isRecallInProgress) {
      const closeOnEscapeKey = (e) => {
        if ((e.charCode || e.keyCode) === 27) {
          isInvalidEmailModalOpen && handleInvalidEmailModalClose();
          dispatch(closeModal());
        }
      };
      document.body.addEventListener('keydown', closeOnEscapeKey);
      return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
    }
  }, [dispatch, handleInvalidEmailModalClose, isInvalidEmailModalOpen, isRecallInProgress]);

  useEffect(() => {
    if (!isRecallInProgress) {
      const closeOnClickOutsideModal = (e) => {
        if (isSharedTicketModalOpen && modalOverlayRef.current === e.target) {
          isInvalidEmailModalOpen && handleInvalidEmailModalClose();
          dispatch(closeModal());
        }
      };
      document.body.addEventListener('click', closeOnClickOutsideModal);
      return () => document.body.removeEventListener('click', closeOnClickOutsideModal);
    }
  }, [dispatch, handleInvalidEmailModalClose, isInvalidEmailModalOpen, isRecallInProgress, isSharedTicketModalOpen]);

  useEffect(() => {
    if (isSharedTicketModalOpen && modalRef?.current && descRef?.current) {
      modalRef.current.setAttribute('aria-labelledby', descRef.current.id);
    }
  }, [isSharedTicketModalOpen]);

  useEffect(() => {
    if (isSharedTicketModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = null;
    };
  }, [isSharedTicketModalOpen]);

  const handleCloseBtnClick = () => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 0,
          text: 'x',
          targetUrl: !isInvalidEmailModalOpen ? `/events/${gamePk}/shared-tickets` : ''
        }
      },
      modal: {
        title: isBulkRecallModalOpen
          ? 'bulk recall alert'
          : isRecallModalOpen
          ? 'recall alert'
          : isCancelModalOpen
          ? 'cancel alert'
          : isInvalidEmailModalOpen
          ? 'invalid email'
          : '',
        option: {
          indexTotal: 1
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    isInvalidEmailModalOpen && setInvalidEmailModalData({ name: '', email: '', ticketIds: [] });
    dispatch(closeModal());
  };

  const handleCancelBtnClick = () => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 1,
          text: 'no, go back',
          targetUrl: !isBulkRecallModalOpen ? `/events/${gamePk}/shared-tickets` : ''
        }
      },
      modal: {
        title: isBulkRecallModalOpen
          ? 'bulk recall alert'
          : isRecallModalOpen
          ? 'recall alert'
          : isCancelModalOpen
          ? 'cancel alert'
          : '',
        option: {
          indexTotal: 1
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    dispatch(closeModal());
  };

  const handleConfirmBtnClick = async () => {
    if (currentTicketId && (isCancelModalOpen || isViewModalOpen)) {
      userEvent({
        event: 'modal-click',
        action: {
          element: {
            index: 2,
            text: isCancelModalOpen ? 'yes, cancel sending' : '',
            targetUrl: `/events/${gamePk}/shared-tickets`
          }
        },
        modal: {
          title: isCancelModalOpen ? 'cancel alert' : '',
          option: {
            indexTotal: 1
          }
        },
        page: {
          title: 'group tickets',
          type: 'ticket display',
          gamePk: gamePk,
          subPage: {
            title: 'shared tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        }
      });
      if (forwardId && isCancelModalOpen) {
        await cancelTicket({ currentTicketId, forwardId });
      } else {
        await cancelTicket({ currentTicketId, gamePk, homeTeamId });
      }
      isCancelModalOpen ? dispatch(closeModal('cancel')) : dispatch(closeModal('view'));
    } else if (currentTicketId && forwardId && isRecallModalOpen) {
      userEvent({
        event: 'modal-click',
        action: {
          element: {
            index: 2,
            text: 'yes, recall',
            targetUrl: `/events/${gamePk}/shared-tickets`
          }
        },
        modal: {
          title: 'recall alert',
          option: {
            indexTotal: 1
          }
        },
        page: {
          title: 'group tickets',
          type: 'ticket display',
          gamePk: gamePk,
          subPage: {
            title: 'shared tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        }
      });
      await recallTicket({ currentTicketId, forwardId });
      dispatch(closeModal('recall'));
    }
  };

  const handleBulkRecallClick = useCallback(async () => {
    await bulkRecall({ gamePk: gamePk, body: checkedRecallIds });
    dispatch(closeModal());
    setManageBtnActive(false);
    bulkRecallProgressRefetch(); // initiate the first refresh as soon as the bulk recall modal clears
    dispatch(openModal('bulk_recall'));
    setTimeout(() => {
      setHasBeenEdited(true);
      ticketsRefetch();
    }, 3000); // per product's request
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 2,
          text: 'yes, recall',
          targetUrl: `/events/${gamePk}/shared-tickets`
        },
        ticket: { quantity: checkedRecallIds?.length }
      },
      modal: {
        title: 'bulk recall alert',
        option: {
          indexTotal: 1
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
  }, [
    bulkRecall,
    checkedRecallIds,
    dispatch,
    gamePk,
    setHasBeenEdited,
    setManageBtnActive,
    ticketsRefetch,
    bulkRecallProgressRefetch
  ]);

  return (
    <ModalOverlay
      className={'modal_overlay' + (isSharedTicketModalOpen ? ' opened' : '')}
      ref={modalOverlayRef}
      aria-hidden={isSharedTicketModalOpen ? 'false' : 'true'}
    >
      <ModalContainer
        id={
          isBulkRecallModalOpen
            ? 'bulk_ticket_recall_modal'
            : isRecallModalOpen
            ? 'ticket_recall_modal'
            : isCancelModalOpen
            ? 'ticket_cancel_modal'
            : isViewModalOpen
            ? 'ticket_view_modal'
            : isInvalidEmailModalOpen
            ? 'invalid_email_modal'
            : null
        }
        className={
          'shared_ticket_modal' +
          (isSharedTicketModalOpen ? ' opened' : '') +
          (isLoading || result.isLoading || removeEmailResult?.isLoading || forwardResult.isLoading ? ' loading' : '')
        }
        aria-modal={isSharedTicketModalOpen ? 'true' : 'false'}
        aria-hidden={isSharedTicketModalOpen ? 'false' : 'true'}
        role="dialog"
        ref={modalRef}
      >
        {isRecallInProgress ? (
          <Loading />
        ) : error || result.error || removeEmailResult.isError || forwardResult.isError ? (
          <output>{t('Error')}</output>
        ) : isInvalidEmailModalOpen ? (
          <InvalidEmailModalMain
            setHasBeenEdited={setHasBeenEdited}
            invalidEmailInfo={invalidEmailInfo}
            formData={invalidEmailModalData}
            setFormData={setInvalidEmailModalData}
            handleCloseBtnClick={handleCloseBtnClick}
            handleRemoveBtnClick={handleRemoveBtnClick}
            handleRetryBtnClick={handleRetryBtnClick}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            gamePk={gamePk}
          />
        ) : (
          <>
            {(isBulkRecallModalOpen || isCancelModalOpen || isRecallModalOpen) && (
              <button
                id={
                  isBulkRecallActive
                    ? 'bulk_recall_modal_close_btn'
                    : isRecallModalOpen
                    ? 'recall_modal_close_btn'
                    : isCancelModalOpen
                    ? 'cancel_modal_close_btn'
                    : isBulkRecallModalOpen
                    ? 'bulk_recall_modal_close_btn'
                    : null
                }
                className="modal_close"
                aria-label={t('Modal_Close_Btn')}
                type="button"
                autoFocus
                onClick={handleCloseBtnClick}
              >
                <img className="modal_close_icon" src={CloseBtn} alt={t('Modal_Close_Btn')} />
              </button>
            )}
            <img
              className="warning_icon"
              src={isViewModalOpen ? RedWarningIcon : WarningIcon}
              alt={t('Warning_Icon')}
            />
            <p id="shared_ticket_modal_desc" className="modal_text" ref={descRef}>
              {isRecallModalOpen && isBulkRecallActive && checkedRecallIds?.length > 1
                ? t('Bulk_Recall_Modal_Text_For_Multiple_Tickets')
                : isRecallModalOpen && isBulkRecallActive && checkedRecallIds?.length === 1
                ? t('Bulk_Recall_Modal_Text_For_Single_Ticket')
                : isRecallModalOpen
                ? t('Recall_ticket_text')
                : isCancelModalOpen
                ? t('Cancel_ticket_text')
                : isViewModalOpen
                ? t('View_Modal_Text_1') +
                  currentTicketInfo.name +
                  ' (' +
                  currentTicketInfo.email +
                  '). ' +
                  t('View_Modal_Text_2')
                : null}
            </p>
            <div className={'shared_tix_modal_footer' + (isViewModalOpen ? ' shared_modal_view' : '')}>
              <button
                id={
                  isBulkRecallModalOpen
                    ? 'bulk_tix_recall_cancel_btn'
                    : isRecallModalOpen
                    ? 'recall_tix_cancel_btn'
                    : isCancelModalOpen
                    ? 'cancel_tix_cancel_btn'
                    : isViewModalOpen
                    ? 'tix_cancel_share_btn'
                    : ''
                }
                aria-label={isViewModalOpen ? 'cancel this share' : 'go back'}
                type="button"
                onClick={isViewModalOpen ? handleConfirmBtnClick : handleCancelBtnClick}
              >
                {isViewModalOpen ? t('Cancel_Share') : t('Recall_modal_cancel')}
              </button>
              <button
                id={
                  isBulkRecallModalOpen
                    ? 'bulk_tix_recall_confirm_btn'
                    : isRecallModalOpen
                    ? 'tix_recall_confirm_btn'
                    : isCancelModalOpen
                    ? 'tix_cancel_confirm_btn'
                    : isViewModalOpen
                    ? 'tix_keep_trying_btn'
                    : ''
                }
                aria-label={
                  isRecallModalOpen
                    ? 'recall ticket'
                    : isCancelModalOpen
                    ? 'cancel sending'
                    : isViewModalOpen
                    ? 'keep trying'
                    : ''
                }
                type="button"
                onClick={
                  isViewModalOpen
                    ? handleCancelBtnClick
                    : isBulkRecallActive
                    ? handleBulkRecallClick
                    : handleConfirmBtnClick
                }
              >
                {isRecallModalOpen
                  ? t('Recall_modal_confirm')
                  : isCancelModalOpen
                  ? t('Cancel_modal_confirm')
                  : isViewModalOpen
                  ? t('Keep_Trying')
                  : ''}
              </button>
            </div>
          </>
        )}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default SharedTicketModal;
