import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import Spreadsheet from '../spreadsheet/Spreadsheet';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, updateTicketAmount, selectInitSpreadsheetData, addRecipients } from './modalSlice';
import { cleanUserData } from '../../utils/autoAllocation';
import { userEvent } from '../../utils/analytics';
import { emailValidator } from '../../utils/helpers/validators';
import { ReactComponent as WarningIcon } from '../../assets/icons/ValidationErrorIcon.svg';

const PeopleModalMain = ({
  handleTicketCountChange,
  handleModalClose,
  ticketRemainingAmount,
  gamePk,
  setHasBeenEdited,
  modalRef
}) => {
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const hotTableRef = useRef();
  const [validationActive, setValidationActive] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initData = useSelector(selectInitSpreadsheetData);

  useEffect(() => {
    //This is needed to add the tooltip to the first invalid cell after validation
    const hot = hotTableRef.current.hotInstance;
    if (validationActive) {
      const currentSelection = hot.getSelected();
      hot.selectCell(
        currentSelection[0][0],
        currentSelection[0][1],
        currentSelection[0][2],
        currentSelection[0][3],
        true
      );
    }
  }, [validationActive]);

  const addTooltipOnSelect = (row, column, row2, column2) => {
    //Adds a tooltip to a selected cell when validation is active
    const modalRect = modalRef.current.getBoundingClientRect();
    const hot = hotTableRef.current.hotInstance;
    const tooltip = document.querySelector('.hot-tooltip');
    tooltip.style.visibility = 'hidden';
    if (validationActive) {
      if (row === row2 && column === column2 && !hot.isEmptyRow(row)) {
        const value = hot.getDataAtCell(row, column);
        if (!value || value === '') {
          const TD = hot.getCell(row, column);
          tooltip.style.visibility = 'visible';
          tooltip.innerHTML = t('Required_field');
          const tdRect = TD.getBoundingClientRect();
          const tooltipRect = tooltip.getBoundingClientRect();
          tooltip.style.top = `${tdRect.top - modalRect.top - 35}px`;
          tooltip.style.left = `${tdRect.x - modalRect.x + (tdRect.width - tooltipRect.width) / 2}px`;
        } else if (column === 1 && !emailValidator.test(value)) {
          const TD = hot.getCell(row, column);
          tooltip.style.visibility = 'visible';
          tooltip.innerHTML = t('Email_invalid');
          const tdRect = TD.getBoundingClientRect();
          const tooltipRect = tooltip.getBoundingClientRect();
          tooltip.style.top = `${tdRect.top - modalRect.top - 35}px`;
          tooltip.style.left = `${tdRect.x - modalRect.x + (tdRect.width - tooltipRect.width) / 2}px`;
        }
      }
    }
  };

  const validateSave = () => {
    //Validates all data
    const ht = hotTableRef.current.hotInstance;
    for (let i = 0; i < ht.countRows(); i++) {
      if (!ht.isEmptyRow(i)) {
        if (!ht.getDataAtCell(i, 0) || ht.getDataAtCell(i, 0) === '') {
          setValidationActive(true);
          ht.selectCell(i, 0, i, 0, true);
          return false;
        }
        if (!ht.getDataAtCell(i, 1) || ht.getDataAtCell(i, 1) === '' || !emailValidator.test(ht.getDataAtCell(i, 1))) {
          setValidationActive(true);
          ht.selectCell(i, 1, i, 1, true);
          return false;
        }
        if (!ht.getDataAtCell(i, 2) || ht.getDataAtCell(i, 2) === '') {
          setValidationActive(true);
          ht.selectCell(i, 2, i, 2, true);
          return false;
        }
      }
    }
    return true;
  };

  const handlePeopleModalSave = (e) => {
    setValidationActive(true);
    e.preventDefault();
    userEvent({
      action: {
        element: {
          index: 2,
          text: 'save and continue to ticket view : auto allocate',
          targetUrl: '/available-tickets'
        }
      },
      event: 'modal-click',
      modal: {
        option: {
          indexTotal: 2
        },
        title: 'add multiple people'
      },
      page: {
        app: {
          name: 'bulk ticket management'
        },
        gamePk: gamePk,
        title: 'group tickets'
      }
    });
    if (validateSave() === false) {
      return;
    }
    setValidationActive(false);
    dispatch(addRecipients(cleanUserData(spreadsheetData)));
    dispatch(updateTicketAmount(ticketRemainingAmount));
    dispatch(closeModal('spreadsheet'));
    setHasBeenEdited(true);
  };

  useEffect(() => {
    userEvent({
      event: 'modal-impression',
      modal: {
        option: {
          indexTotal: 1
        },
        title: 'add multiple people'
      },
      page: {
        app: {
          name: 'bulk ticket management'
        },
        gamePk: gamePk,
        title: 'group tickets',
        subPage: {
          title: 'available tickets'
        }
      }
    });
  }, [gamePk]);

  useEffect(() => {
    const updatedData = initData.map((item) => [...item]);
    setSpreadsheetData(updatedData);
  }, [initData]);

  return (
    <>
      <div className="add_modal_body spreadsheet" tabIndex="0" aria-label={t('spreadsheet_desc')}>
        {validationActive ? (
          <div className="people-validation" role="alert">
            <WarningIcon className="warning-icon" alt={t('Validation_Error')} />
            <span>{t('People_Validation_Error')}</span>
          </div>
        ) : null}
        <Spreadsheet
          hotTableRef={hotTableRef}
          validationActive={validationActive}
          initData={spreadsheetData}
          handleTicketCountChange={handleTicketCountChange}
          addTooltipOnSelect={addTooltipOnSelect}
          setIsSaveDisabled={setIsSaveDisabled}
          ticketRemainingAmount={ticketRemainingAmount}
          modalRef={modalRef}
        />
      </div>
      <div className="add_modal_footer spreadsheet_footer">
        <button
          className="modal_footer_left_btn"
          id="modal_cancel_btn"
          type="button"
          onClick={(e) => handleModalClose(e, true)}
        >
          {t('Modal_Cancel_Btn')}
        </button>
        <button
          className="modal_footer_right_btn"
          disabled={isSaveDisabled}
          type="submit"
          id="modal_save_btn"
          onClick={handlePeopleModalSave}
        >
          {t('Modal_Save_Btn')}
        </button>
      </div>
    </>
  );
};

export default PeopleModalMain;
