import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeAllRecipients,
  selectShouldEventsRefresh,
  selectShouldTicketsRefresh,
  setSaveData,
  setShouldEventsRefresh,
  setShouldTicketsRefresh
} from '../../features/modal/modalSlice';
import { useCallback, useEffect } from 'react';
import EventList from './EventLIst/EventList';
import EventTutorial from './EventTutorial/EventTutorial';
import { RefreshIcon } from '../Shared/Icons/RefreshIcon';
import { useGetEventsQuery } from '../TicketDisplay/TicketList/api/ticketDisplayListApi';
import { userEvent } from '../../utils/analytics';
import { ticketsApi } from '../TicketDisplay/TicketList/api/ticketDisplayListApi';
import './EventDisplay.scss';
import VerfiedAccountModal from './VerifiedAccountModal/VerifiedAccountModal';
import { closeModal, selectBulkRecallToastOpen } from '../../features/modal/SharedTicketModals/sharedTicketModalSlice';

const EventDisplay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refresh = useSelector(selectShouldEventsRefresh);
  const { data, error, isLoading } = useGetEventsQuery({ refresh: refresh });
  const shouldTicketsRefresh = useSelector(selectShouldTicketsRefresh);
  const isBulkRecallToastOpen = useSelector(selectBulkRecallToastOpen);

  useEffect(() => {
    if (data) {
      let gamePks = '';
      data.forEach((game, i, array) => {
        gamePks += game.gamePk;
        if (i !== array.length - 1) {
          gamePks += ',';
        }
      });
      userEvent({
        event: 'page-view',
        page: {
          title: 'group tickets',
          type: 'event display',
          app: {
            name: 'bulk ticket management'
          },
          content: {
            gamePk: {
              list: gamePks,
              count: data.length
            }
          }
        }
      });
    }
  }, [data]);

  const refreshClick = useCallback(() => {
    if (refresh) {
      dispatch(ticketsApi.util.invalidateTags(['Events']));
    }
    dispatch(setShouldEventsRefresh(true));
  }, [dispatch, refresh]);

  useEffect(() => {
    if (shouldTicketsRefresh) {
      dispatch(setShouldTicketsRefresh(false));
    }
  }, [dispatch, shouldTicketsRefresh]);

  useEffect(() => {
    if (isBulkRecallToastOpen) {
      dispatch(closeModal('bulk_recall'));
    }
  }, [dispatch, isBulkRecallToastOpen]);

  useEffect(() => {
    dispatch(removeAllRecipients());
    dispatch(setSaveData(false));
  }, [dispatch]);

  return (
    <main className="event_page_cont">
      <div className="events_col">
        <div className="events_col_header">
          <h3>{t('Upcoming_Events')}</h3>
          <RefreshIcon refetch={refreshClick} />
        </div>
        <EventList data={data} isLoading={isLoading} error={error} />
      </div>
      <div className="event_tutorial_col">
        <EventTutorial />
      </div>
      {data ? <VerfiedAccountModal data={data} /> : null}
    </main>
  );
};

export default EventDisplay;
