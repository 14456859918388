import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddPersonForm from '../../components/AddPersonForm/AddPersonForm';
import { emailValidator, integerValidator } from '../../utils/helpers/validators';
import {
  updateTicketAmount,
  closeModal,
  addRecipients,
  selectRecipient,
  selectEditMode,
  updateRecipient,
  removeRecipient
} from './modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userEvent } from '../../utils/analytics';
import { v4 as uuid } from 'uuid';

const PersonModalMain = ({
  ticketRemainingAmount,
  updateTicketRemainingAmount,
  gamePk,
  isPersonModalOpen,
  setHasBeenEdited
}) => {
  const targetPerson = useSelector(selectRecipient);
  const isEditModeOn = useSelector(selectEditMode);
  const [formData, setFormData] = useState({ recipientId: uuid(), name: '', email: '', seatCount: '' });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState({ name: '', email: '', seatCount: '' });

  const handleInputChange = useCallback(
    (e) => {
      let { name, value } = e.target;
      if (name === 'seatCount') {
        if (value.includes('-') || !integerValidator.test(value)) {
          value = '';
          return;
        } else if (Number(value) > Number(formData?.seatCount)) {
          updateTicketRemainingAmount(
            (updatedTicketAmount) => updatedTicketAmount - (Number(value) - Number(formData.seatCount))
          );
        } else {
          updateTicketRemainingAmount(
            (updatedTicketAmount) => updatedTicketAmount + (Number(formData.seatCount) - Number(value))
          );
        }
      }
      if (formErrors[name] && value) {
        setFormErrors({ ...formErrors, [name]: '' });
      }
      setFormData({
        ...formData,
        [name]: value
      });
    },
    [formData, updateTicketRemainingAmount, formErrors]
  );

  const handlePersonFormSubmit = useCallback(
    (e, leftBtnClicked) => {
      let isValid = true;
      const validatePersonForm = (formData) => {
        Object.keys(formData).forEach((key) => {
          if (formData[key] === '') {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Required_field') };
            });
            isValid = false;
          } else if (key === 'email' && !emailValidator.test(formData[key])) {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Email_invalid') };
            });
            isValid = false;
          } else if (key === 'seatCount' && (!formData[key] || Number(formData[key]) === 0 || isNaN(formData[key]))) {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Required_field') };
            });
            isValid = false;
          }
        });
      };
      e.preventDefault();
      validatePersonForm(formData);
      if (isValid) {
        dispatch(updateTicketAmount(ticketRemainingAmount));
        userEvent({
          event: 'modal-click',
          action: {
            element: {
              index: leftBtnClicked ? 1 : 2,
              text:
                leftBtnClicked && isEditModeOn
                  ? 'remove recipient'
                  : isEditModeOn
                  ? 'save changes'
                  : leftBtnClicked
                  ? 'save and view tickets'
                  : 'save and add another',
              targetUrl: leftBtnClicked || isEditModeOn ? `/events/${gamePk}/available-tickets` : ''
            }
          },
          modal: {
            option: {
              indexTotal: isEditModeOn ? 1 : 2
            },
            title: isEditModeOn ? 'edit person' : 'add person'
          },
          page: {
            app: {
              name: 'bulk ticket management'
            },
            gamePk: gamePk,
            subPage: {
              title: 'available tickets'
            },
            title: 'group tickets',
            type: 'ticket display'
          }
        });
        isEditModeOn ? dispatch(updateRecipient(formData)) : dispatch(addRecipients([formData]));
        setFormData({ ...formData, recipientId: uuid(), name: '', email: '', seatCount: '' });
        leftBtnClicked && isEditModeOn && dispatch(removeRecipient(targetPerson[0]?.recipientId));
        leftBtnClicked && dispatch(closeModal('person'));
        setHasBeenEdited(true);
      }
    },
    [dispatch, formData, ticketRemainingAmount, gamePk, isEditModeOn, targetPerson, t, setHasBeenEdited]
  );

  useEffect(() => {
    if (gamePk && isPersonModalOpen) {
      userEvent({
        event: 'modal-impression',
        page: {
          title: 'group tickets',
          gamePk: gamePk,
          subPage: {
            title: 'available tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        },
        modal: {
          title: isEditModeOn ? 'edit person' : 'add person',
          option: {
            indexTotal: 1
          }
        }
      });
    }
  }, [gamePk, isPersonModalOpen, isEditModeOn]);

  useEffect(() => {
    if (isEditModeOn && targetPerson[0]?.recipientId) {
      setFormData(targetPerson[0]);
    }
  }, [isEditModeOn, targetPerson]);

  return (
    <div className="add_modal_body">
      <AddPersonForm
        formData={formData}
        handleInputChange={handleInputChange}
        handlePersonFormSubmit={handlePersonFormSubmit}
        formErrors={formErrors}
        ticketRemainingAmount={ticketRemainingAmount}
      />
    </div>
  );
};

export default PersonModalMain;
