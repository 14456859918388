import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEditMode } from '../../features/modal/modalSlice';
import { ReactComponent as WarningIcon } from '../../assets/icons/ValidationErrorIcon.svg';
import './AddPersonForm.scss';

const AddPersonForm = ({ formData, handleInputChange, handlePersonFormSubmit, formErrors, ticketRemainingAmount }) => {
  const { t } = useTranslation();
  const isEditModeOn = useSelector(selectEditMode);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const nameField = useRef();
  const emailField = useRef();
  const seatCountField = useRef();
  const ticketExceedingError = useRef();
  const { name, email, seatCount } = formErrors || {};
  const disabled = isBtnDisabled || ticketRemainingAmount < 0;

  useEffect(() => {
    const { name, email, seatCount } = formData || {};
    if (name === '' && email === '' && seatCount === '') {
      if (!isBtnDisabled) {
        setIsBtnDisabled(true);
      }
    } else {
      setIsBtnDisabled(false);
    }
  }, [formData, isBtnDisabled]);

  useEffect(() => {
    if (name && nameField?.current) {
      nameField.current.focus();
    } else if (email && emailField?.current) {
      emailField.current.focus();
    } else if (seatCount && seatCountField?.current) {
      seatCountField.current.focus();
    }
  }, [name, email, seatCount]);

  return (
    <form className="add_person_form">
      <div className="input_cont">
        <label className={'person_modal_label' + (name ? ' error' : '')} htmlFor="person_name">
          {t('Name')}
        </label>
        <div className="input_wrapper">
          <input
            id="person_name"
            className={'person_modal_input' + (name ? ' error' : '')}
            type="text"
            name="name"
            value={formData?.name}
            aria-label={t('Name')}
            aria-required={true}
            onChange={handleInputChange}
            ref={nameField}
            aria-invalid={name ? true : false}
            aria-describedby="person_name_error"
          />
          {name && (
            <span className="validationError">
              <WarningIcon />
            </span>
          )}
        </div>
        {name && (
          <span id="person_name_error" className="form_error_message" role="alert">
            {name}
          </span>
        )}
      </div>
      <div className="input_cont">
        <label className={'person_modal_label' + (email ? ' error' : '')} htmlFor="person_email">
          {t('Email')}
        </label>
        <div className="input_wrapper">
          <input
            id="person_email"
            className={'person_modal_input' + (email ? ' error' : '')}
            type="email"
            name="email"
            value={formData?.email}
            aria-label={t('Email')}
            aria-required={true}
            onChange={handleInputChange}
            ref={emailField}
            aria-invalid={email ? true : false}
            aria-describedby="person_email_error"
          />
          {email && (
            <span className="validationError">
              <WarningIcon />
            </span>
          )}
        </div>
        {email && (
          <span id="person_email_error" className="form_error_message" role="alert">
            {email}
          </span>
        )}
      </div>
      <div className="input_cont">
        <label
          className={'person_modal_label' + (seatCount || ticketRemainingAmount < 0 ? ' error' : '')}
          htmlFor="person_seat_count"
        >
          {t('Number_of_Seats')}
        </label>
        <div className="input_wrapper">
          <input
            id="person_seat_count"
            className={'person_modal_input' + (seatCount || ticketRemainingAmount < 0 ? ' error' : '')}
            value={formData?.seatCount}
            type="text"
            name="seatCount"
            aria-label={t('Number_of_Seats')}
            aria-required={true}
            onChange={handleInputChange}
            ref={seatCountField}
            aria-invalid={seatCount || ticketRemainingAmount < 0 ? true : false}
            aria-describedby="person_seat_error"
            data-testid="seatCount"
          />
          {(seatCount || ticketRemainingAmount < 0) && (
            <span className="validationError">
              <WarningIcon />
            </span>
          )}
        </div>
        {(seatCount || ticketRemainingAmount < 0) && (
          <span
            id="person_seat_error"
            className="form_error_message"
            aria-live="polite"
            role="alert"
            ref={ticketExceedingError}
            aria-details="tix_exceeding_error"
            data-testid="seatCountError"
          >
            {ticketRemainingAmount < 0 && <> {t('ticket_exceeding_text')}</>}
            {seatCount && seatCount}
          </span>
        )}
      </div>
      <div className="add_modal_footer form_footer">
        <button
          className="modal_footer_left_btn"
          id={isEditModeOn ? 'remove_recipient_btn' : 'save_and_view_btn'}
          disabled={disabled}
          onClick={(e) => handlePersonFormSubmit(e, true)}
          aria-label={isEditModeOn ? t('Remove_Person_Btn') : t('Save_and_View_Btn')}
        >
          {isEditModeOn ? t('Remove_Person_Btn') : t('Save_and_View_Btn')}
        </button>
        <button
          className="modal_footer_right_btn"
          disabled={disabled}
          id={isEditModeOn ? 'save_recipient_changes_btn' : 'save_and_add_btn'}
          aria-label={isEditModeOn ? t('Save_Changes_Btn') : t('Save_and_Add_Btn')}
          onClick={(e) => handlePersonFormSubmit(e, false)}
        >
          {isEditModeOn ? t('Save_Changes_Btn') : t('Save_and_Add_Btn')}
        </button>
      </div>
    </form>
  );
};

export default AddPersonForm;
