// this file is to house simple input validators

export const integerValidator = /^[0-9]*$/;

export const emailValidator =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const dateFormatValidator = /^\d{4}-\d{2}-\d{2}$/;

export const timeFormatValidator = /^([0-1]?[0-9]):([0-5][0-9])?\s([AP][M])\s([A-Z][A-Z])$/;
