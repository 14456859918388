import './CircleProgress.scss';
import { useTranslation } from 'react-i18next';

export const CircleProgress = ({ size, strokeWidth, progress }) => {
  const { t } = useTranslation();
  const center = size / 2;
  const radius = center - strokeWidth;
  const arcLength = 2 * 3.14 * radius;
  const arcOffset = arcLength * ((100 - progress) / 100);

  return (
    <div className="progress-indicator" data-testid="circle-progress">
      <svg
        className="svg-indicator"
        style={{ width: `${size}px`, height: `${size}px` }}
        aria-labelledby="circleProgressTitle circileProgressDescription"
      >
        <title id="circleProgressTitle">{t('Ticket_Sharing_Progress')}</title>
        <desc id="circileProgressDescription">{`${progress}%`}</desc>
        <circle
          className="svg-indicator-track"
          style={{ cx: `${center}`, cy: `${center}`, r: `${radius}`, strokeWidth: `${strokeWidth}px` }}
        />
        <circle
          className="svg-indicator-indication"
          style={{
            cx: `${center}`,
            cy: `${center}`,
            r: `${radius}`,
            strokeWidth: `${strokeWidth}px`,
            strokeDasharray: `${arcLength}px`,
            strokeDashoffset: `${arcOffset}px`
          }}
        />
      </svg>
    </div>
  );
};
