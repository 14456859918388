import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutClose, logoutToggle, selectLogout } from '../../../features/logout/logoutSlice';
import './Icons.scss';
import {
  selectEditMode,
  selectPeopleModalOpen,
  selectPersonModalOpen,
  selectManualAllocationModalOpen,
  selectRemoveTicketsModalState
} from '../../../features/modal/modalSlice';
import { t } from 'i18next';
import {
  selectBulkRecallToastOpen,
  selectCancelModalOpen,
  selectInvalidEmailModalOpen,
  selectRecallModalOpen,
  selectViewModalOpen
} from '../../../features/modal/SharedTicketModals/sharedTicketModalSlice';

export const MenuIcon = () => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const isLogoutOpen = useSelector(selectLogout);
  const isPeopleModalOpen = useSelector(selectPeopleModalOpen);
  const isPersonModalOpen = useSelector(selectPersonModalOpen);
  const isEditModeOn = useSelector(selectEditMode);
  const isRecallModalOpen = useSelector(selectRecallModalOpen);
  const isCancelModalOpen = useSelector(selectCancelModalOpen);
  const isManualAllocModalOpen = useSelector(selectManualAllocationModalOpen);
  const isViewModalOpen = useSelector(selectViewModalOpen);
  const isRemoveTicketsModalOpen = useSelector(selectRemoveTicketsModalState);
  const isBulkRecallToastOpen = useSelector(selectBulkRecallToastOpen);
  const isInvalidEmailModalOpen = useSelector(selectInvalidEmailModalOpen);
  const isModalOpen =
    isPeopleModalOpen ||
    isPersonModalOpen ||
    isEditModeOn ||
    isRecallModalOpen ||
    isCancelModalOpen ||
    isViewModalOpen ||
    isManualAllocModalOpen ||
    isRemoveTicketsModalOpen ||
    isBulkRecallToastOpen ||
    isInvalidEmailModalOpen;

  useEffect(() => {
    const closeOnClickOutside = (e) => {
      if (isLogoutOpen && menuRef && !menuRef.current?.contains(e.target)) {
        dispatch(logoutClose());
      }
    };
    document.body.addEventListener('click', closeOnClickOutside);
    return () => {
      document.removeEventListener('click', closeOnClickOutside);
    };
  }, [isLogoutOpen, dispatch]);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        dispatch(logoutClose());
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
  }, [dispatch]);

  return (
    <button
      id="hamburger_menu"
      ref={menuRef}
      type="button"
      aria-label={t('Open_Logoug_Menu')}
      aria-haspopup="true"
      aria-expanded={isLogoutOpen ? 'true' : 'false'}
      aria-controls="logout-button"
      className="hamburger_menu"
      tabIndex={isModalOpen ? '-1' : '0'}
      onClick={() => dispatch(logoutToggle())}
      data-testid="hamburger_menu"
    >
      <div className="icon_bar_1" />
      <div className="icon_bar_2" />
      <div className="icon_bar_3" />
    </button>
  );
};
// I custom built this navbar icon just for the time being. May remove it if we're to go with any icon package down the road
