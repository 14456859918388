import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectTimeOfSave } from '../../../features/modal/modalSlice';

const SaveTimer = ({ gamePk }) => {
  const [timeSinceSave, setTimeSinceSave] = useState();
  const { t } = useTranslation();
  const timeOfSave = useSelector(selectTimeOfSave);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeOfSave && timeOfSave[gamePk]) {
        const minutesSinceSave = Math.ceil((new Date() - new Date(timeOfSave[gamePk])) / 1000 / 60);
        setTimeSinceSave(minutesSinceSave);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [gamePk, timeOfSave]);

  return (
    <>
      {timeSinceSave ? (
        <>
          {timeSinceSave > 10 ? (
            <small data-testid="save_timer_max" className="auto_save_timer">
              {t('Draft_saved_ten')}
            </small>
          ) : (
            <small data-testid="save_timer" className="auto_save_timer">
              {t('Draft_saved')} {timeSinceSave} {t('Minutes_ago')}
            </small>
          )}
        </>
      ) : null}
    </>
  );
};

export default SaveTimer;
