import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../features/modal/modalSlice';
import { openModal as openBulkRecallModal } from '../../../features/modal/SharedTicketModals/sharedTicketModalSlice';
import { userEvent } from '../../../utils/analytics';
import {
  selectAreThereForwardableTickets,
  selectManuallyAllocatableTicketCount
} from '../../../stores/allocationSlice';
import { useNavigate } from 'react-router-dom';
import SidePanelShare from './SidePanelShare';
import SaveTimer from './SaveTimer';
import './SidePanel.scss';
import SidePanelManageList from './SidePanelManageList';
import SidePanelRecallTickets from './SidePanelRecallTickets';
import { useCallback } from 'react';

const SidePanel = ({
  isOnStealthMode,
  gamePk,
  homeTeamId,
  isShareActive,
  setIsShareActive,
  setHasBeenEdited,
  modalBtnDisabled,
  isManageBtnActive,
  isManageBtnOnForwardsActive,
  isBulkRecallActive,
  hasBeenEdited,
  eventTime,
  setShouldDisplayLoader
}) => {
  const buttonDisabled = !useSelector(selectAreThereForwardableTickets);
  const addPeopleBtnDisabled = useSelector(selectManuallyAllocatableTicketCount) <= 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sidePanelClassName = `ticket_display_side_panel${
    isShareActive
      ? ' share_side_panel'
      : isManageBtnActive
      ? ' manage_list_side_panel'
      : isManageBtnOnForwardsActive
      ? ' recall_tix_side_panel'
      : ''
  }`;

  const handleAddBtnClick = (e, addPeopleBtnClicked) => {
    navigate(`/events/${gamePk}/available-tickets`);
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: addPeopleBtnClicked ? 0 : 1,
          text: addPeopleBtnClicked ? 'add multiple people' : 'add person'
        }
      },
      page: {
        app: {
          name: 'bulk ticket management'
        },
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        title: 'group tickets',
        type: 'ticket detail'
      }
    });
    dispatch(openModal(addPeopleBtnClicked ? 'spreadsheet' : 'person'));
  };

  const handleShareClick = () => {
    navigate(`/events/${gamePk}/available-tickets`);
    setShouldDisplayLoader(true);
    setIsShareActive(true);
  };

  const handleRemoveSelectedBtnClick = () => {
    dispatch(openModal('removeTickets'));
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 1,
          text: 'remove selected'
        }
      },
      page: {
        app: {
          name: 'bulk ticket management'
        },
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        title: 'group tickets',
        type: 'ticket display'
      }
    });
  };

  const handleRecallTicketsBtnClick = useCallback(() => {
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 1,
          text: 'recall tickets'
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    dispatch(openBulkRecallModal('recall'));
  }, [dispatch, gamePk]);

  return (
    <aside className={sidePanelClassName}>
      {isShareActive ? (
        <SidePanelShare
          gamePk={gamePk}
          homeTeamId={homeTeamId}
          isOnStealthMode={isOnStealthMode}
          setIsShareActive={setIsShareActive}
          setHasBeenEdited={setHasBeenEdited}
          eventTime={eventTime}
        />
      ) : isManageBtnActive ? (
        <>
          <SidePanelManageList handleRemoveSelectedBtnClick={handleRemoveSelectedBtnClick} />
          {hasBeenEdited && <div className="panel_hr" />}
        </>
      ) : isManageBtnOnForwardsActive ? (
        <>
          <SidePanelRecallTickets
            isBulkRecallActive={isBulkRecallActive}
            handleBtnClick={handleRecallTicketsBtnClick}
            isOnStealthMode={isOnStealthMode}
          />
          {hasBeenEdited && <div className="panel_hr" />}
        </>
      ) : (
        <>
          <button
            id="ticket_add_ppl_btn"
            onClick={(e) => handleAddBtnClick(e, true)}
            tabIndex={isOnStealthMode ? '-1' : '0'}
            aria-label={t('Add_People')}
            type="button"
            aria-haspopup="true"
            disabled={addPeopleBtnDisabled}
          >
            {t('Add_People_Text')}
          </button>
          <button
            id="ticket_add_person_btn"
            onClick={(e) => handleAddBtnClick(e, false)}
            aria-label={t('Add_Person')}
            type="button"
            aria-haspopup="true"
            tabIndex={isOnStealthMode ? '-1' : '0'}
            disabled={modalBtnDisabled}
          >
            {t('Add_Person')}
          </button>{' '}
          <div className="panel_hr" />
          <button
            id="share_tix_btn"
            disabled={buttonDisabled}
            tabIndex={isOnStealthMode ? '-1' : '0'}
            aria-label={t('Share_Tickets')}
            onClick={handleShareClick}
          >
            {t('Share_Tickets')}
          </button>
        </>
      )}
      <SaveTimer gamePk={gamePk} />
    </aside>
  );
};

export default SidePanel;
