import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Shared/Loading/Loading';
import { updateDataLayer } from '../utils/analytics';
import { datadogRum } from '@datadog/browser-rum';

const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState?.isAuthenticated, authState]);

  useEffect(() => {
    if (authState) {
      const renewToken = async () => {
        await oktaAuth.tokenManager.renew('idToken');
        await oktaAuth.tokenManager.renew('accessToken');
      };

      const checkExpiration = () => {
        if ((authState.accessToken?.expiresAt * 1000 - Date.now()) / 1000 / 60 / 60 < 1) {
          renewToken();
        }
      };

      checkExpiration();
      const timer = setInterval(checkExpiration, 10000);

      return () => {
        clearInterval(timer);
      };
    }
  });

  if (!authState || !authState?.isAuthenticated) {
    return <Loading />;
  }

  if (authState?.idToken?.claims && authState?.accessToken?.claims?.uid) {
    datadogRum.setUser({
      id: authState.accessToken.claims.uid,
      name: authState.idToken.claims.name,
      email: authState.idToken.claims.email
    });

    updateDataLayer({
      user: {
        oktaId: authState.accessToken.claims.uid
      }
    });
  }

  return <Outlet />;
};

export default RequiredAuth;
