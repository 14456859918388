import { useTranslation } from 'react-i18next';
import './TicketSearch.scss';

const NoSearchResults = ({ homeTeamId }) => {
  const { t } = useTranslation();

  return (
    <div className="no_search_results">
      <p>{t('No_Search_Results')}</p>
      {homeTeamId && (
        <img
          src={`https://img.mlbstatic.com/mlb-photos/image/upload/w_44,f_png,q_auto/v1/team/${homeTeamId}/logo/cap/light/current`}
          alt={t('EventCard_logo_alt')}
        />
      )}
    </div>
  );
};

export default NoSearchResults;
