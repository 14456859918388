import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AcceptedIcon } from '../../../assets/icons/ForwardAccepted.svg';
import { ReactComponent as PendingIcon } from '../../../assets/icons/ForwardPending.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/ForwardError.svg';
import { ReactComponent as ProcessingIcon } from '../../../assets/icons/ForwardProcessing.svg';
import { useDispatch } from 'react-redux';
import { updateTicketInfoForViewModal } from '../../../features/modal/SharedTicketModals/sharedTicketModalSlice';
import Checkbox from '../../Checkbox/Checkbox';

const SharedTicket = ({
  seat,
  isOnStealthMode,
  recallable,
  cancellable,
  viewable,
  fixable,
  handleTicketBtnClick,
  isManageBtnActive,
  handleCheckClick,
  checkedTicketsByEmail
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPending = cancellable && seat?.status === 'PENDING'; // not yet sent to BO, used for progress bar
  const isInProgress = cancellable && seat?.status === 'IN_PROGRESS'; // waiting for user's acceptance
  const isChecked = useMemo(
    () => checkedTicketsByEmail?.includes(seat?.recipientAddress),
    [checkedTicketsByEmail, seat?.recipientAddress]
  );
  const ticketCountText = seat?.ticketCount > 1 ? t('Tickets') : t('Ticket');

  const icon =
    isManageBtnActive && seat?.status !== 'PENDING' ? (
      <Checkbox handleCheckClick={() => handleCheckClick(seat?.recipientAddress)} isChecked={isChecked} />
    ) : recallable ? (
      <AcceptedIcon data-testid="acceptance_icon" />
    ) : isInProgress ? (
      <PendingIcon data-testid="pending_icon" />
    ) : isPending ? (
      <ProcessingIcon data-testid="processing_icon" />
    ) : viewable || fixable ? (
      <ErrorIcon data-testid="error_icon" />
    ) : null;

  return (
    <div className="seat_cont shared">
      {icon}
      {fixable ? (
        <span className="invalid_email_count">{`${seat?.ticketCount} ${ticketCountText}`}</span>
      ) : seat?.access === 'PARKING' ? (
        <span data-testid="seat_number">{t('Parking')}</span>
      ) : (
        <span data-testid="seat_number">
          {t('Seat')} {seat?.location.seat.value}
        </span>
      )}
      {seat?.email && (
        <article className="ticket_assigned_cont">
          <div className="ticket_info">
            <div className="ticket_name">{seat.name}</div>
            <div className="ticket_email">{seat.email}</div>
          </div>
          {!isManageBtnActive && (
            <button
              id={
                recallable
                  ? 'sharedtix_recall_btn'
                  : cancellable
                  ? 'sharedtix_cancel_btn'
                  : viewable
                  ? 'sharedtix_view_btn'
                  : fixable
                  ? 'sharedtix_fix_btn'
                  : ''
              }
              aria-label={
                recallable
                  ? 'shared ticket recall'
                  : cancellable
                  ? 'shared ticket cancel'
                  : viewable
                  ? 'shared ticket view'
                  : fixable
                  ? 'shared ticket fix'
                  : ''
              }
              aria-haspopup="true"
              tabIndex={isOnStealthMode ? '-1' : '0'}
              type="button"
              onClick={() => {
                recallable && handleTicketBtnClick(seat.ticketId, seat.forwardId, 'recall');
                cancellable && handleTicketBtnClick(seat.ticketId, seat.forwardId, 'cancel');
                viewable && handleTicketBtnClick(seat.ticketId, seat.forwardId, 'view');
                viewable && dispatch(updateTicketInfoForViewModal(seat));
                fixable && handleTicketBtnClick(seat.ticketId, seat.forwardId, 'fix');
              }}
            >
              {recallable && t('Recall')}
              {cancellable && t('Cancel')}
              {viewable && t('View')}
              {fixable && t('Fix')}
            </button>
          )}
        </article>
      )}
    </div>
  );
};

export default SharedTicket;
