import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForwardMutation } from '../TicketList/api/ticketDisplayListApi';
import { getCheckedRecipientIds } from '../../../stores/allocationSlice';
import { removeRecipients } from '../../../features/modal/modalSlice';
import { useNavigate } from 'react-router-dom';
import {
  areNoSeatsChecked,
  getForwardPayload,
  uncheckAllBoxes,
  getForwardAllocationTypeCount
} from '../../../stores/allocationSlice';
import { userEvent } from '../../../utils/analytics';
import './SidePanel.scss';
import { formatDate } from '../../../utils/helpers/formatters';
import ShareToast from './ShareToast';

const SidePanelShare = ({ isOnStealthMode, setIsShareActive, gamePk, homeTeamId, setHasBeenEdited, eventTime }) => {
  const { t } = useTranslation();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [numberSent, setNumberSent] = useState(0);
  const noSeatsChecked = useSelector(areNoSeatsChecked);
  const forwardPayload = useSelector(getForwardPayload);
  const allocationTypeCount = useSelector(getForwardAllocationTypeCount);
  const recipientIds = useSelector(getCheckedRecipientIds);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forward, result] = useForwardMutation();

  const handleShareClick = () => {
    const ticketCount = howManyTicketsInPayload(forwardPayload);
    const toastDuration = ticketCount <= 500 ? 5000 : 2000;
    setIsToastOpen(true);
    setNumberSent(ticketCount);
    forward(forwardPayload).then((results) => {
      if (results && results.data === null) {
        dispatch(removeRecipients(recipientIds));
        dispatch(uncheckAllBoxes());
      }
      setTimeout(() => {
        setIsToastOpen(false);
        setHasBeenEdited(true);
        if (results && results.data === null) {
          setIsShareActive(false);
        }
      }, toastDuration);
    });

    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 1,
          text: 'Send Selected',
          targetUrl: `/events/${gamePk}/available-tickets`
        },
        ticket: {
          quantity: howManyTicketsInPayload(forwardPayload),
          allocation: {
            auto: allocationTypeCount.auto,
            manual: allocationTypeCount.manual
          },
          event: {
            gamePk: gamePk,
            homeTeamId: homeTeamId,
            time: formatDate(eventTime),
            minutesUntil:
              new Date(eventTime).getTime() - new Date().getTime() > 0
                ? Math.floor((new Date(eventTime).getTime() - new Date().getTime()) / 60000)
                : 0
          }
        }
      },
      page: {
        title: 'group tickets',
        app: {
          name: 'bulk ticket management'
        },
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        }
      }
    });
  };

  const howManyTicketsInPayload = (payload) => {
    let counter = 0;
    payload.forEach((recipient) => {
      counter = counter + recipient.ticketIds.length;
    });
    return counter;
  };

  const handleCancelClick = () => {
    setIsShareActive(false);
    dispatch(uncheckAllBoxes());
    navigate(`/events/${gamePk}/available-tickets`);
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'cancel',
          targetUrl: `/events/${gamePk}/available-tickets`
        }
      },
      page: {
        title: 'group tickets',
        app: {
          name: 'bulk ticket management'
        },
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        }
      }
    });
  };

  return (
    <>
      <ShareToast isToastOpen={isToastOpen} result={result} numberSent={numberSent} />
      <button
        id="cancel_share_btn"
        onClick={handleCancelClick}
        tabIndex={isOnStealthMode ? '-1' : '0'}
        aria-label={t('Cancel')}
        type="button"
      >
        {t('Cancel')}
      </button>
      <button
        id="share_tix_btn"
        disabled={noSeatsChecked}
        tabIndex={isOnStealthMode ? '-1' : '0'}
        aria-label={t('Send_Selected')}
        onClick={handleShareClick}
      >
        {t('Send_Selected')}
      </button>
    </>
  );
};

export default SidePanelShare;
