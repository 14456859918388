import { useTranslation } from 'react-i18next';
import Loading from '../Loading/Loading';
import { useGetGitVersionQuery } from '../../TicketDisplay/TicketList/api/ticketDisplayListApi';
import './Status.scss';

const Status = () => {
  const { t } = useTranslation();
  const { isLoading, error, data } = useGetGitVersionQuery();

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <main className="app_status">
          <section className="app_version" data-testid="app_version">
            {t('App_Version')}: {process.env.REACT_APP_VERSION}
          </section>
          {error ? (
            error.data?.message
          ) : (
            <section className="api_version" data-testid="api_version">
              {t('API_Version')}: {data?.git?.commit?.id}
            </section>
          )}
        </main>
      )}
    </>
  );
};

export default Status;
