import { ReactComponent as RefreshIcon } from '../../../assets/icons/Refresh_Icon.svg';
import { useTranslation } from 'react-i18next';

function RefreshButton({ lastUpdated, isTabbable, handleRefreshBtnClick, optionalClass }) {
  const { t } = useTranslation();

  return (
    <button
      id="tix_refresh_btn"
      aria-label="ticket inventory refresh"
      type="button"
      disabled={lastUpdated}
      tabIndex={isTabbable}
      onClick={handleRefreshBtnClick}
    >
      <span>{t('Refresh')}</span>
      <RefreshIcon id="tix_refresh_icon" alt={t('Refresh_Icon')} className={'tix_refresh_icon' + optionalClass} />
    </button>
  );
}

export default RefreshButton;
