import { useEffect, useRef } from 'react';
import { handleModalTab } from '../../../utils/helpers/handlers';
import ModalContainer from '../../../features/modal/ModalContainer';
import ModalOverlay from '../../../features/modal/ModalOverlay';
import WarningIcon from '../../../assets/icons/WarningIcon.svg';
import CloseBtn from '../../../assets/icons/CloseButton.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectRemoveTicketsModalState } from '../../../features/modal/modalSlice';
import { userEvent } from '../../../utils/analytics';

const ManageListModal = ({ gamePk, removeTickets }) => {
  const descRef = useRef();
  const modalRef = useRef();
  const modalOverlayRef = useRef();
  const closeBtnRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isRemoveTicketsModalOpen = useSelector(selectRemoveTicketsModalState);

  useEffect(() => {
    if (isRemoveTicketsModalOpen) {
      userEvent({
        event: 'modal-impression',
        modal: {
          title: 'remove alert',
          option: {
            indexTotal: 1
          }
        },
        page: {
          title: 'group tickets',
          gamePk: gamePk,
          subPage: {
            title: 'available tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        }
      });
    }
  }, [isRemoveTicketsModalOpen, gamePk]);

  useEffect(() => {
    if (closeBtnRef?.current && isRemoveTicketsModalOpen) {
      closeBtnRef.current.focus();
    }
  }, [isRemoveTicketsModalOpen]);

  useEffect(() => {
    document.body.addEventListener('keydown', handleModalTab(modalRef), false);
    return () => {
      document.body.removeEventListener('keydown', handleModalTab(modalRef), false);
    };
  }, []);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        dispatch(closeModal());
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
  }, [dispatch]);

  useEffect(() => {
    const closeOnClickOutsideModal = (e) => {
      if (isRemoveTicketsModalOpen && modalOverlayRef.current === e.target) {
        dispatch(closeModal());
      }
    };
    document.body.addEventListener('click', closeOnClickOutsideModal);
    return () => document.body.removeEventListener('click', closeOnClickOutsideModal);
  }, [isRemoveTicketsModalOpen, dispatch]);

  useEffect(() => {
    if (isRemoveTicketsModalOpen && modalRef?.current && descRef?.current) {
      modalRef.current.setAttribute('aria-labelledby', descRef.current.id);
    }
  }, [isRemoveTicketsModalOpen]);

  useEffect(() => {
    if (isRemoveTicketsModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = null;
    };
  }, [isRemoveTicketsModalOpen]);

  const handleCloseBtnClick = () => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 0,
          text: 'X'
        }
      },
      modal: {
        title: 'remove alert',
        option: {
          indexTotal: 1
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    dispatch(closeModal());
  };

  const handleGoBackBtnClick = () => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 1,
          text: 'no, go back'
        }
      },
      modal: {
        title: 'remove alert',
        option: {
          indexTotal: 1
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    dispatch(closeModal());
  };

  const handleRemoveBtnClick = () => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 2,
          text: 'yes, remove',
          targetUrl: `/events/${gamePk}/available-tickets`
        }
      },
      modal: {
        title: 'remove alert',
        option: {
          indexTotal: 1
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
    removeTickets();
  };

  return (
    <ModalOverlay
      className={'modal_overlay' + (isRemoveTicketsModalOpen ? ' opened' : '')}
      ref={modalOverlayRef}
      aria-hidden={isRemoveTicketsModalOpen ? 'false' : 'true'}
    >
      <ModalContainer
        id="remove_tix_modal"
        className={'remove_tix_modal' + (isRemoveTicketsModalOpen ? ' opened' : '')}
        aria-modal={isRemoveTicketsModalOpen ? 'true' : 'false'}
        aria-hidden={isRemoveTicketsModalOpen ? 'false' : 'true'}
        role="dialog"
        ref={modalRef}
      >
        <button
          id="remove_tix_modal_close_btn"
          className="modal_close"
          aria-label={t('Modal_Close_Btn')}
          type="button"
          ref={closeBtnRef}
          onClick={handleCloseBtnClick}
        >
          <img className="modal_close_icon" src={CloseBtn} alt={t('Modal_Close_Btn')} />
        </button>
        <img className="warning_icon" src={WarningIcon} alt={t('Warning_Icon')} />
        <p id="remove_tix_modal_desc" className="modal_text" ref={descRef}>
          {t('Ticket_Removal_Modal_Desc')}
        </p>
        <div className="remove_tix_modal_footer">
          <button id="remove_tix_modal_go_back_btn" aria-label="go back" type="button" onClick={handleGoBackBtnClick}>
            {t('Recall_modal_cancel')}
          </button>
          <button id="remove_tix_modal_remove_btn" aria-label="remove" type="button" onClick={handleRemoveBtnClick}>
            {t('Modal_Remove_Text')}
          </button>
        </div>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ManageListModal;
