import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from '../../../assets/icons/ValidationErrorIcon.svg';

const ManualAllocationForm = ({ formErrors, handleSubmit, handleInputChange, formData, isManAllocEditModeOn }) => {
  const { t } = useTranslation();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const nameField = useRef();
  const emailField = useRef();
  const { name, email } = formErrors || {};

  useEffect(() => {
    const { name, email } = formData || {};
    if (name === '' && email === '') {
      if (!isBtnDisabled) {
        setIsBtnDisabled(true);
      }
    } else {
      setIsBtnDisabled(false);
    }
  }, [formData, isBtnDisabled]);

  useEffect(() => {
    if (name && nameField?.current) {
      nameField.current.focus();
    } else if (email && emailField?.current) {
      emailField.current.focus();
    }
  }, [name, email]);

  return (
    <form className="man_alloc_form">
      <div className="input_cont">
        <label className={'man_alloc_modal_label' + (name ? ' error' : '')} htmlFor="man_alloc_person_name">
          {t('Name')}
        </label>
        <div className="input_wrapper">
          <input
            id="man_alloc_person_name"
            className={'man_alloc_modal_input' + (name ? ' error' : '')}
            name="name"
            value={formData?.name}
            aria-label={t('Name')}
            aria-required={true}
            onChange={handleInputChange}
            ref={nameField}
            aria-invalid={name ? true : false}
            aria-describedby="man_alloc_name_error"
          />
          {name && (
            <span className="validationError">
              <WarningIcon />
            </span>
          )}
        </div>
        {name && (
          <span id="man_alloc_name_error" className="form_error_message" role="alert">
            {name}
          </span>
        )}
      </div>
      <div className="input_cont">
        <label className={'man_alloc_modal_label' + (email ? ' error' : '')} htmlFor="man_alloc_person_email">
          {t('Email')}
        </label>
        <div className="input_wrapper">
          <input
            id="man_alloc_person_email"
            className={'man_alloc_modal_input' + (email ? ' error' : '')}
            name="email"
            value={formData?.email}
            aria-label={t('Email')}
            aria-required={true}
            onChange={handleInputChange}
            ref={emailField}
            aria-invalid={email ? true : false}
            aria-describedby="man_alloc_email_error"
          />
          {email && (
            <span className="validationError">
              <WarningIcon />
            </span>
          )}
        </div>
        {email && (
          <span id="man_alloc_email_error" className="form_error_message" role="alert">
            {email}
          </span>
        )}
      </div>
      <div className="add_modal_footer form_footer">
        <button
          className="modal_footer_left_btn"
          id={isManAllocEditModeOn ? 'man_alloc_remove_recipient_btn' : 'man_alloc_cancel_btn'}
          onClick={(e) => handleSubmit(e, true)}
          aria-label={isManAllocEditModeOn ? t('Remove_Person_Btn') : t('Manual_Allocation_Modal_Cancel')}
        >
          {isManAllocEditModeOn ? t('Remove_Person_Btn') : t('Modal_Cancel_Btn')}
        </button>
        <button
          className="modal_footer_right_btn"
          disabled={isBtnDisabled}
          id={isManAllocEditModeOn ? 'man_alloc_save_changes_btn' : 'man_alloc_save_btn'}
          onClick={(e) => handleSubmit(e, false)}
          aria-label={isManAllocEditModeOn ? t('Save_Changes_Btn') : t('Manual_Allocation_Modal_Save')}
        >
          {isManAllocEditModeOn ? t('Save_Changes_Btn') : t('Save_Assigned_Ticket')}
        </button>
      </div>
    </form>
  );
};

export default ManualAllocationForm;
