import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Logout.scss';
import { selectLogout } from './logoutSlice';
import { useOktaAuth } from '@okta/okta-react';
import { userEvent } from '../../utils/analytics';
import { Link } from 'react-router-dom';

const Logout = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const logoutOpened = useSelector(selectLogout);
  const { t } = useTranslation();
  const userEmail = authState?.idToken?.claims?.email;

  useEffect(() => {
    if (logoutOpened) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = null;
    };
  }, [logoutOpened]);

  const handleLogoutClick = () => {
    userEvent({
      event: 'link-click',
      action: {
        section: {
          customName: 'hamburger menu'
        },
        element: {
          index: 0,
          text: t('Logout'),
          targetUrl: 'Okta login page'
        }
      }
    });
    oktaAuth.signOut();
  };

  return (
    <div className={'navbar_menu logout' + (logoutOpened ? ' logout_opened' : '')} id="logoutContainer">
      <div className="menu_items logout_dialogue">
        <div className="events_page_btn_container">
          <Link id="events_page_link" to="/events">
            {t('Events_Page')}
          </Link>
        </div>
        <hr className="navbar_menu_line" />
        <div className="logout_btn_container">
          <button
            aria-label={t('Logout')}
            aria-hidden={logoutOpened ? false : true}
            className="logout_btn"
            id="logout-button"
            type="button"
            onClick={handleLogoutClick}
          >
            {t('Logout') + ' ' + userEmail}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Logout;
