import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLogout } from '../../features/logout/logoutSlice';
import Modal from '../../features/modal/Modal';
import InvalidSaveModal from './InvalidSaveModal/InvalidSaveModal';
import {
  selectEditMode,
  selectPeopleModalOpen,
  selectPersonModalOpen,
  selectRecipients,
  selectManualAllocationModalOpen,
  selectShouldTicketsRefresh,
  selectShouldEventsRefresh,
  selectRemoveTicketsModalState,
  removeAllRecipients
} from '../../features/modal/modalSlice';
import EventHeader from '../EventDisplay/EventCard/EventHeader/EventHeader';
import {
  useGetEventsQuery,
  useGetAccountsQuery,
  useBulkRecallMutation,
  useGetForwardProgressQuery,
  ticketsApi
} from './TicketList/api/ticketDisplayListApi';
import SidePanel from './SidePanel/SidePanel';
import TabList from './TabList/TabList';
import TicketList from './TicketList/TicketList';
import SharedTicketList from './TicketList/SharedTicketList';
import { createBins, sortBinsAlphabetically } from '../../utils/autoAllocation';
import { useGetTicketsQuery, useGetSaveQuery } from './TicketList/api/ticketDisplayListApi';
import {
  openModal,
  selectBulkRecallToastOpen,
  selectCancelModalOpen,
  selectCurrentForwardId,
  selectInvalidEmailModalOpen,
  selectRecallModalOpen,
  selectViewModalOpen
} from '../../features/modal/SharedTicketModals/sharedTicketModalSlice';
import './TicketDisplay.scss';
import SharedTicketModal from '../../features/modal/SharedTicketModals/SharedTicketModal';
import TicketSearch from './TicketSearch/TicketSearch';
import { selectSearchTerm, clearSearch } from '../../stores/searchSlice';
import BulkRecallPendingModal from '../../features/modal/BulkRecallPendingModal.js';
import { userEvent } from '../../utils/analytics';

const TicketDisplay = ({ currentTab }) => {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState();
  const [isShareActive, setIsShareActive] = useState(false);
  const { gamePk } = useParams();
  const isSpreadsheetModalOpen = useSelector(selectPeopleModalOpen);
  const isPersonModalOpen = useSelector(selectPersonModalOpen);
  const activeTab = currentTab;
  const isEditModeOn = useSelector(selectEditMode);
  const isLogoutOpen = useSelector(selectLogout);
  const isRecallModalOpen = useSelector(selectRecallModalOpen);
  const isCancelModalOpen = useSelector(selectCancelModalOpen);
  const isViewModalOpen = useSelector(selectViewModalOpen);
  const isRemoveTicketsModalOpen = useSelector(selectRemoveTicketsModalState);
  const isInvalidEmailModalOpen = useSelector(selectInvalidEmailModalOpen);
  const searchTerm = useSelector(selectSearchTerm);
  const isMemberModalOpen = isSpreadsheetModalOpen || isPersonModalOpen || isEditModeOn;
  const isSharedTicketModalOpen = isRecallModalOpen || isCancelModalOpen || isViewModalOpen || isInvalidEmailModalOpen;
  const isManualAllocModalOpen = useSelector(selectManualAllocationModalOpen);
  const isBulkRecallToastOpen = useSelector(selectBulkRecallToastOpen);
  const isOnStealthMode =
    isLogoutOpen ||
    isMemberModalOpen ||
    isSharedTicketModalOpen ||
    isManualAllocModalOpen ||
    isRemoveTicketsModalOpen ||
    isBulkRecallToastOpen;
  const shouldTicketsRefresh = useSelector(selectShouldTicketsRefresh);
  const shouldEventsRefresh = useSelector(selectShouldEventsRefresh);
  const eventQuery = useGetEventsQuery({ refresh: shouldEventsRefresh });
  const [acceptedForwards, setAcceptedForwards] = useState([]);
  const [failingForwards, setFailingForwards] = useState([]);
  const [pendingForwards, setPendingForwards] = useState([]);
  const [inProgressForwards, setInProgressForwards] = useState([]);
  const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);
  const { data, isLoading, error, refetch } = useGetTicketsQuery(
    {
      gamePk: gamePk,
      homeTeamId: eventData?.homeTeamId,
      refresh: shouldTicketsRefresh || isRefreshBtnClicked
    },
    { pollingInterval: pendingForwards?.length === 0 ? undefined : 60000, skip: !gamePk || !eventData?.homeTeamId }
  );
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const saveData = useGetSaveQuery({ gamePk }); // moving it here fixed a bug I'd found, see GROUP-171 for more info
  const accounts = useGetAccountsQuery();
  const recipients = useSelector(selectRecipients);
  const forwardingError = failingForwards[0]?.length > 0;
  const unassignableTickets = data?.availableTickets?.filter((ticket) => ticket.forwardable === false);
  const assignableTickets = useMemo(
    () => data?.availableTickets?.filter((ticket) => ticket.forwardable === true),
    [data?.availableTickets]
  );
  const unassignableTicketsBySection = useMemo(
    () => unassignableTickets && createBins(unassignableTickets, sortBinsAlphabetically),
    [unassignableTickets]
  );
  const nonVenueTickets = useMemo(
    () => data?.availableTickets?.filter((ticket) => ticket.access !== 'VENUE' && ticket.forwardable === true),
    [data?.availableTickets]
  );
  const nonVenueTicketsAmount = nonVenueTickets?.length ?? 0;
  const unassignableTicketAmount = unassignableTickets?.length ?? 0;
  const nonforwardableTicketsOnly = unassignableTicketAmount > 0 && assignableTickets?.length === 0;
  const areAllSeatsAssigned = useMemo(() => {
    return (
      data?.availableTickets?.length ===
      recipients?.reduce((acc, cur) => acc + Number(cur.seatCount), 0) +
        nonVenueTicketsAmount +
        unassignableTicketAmount
    );
  }, [data?.availableTickets?.length, recipients, nonVenueTicketsAmount, unassignableTicketAmount]);
  const modalBtnDisabled = data?.availableTickets?.length === 0 || areAllSeatsAssigned;
  const [isManageBtnActive, setIsManageBtnActive] = useState(false);
  const availableTicketsCount = data?.availableTickets?.length ?? 0;
  const forwardedTickets = useMemo(() => data?.sharedTickets, [data?.sharedTickets]);
  const sharedTicketsCount = forwardedTickets?.length ?? 0;
  const [isManageBtnOnForwardsActive, setIsManageBtnOnForwardsActive] = useState(false);
  const [isBulkRecallActive, setIsBulkRecallActive] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]); // id mapping for bulk ticket recall, e.g., [{forwardId: a, ticketId:1, }]
  const [bulkRecall] = useBulkRecallMutation();
  const progress = useGetForwardProgressQuery({ gamePk }, { skip: !gamePk });
  const [invalidEmailMapping, setInvalidEmailMapping] = useState();
  const [invalidEmailInfo, setInvalidEmailInfo] = useState();
  const currentForwardId = useSelector(selectCurrentForwardId);
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);
  const [isInvalidSaveModalOpen, setIsInvalidSaveModalOpen] = useState(false);

  useEffect(() => {
    if (progress?.data?.isRecalling) {
      dispatch(openModal('bulk_recall'));
    }
  }, [progress?.data?.isRecalling, dispatch]);

  useEffect(() => {
    if (activeTab === 'tixTab2' && isManageBtnActive) {
      setIsManageBtnActive(false);
    }
  }, [activeTab, isManageBtnActive]); // per Michael's feedback

  useEffect(() => {
    if (activeTab === 'tixTab1' && isManageBtnOnForwardsActive) {
      setIsManageBtnOnForwardsActive(false);
    }
  }, [activeTab, isManageBtnOnForwardsActive]);

  useEffect(() => {
    if (invalidEmailMapping && isInvalidEmailModalOpen && currentForwardId) {
      setInvalidEmailInfo(invalidEmailMapping[currentForwardId]);
    }
  }, [currentForwardId, invalidEmailInfo, invalidEmailMapping, isInvalidEmailModalOpen]);

  useEffect(() => {
    if (recipients.length && data?.availableTickets.length) {
      const tickets = [...data.availableTickets];
      const allocatableTicketCount = availableTicketsCount - nonVenueTicketsAmount - unassignableTicketAmount;
      let ticketsNeeded = 0;
      recipients.forEach((recipient) => {
        if (recipient.ticketId) {
          //Manually allocated seat
          const isValidManualTicket = tickets.some((ticket) => recipient.ticketId === ticket.ticketId);
          if (!isValidManualTicket) {
            clearRecipients();
            return;
          }
          tickets.forEach((ticket) => {
            if (recipient.ticketId === ticket.ticketId && ticket.access === 'VENUE') {
              ticketsNeeded++;
            }
          });
        } else {
          ticketsNeeded += Number(recipient.seatCount);
        }
      });
      if (ticketsNeeded > allocatableTicketCount) {
        clearRecipients();
      }
    }
    function clearRecipients() {
      setHasBeenEdited(true);
      dispatch(removeAllRecipients());
      setIsInvalidSaveModalOpen(true);
    }
  }, [recipients, data, availableTicketsCount, nonVenueTicketsAmount, unassignableTicketAmount, dispatch]);

  const modalProps = {
    isModalOpen: isMemberModalOpen,
    isPeopleModalOpen: isSpreadsheetModalOpen,
    isPersonModalOpen: isPersonModalOpen,
    gamePk: gamePk,
    homeTeamId: eventData?.homeTeamId,
    setHasBeenEdited: setHasBeenEdited,
    unassignableTicketAmount: unassignableTicketAmount,
    nonVenueTicketsAmount: nonVenueTicketsAmount,
    data: data
  };

  const sharedModalProps = {
    gamePk: gamePk,
    homeTeamId: eventData?.homeTeamId,
    isRecallModalOpen: isRecallModalOpen,
    isCancelModalOpen: isCancelModalOpen,
    isViewModalOpen: isViewModalOpen,
    isInvalidEmailModalOpen: isInvalidEmailModalOpen,
    isSharedTicketModalOpen: isSharedTicketModalOpen,
    isBulkRecallActive: isBulkRecallActive,
    setManageBtnActive: setIsManageBtnOnForwardsActive,
    checkedRecallIds: checkedIds,
    setHasBeenEdited: setHasBeenEdited,
    ticketsRefetch: refetch,
    bulkRecall: bulkRecall,
    bulkRecallProgressRefetch: progress?.refetch,
    invalidEmailInfo: invalidEmailInfo
  };

  useEffect(() => {
    if (eventQuery && eventQuery.data) {
      eventQuery.data.forEach((element) => {
        if (element.gamePk === gamePk) {
          setEventData(element);
        }
      });
    }
  }, [eventQuery, gamePk]);

  useEffect(() => {
    //Clear search terms when someone adds/edits a group
    dispatch(clearSearch());
  }, [recipients, data?.sharedTickets, dispatch]);

  useEffect(() => {
    isRefreshBtnClicked && dispatch(ticketsApi.util.invalidateTags([{ type: 'Tickets', id: gamePk }]));
  }, [isRefreshBtnClicked, dispatch, gamePk]);

  const sharedProps = {
    isLoading: isLoading,
    error: error,
    ticketData: data,
    gamePk: gamePk,
    homeTeamId: eventData?.homeTeamId,
    isOnStealthMode: isOnStealthMode
  };

  const filterForwards = useCallback(
    (state, setter) => {
      let filteredData = data?.sharedTickets?.filter((ticket) => ticket.status === state);
      if (searchTerm !== '') {
        filteredData = filteredData.filter((ticket) => {
          return (
            ticket.recipientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            ticket.recipientAddress?.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
      }
      if (filteredData) {
        state === 'ERROR' ? setter([filteredData]) : setter(createBins(filteredData, sortBinsAlphabetically)); // only create bins for statuses other than error at this point
      }
    },
    [data?.sharedTickets, searchTerm]
  );

  useEffect(() => {
    if (data && data.sharedTickets.length > 0) {
      filterForwards('IN_PROGRESS', setInProgressForwards);
      filterForwards('PENDING', setPendingForwards);
      filterForwards('ACCEPTED', setAcceptedForwards);
      filterForwards('ERROR', setFailingForwards);
    }
  }, [data, filterForwards]);

  useEffect(() => {
    if (data) {
      userEvent({
        event: 'page-view',
        page: {
          content: {
            ticket: {
              count: {
                availableTicketCount: availableTicketsCount,
                sharedTicketCount: sharedTicketsCount,
                nonVenueTicketCount: nonVenueTicketsAmount,
                nonForwardableTicketCount: unassignableTicketAmount,
                totalTicketCount: availableTicketsCount + sharedTicketsCount
              }
            }
          },
          title: 'group tickets',
          type: 'ticket display',
          gamePk: gamePk,
          subPage: {
            title: activeTab === 'tixTab1' ? 'available tickets' : 'shared tickets'
          },
          app: {
            name: 'bulk ticket management'
          }
        }
      });
    }
  }, [
    gamePk,
    data,
    activeTab,
    availableTicketsCount,
    sharedTicketsCount,
    nonVenueTicketsAmount,
    unassignableTicketAmount
  ]);

  return (
    <>
      {(isBulkRecallToastOpen || progress?.data?.isRecalling) && (
        <BulkRecallPendingModal gamePk={gamePk} isBulkRecallToastOpen={isBulkRecallToastOpen} />
      )}
      {data?.availableTickets?.length > 0 && <Modal modalProps={modalProps} />}
      <SharedTicketModal modalProps={sharedModalProps} />
      <main className="ticket_display_main">
        <section className="tickets_section">
          <div className="tickets_section_header">
            <div className="event_header_cont">
              {eventData && eventData.gamePk ? (
                <EventHeader
                  logoutOpened={isLogoutOpen}
                  eventData={eventData}
                  availableTicketsCount={availableTicketsCount}
                  sharedTicketsCount={sharedTicketsCount}
                />
              ) : null}
            </div>
            <div className="tablist_cont">
              <TabList
                activeTab={activeTab}
                isOnStealthMode={isOnStealthMode}
                gamePk={gamePk}
                forwardingError={forwardingError}
                isRefreshBtnClicked={isRefreshBtnClicked}
                setIsRefreshBtnClicked={setIsRefreshBtnClicked}
              />
            </div>
          </div>
          <div>
            <TicketSearch gamePk={gamePk} isOnStealthMode={isOnStealthMode} />
          </div>
          <div className="tickets_section_body">
            {eventData &&
              eventData.gamePk &&
              (activeTab === 'tixTab1' ? (
                <TicketList
                  ownProps={sharedProps}
                  setIsShareActive={setIsShareActive}
                  isShareActive={isShareActive}
                  hasBeenEdited={hasBeenEdited}
                  setHasBeenEdited={setHasBeenEdited}
                  saveData={saveData}
                  unassignableTickets={unassignableTicketsBySection}
                  assignableTickets={assignableTickets}
                  accounts={accounts}
                  isManageBtnActive={isManageBtnActive}
                  setIsManageBtnActive={setIsManageBtnActive}
                  nonforwardableTicketsOnly={nonforwardableTicketsOnly}
                  shouldDisplayLoader={shouldDisplayLoader}
                  setShouldDisplayLoader={setShouldDisplayLoader}
                />
              ) : activeTab === 'tixTab2' ? (
                <SharedTicketList
                  ownProps={sharedProps}
                  pendingForwards={pendingForwards}
                  acceptedForwards={acceptedForwards}
                  failingForwards={failingForwards}
                  inProgressForwards={inProgressForwards}
                  homeTeamId={eventData?.homeTeamId}
                  sharedTickets={data?.sharedTickets}
                  isManageBtnActive={isManageBtnOnForwardsActive}
                  setIsManageBtnActive={setIsManageBtnOnForwardsActive}
                  forwardedTickets={forwardedTickets}
                  setIsBulkRecallActive={setIsBulkRecallActive}
                  setCheckedIds={setCheckedIds}
                  invalidEmailMapping={invalidEmailMapping}
                  setInvalidEmailMapping={setInvalidEmailMapping}
                  setShouldDisplayLoader={setShouldDisplayLoader}
                />
              ) : null)}
          </div>
        </section>
        <SidePanel
          isOnStealthMode={isOnStealthMode}
          gamePk={gamePk}
          homeTeamId={eventData?.homeTeamId}
          isShareActive={isShareActive}
          setIsShareActive={setIsShareActive}
          setHasBeenEdited={setHasBeenEdited}
          modalBtnDisabled={modalBtnDisabled}
          isManageBtnActive={isManageBtnActive}
          isManageBtnOnForwardsActive={isManageBtnOnForwardsActive}
          isBulkRecallActive={isBulkRecallActive}
          hasBeenEdited={hasBeenEdited}
          eventTime={eventData?.dateTime}
          setShouldDisplayLoader={setShouldDisplayLoader}
        />
      </main>
      {isInvalidSaveModalOpen ? (
        <InvalidSaveModal setIsInvalidSaveModalOpen={setIsInvalidSaveModalOpen} gamePk={gamePk} />
      ) : null}
    </>
  );
};

export default TicketDisplay;
