import { useTranslation } from 'react-i18next';
import './ManageListButton.scss';

const ManageListButton = ({ manageable, handleManageListBtnClick, isOnStealthMode, isIdle, isInline }) => {
  const { t } = useTranslation();

  return (
    <button
      id="manage_list_btn"
      aria-label="manage list"
      className={'manage_list_btn' + (isIdle ? ' idle' : isInline ? ' inline' : '')}
      disabled={!manageable}
      onClick={handleManageListBtnClick}
      tabIndex={isOnStealthMode ? '-1' : '0'}
    >
      {t('Manage_List')}
    </button>
  );
};

export default ManageListButton;
