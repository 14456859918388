import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CloseBtn from '../../assets/icons/CloseButton.svg';
import { selectEditMode, selectPersonTicketExceedingAmount, selectManualAllocationModalOpen } from './modalSlice';
import { selectManuallyAllocatableTicketCount } from '../../stores/allocationSlice';

const ModalHeader = (props) => {
  const { t } = useTranslation();
  const {
    modalHeader,
    modalDesc,
    handleModalClose,
    ticketAmount,
    ticketRemainingAmount,
    handleManAllocModalClose,
    isManAllocEditModeOn,
    isManualAllocationOpen,
    setIsManualAllocationOpen,
    isPeopleModalOpen,
    ticketNumber,
    manualTicketRemainingAmount
  } = props.ownProps;
  const isEditModeOn = useSelector(selectEditMode);
  const ticketExceedingAmount = useSelector(selectPersonTicketExceedingAmount);
  const isManualAllocOn = useSelector(selectManualAllocationModalOpen);
  const manualTicketCount = useSelector(selectManuallyAllocatableTicketCount);

  const handleAutoClick = () => {
    if (isManualAllocationOpen) {
      hideSpreadsheetTooltip();
      setIsManualAllocationOpen(false);
    }
  };

  const handleManualClick = () => {
    if (!isManualAllocationOpen) {
      hideSpreadsheetTooltip();
      setIsManualAllocationOpen(true);
    }
  };

  const hideSpreadsheetTooltip = () => {
    //Hides the tooltip if switching between auto and manual allocation modals
    const tooltip = document.querySelector('.hot-tooltip');
    if (tooltip) {
      tooltip.style.visibility = 'hidden';
    }
  };

  return (
    <>
      <div className="add_modal_header">
        <h4 id="modal_title">{modalHeader}</h4>
        <button
          id="modal_close_btn"
          aria-label={t('Modal_Close_Btn')}
          className="modal_close"
          type="button"
          onClick={isManualAllocOn ? handleManAllocModalClose : (e) => handleModalClose(e, false)}
        >
          <img className="modal_close_icon" src={CloseBtn} alt={t('Modal_Close_Btn')} />
        </button>
      </div>
      {!isManAllocEditModeOn && (
        <p id="modal_desc" className={isManualAllocOn ? 'man_alloc_modal_desc' : ''}>
          {modalDesc}
        </p>
      )}
      {!isPeopleModalOpen &&
        !isManualAllocOn &&
        !isEditModeOn &&
        (ticketRemainingAmount < 0 ? (
          <small id="tix_exceeding_error" className="remaining_tix_desc error" aria-live="polite" role="alert">
            {t('You_have_entered')} {Number(ticketExceedingAmount)} {t('tickets_over_your_inventory')}
          </small>
        ) : (
          <small id="remaining_tix_desc" className="remaining_tix_desc">
            {t('You_have')} {ticketRemainingAmount} {t('of')} {ticketAmount} {t('tickets_remaining')}{' '}
            {t('For_auto_assignment')}
          </small>
        ))}
      {isPeopleModalOpen && !isManualAllocOn && !isEditModeOn ? (
        <div className="modal-switcher">
          <button
            className={!isManualAllocationOpen ? 'modal-selection active' : 'modal-selection'}
            onClick={handleAutoClick}
            id="switcher-auto"
            aria-label={t('Switcher_Auto')}
          >
            {t('Auto_Allocate')}
          </button>
          <button
            className={isManualAllocationOpen ? 'modal-selection active' : 'modal-selection'}
            onClick={handleManualClick}
            id="switcher-manual"
            aria-label={t('Switcher_Manual')}
          >
            {t('Manually_Allocate')}
          </button>
        </div>
      ) : null}
      {isPeopleModalOpen && !isManualAllocationOpen && (
        <div className={`ticket-counts ${ticketRemainingAmount < 0 && 'ticket-counts-error'}`}>
          {ticketNumber - ticketRemainingAmount} of {ticketNumber}
        </div>
      )}
      {isPeopleModalOpen && isManualAllocationOpen && (
        <div className="ticket-counts">
          {manualTicketRemainingAmount} of {manualTicketCount}
        </div>
      )}
    </>
  );
};

export default ModalHeader;
