const appConfig = {
  dev: {
    groupTicketingService: 'https://qa-grouptix-service.mlb.com',
    oktaIssuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
    oktaClientId: '0oa1i7of0qaHHs0Xf0h8',
    oktaRedirectUri: 'http://localhost:3000/login/callback',
    analyticsUrl: 'https://assets.adobedtm.com/267c22ecc15c/1ca761836546/launch-a6def63dd0b7-development.min.js',
    profileServiceUrl: 'https://profile-qa.mlb.com'
  },
  qa: {
    groupTicketingService: 'https://qa-grouptix-service.mlb.com',
    oktaIssuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
    oktaClientId: '0oa1i7of0qaHHs0Xf0h8',
    oktaRedirectUri: 'https://qa-grouptix-ui.mlb.com/login/callback',
    analyticsUrl: 'https://assets.adobedtm.com/267c22ecc15c/1ca761836546/launch-a6def63dd0b7-development.min.js',
    profileServiceUrl: 'https://profile-qa.mlb.com'
  },
  uat: {
    groupTicketingService: 'https://uat-grouptix-service.mlb.com/',
    oktaIssuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
    oktaClientId: '0oa1i7of0qaHHs0Xf0h8',
    oktaRedirectUri: 'https://uat-grouptix-ui.mlb.com/login/callback',
    analyticsUrl: 'https://assets.adobedtm.com/267c22ecc15c/1ca761836546/launch-d00478ed8542-staging.min.js',
    profileServiceUrl: 'https://profile.mlb.com'
  },
  prod: {
    groupTicketingService: 'https://grouptix-service.mlb.com/',
    oktaIssuer: 'https://ids.mlb.com/oauth2/aus1m088yK07noBfh356',
    oktaClientId: '0oap80v1wsgW5XZUm357',
    oktaRedirectUri: 'https://group-tickets.mlb.com/login/callback',
    analyticsUrl: 'https://assets.adobedtm.com/267c22ecc15c/1ca761836546/launch-3c0da2ce339c.min.js',
    profileServiceUrl: 'https://profile.mlb.com'
  }
};

const config = appConfig[process.env.REACT_APP_ENVIRONMENT]
  ? appConfig[process.env.REACT_APP_ENVIRONMENT]
  : appConfig.dev;

const environmentConfig = {
  callbackRoute: '/login/callback',
  ...config
  //add config that belongs to all environments here
};

export default environmentConfig;
