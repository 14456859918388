import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeModal,
  selectTicketAmount,
  updateTicketAmount,
  selectRecipients,
  selectEditMode,
  updateTicketExceedingAmount
} from './modalSlice';
import { useTranslation } from 'react-i18next';
import { handleModalTab } from '../../utils/helpers/handlers';
import ModalOverlay from './ModalOverlay';
import ModalContainer from './ModalContainer';
import ModalHeader from './ModalHeader';
import ManualPeopleModal from './ManualPeopleModal';
import PeopleModalMain from './PeopleModalMain';
import PersonModalMain from './PersonModalMain';
import { userEvent } from '../../utils/analytics';
import './Modal.scss';

const Modal = ({ modalProps }) => {
  const {
    isModalOpen,
    isPeopleModalOpen,
    isPersonModalOpen,
    gamePk,
    setHasBeenEdited,
    unassignableTicketAmount,
    nonVenueTicketsAmount,
    data
  } = modalProps;
  const { t } = useTranslation();
  const modalOverlayRef = useRef();
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [ticketRemainingAmount, setTicketRemainingAmount] = useState(data?.availableTickets?.length || 0);
  const [manualTicketRemainingAmount, setManualTicketRemainingAmount] = useState(0);
  const [isManualAllocationOpen, setIsManualAllocationOpen] = useState(false);
  const ticketAmount = data?.availableTickets?.length || 0;
  const updatedTicketAmount = useSelector(selectTicketAmount);
  const recipients = useSelector(selectRecipients);
  const isEditModeOn = useSelector(selectEditMode);
  const isModalOn = isEditModeOn || isModalOpen;
  const personModalOn = isPersonModalOpen || isEditModeOn;
  const autoAllocatableTicketsAmount = updatedTicketAmount - unassignableTicketAmount - nonVenueTicketsAmount;
  const ticketNumber = autoAllocatableTicketsAmount;

  const handleTicketCountChange = useCallback((newVal) => {
    setTicketRemainingAmount(newVal);
  }, []);

  useEffect(() => {
    let seatsAssignedAmount = 0;
    recipients.forEach((recipient) => (seatsAssignedAmount += Number(recipient.seatCount)));
    ticketAmount && dispatch(updateTicketAmount(ticketAmount - seatsAssignedAmount));
  }, [dispatch, recipients, ticketAmount]);

  useEffect(() => {
    if (isModalOn) {
      setTicketRemainingAmount(autoAllocatableTicketsAmount);
    }
  }, [isModalOn, autoAllocatableTicketsAmount, isManualAllocationOpen]);

  useEffect(() => {
    if (isModalOn) {
      setManualTicketRemainingAmount(0);
    }
  }, [isModalOn, updatedTicketAmount, unassignableTicketAmount, isManualAllocationOpen]);

  useEffect(() => {
    if (ticketRemainingAmount < 0) {
      dispatch(updateTicketExceedingAmount(-1 * ticketRemainingAmount));
    }
  }, [dispatch, ticketRemainingAmount]);

  const handleModalClose = useCallback(
    (e, cancelBtnClicked) => {
      if (isModalOn && gamePk) {
        userEvent({
          event: 'modal-click',
          action: {
            element: {
              index: cancelBtnClicked && isPeopleModalOpen ? 1 : 0,
              text: cancelBtnClicked && isPeopleModalOpen ? 'cancel' : 'X',
              targetUrl: isPeopleModalOpen ? '/available-tickets' : `/events/${gamePk}/available-tickets`
            }
          },
          modal: {
            option: {
              indexTotal: isPeopleModalOpen ? 2 : 1
            },
            title: isEditModeOn
              ? 'edit person'
              : isPersonModalOpen
              ? 'add person'
              : isPeopleModalOpen
              ? 'add multiple people'
              : ''
          },
          page: {
            app: {
              name: 'bulk ticket management'
            },
            gamePk: gamePk,
            subPage: isPeopleModalOpen
              ? ''
              : {
                  title: 'available tickets'
                },
            title: 'group tickets',
            type: isPeopleModalOpen ? '' : 'ticket display'
          }
        });
      }
      setTicketRemainingAmount(updatedTicketAmount);
      dispatch(closeModal());
    },
    [dispatch, updatedTicketAmount, isPersonModalOpen, gamePk, isEditModeOn, isModalOn, isPeopleModalOpen]
  );

  const updateTicketRemainingAmount = useCallback((val) => {
    setTicketRemainingAmount(val);
  }, []);

  const headerProps = {
    modalHeader: isPeopleModalOpen
      ? t('Add_People_Text')
      : isEditModeOn
      ? 'Edit Person'
      : isPersonModalOpen
      ? t('Add_Person')
      : null,
    modalDesc: isPeopleModalOpen
      ? t('Add_People_Desc')
      : isEditModeOn
      ? t('Edit_Person_Modal_Desc')
      : isPersonModalOpen
      ? t('Add_Person_Desc')
      : null,
    ticketAmount: isModalOn && ticketAmount,
    ticketRemainingAmount: isModalOn && ticketRemainingAmount,
    handleModalClose: handleModalClose,
    isManualAllocationOpen: isManualAllocationOpen,
    setIsManualAllocationOpen: setIsManualAllocationOpen,
    isPeopleModalOpen: isPeopleModalOpen,
    ticketNumber: ticketNumber,
    manualTicketRemainingAmount: manualTicketRemainingAmount
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleModalTab(modalRef), false);
    return () => {
      document.body.removeEventListener('keydown', handleModalTab(modalRef), false);
    };
  }, []);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        handleModalClose(e, false);
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
  }, [handleModalClose]);

  useEffect(() => {
    const closeOnClickOutsideModal = (e) => {
      if (isModalOpen && modalOverlayRef.current === e.target) {
        handleModalClose(e, false);
      }
    };
    document.body.addEventListener('click', closeOnClickOutsideModal);
    return () => document.body.removeEventListener('click', closeOnClickOutsideModal);
  }, [isModalOpen, handleModalClose]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = null;
    };
  }, [isModalOpen]);

  return (
    <>
      <ModalOverlay
        ref={modalOverlayRef}
        className={'modal_overlay' + (isModalOpen ? ' opened' : '')}
        aria-hidden={isModalOpen ? 'false' : 'true'}
      >
        <ModalContainer
          className={'add_modal_dialog' + (isModalOpen ? ' opened' : '') + (personModalOn ? ' person' : '')}
          ref={modalRef}
          aria-labelledby="modal_desc remaining_tix_desc"
          id={isPeopleModalOpen ? 'add_people_modal' : isPersonModalOpen ? 'add_person_modal' : null}
          aria-modal={isModalOpen ? 'true' : 'false'}
          aria-hidden={isModalOpen ? 'false' : 'true'}
          role="dialog"
        >
          {isModalOn && <ModalHeader ownProps={headerProps} />}
          {isPeopleModalOpen ? (
            isManualAllocationOpen ? (
              <ManualPeopleModal
                setManualTicketRemainingAmount={setManualTicketRemainingAmount}
                handleModalClose={handleModalClose}
                ticketRemainingAmount={ticketRemainingAmount}
                gamePk={gamePk}
                setHasBeenEdited={setHasBeenEdited}
                modalRef={modalRef}
              />
            ) : (
              <PeopleModalMain
                handleTicketCountChange={handleTicketCountChange}
                handleModalClose={handleModalClose}
                ticketRemainingAmount={ticketRemainingAmount}
                gamePk={gamePk}
                setHasBeenEdited={setHasBeenEdited}
                modalRef={modalRef}
              />
            )
          ) : isPersonModalOpen ? (
            <PersonModalMain
              ticketRemainingAmount={ticketRemainingAmount}
              updateTicketRemainingAmount={updateTicketRemainingAmount}
              gamePk={gamePk}
              isPersonModalOpen={isPersonModalOpen}
              recipients={recipients}
              setHasBeenEdited={setHasBeenEdited}
            />
          ) : null}
          {isPeopleModalOpen && <div id="tooltip" className="hot-tooltip" role="alert" />}
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};

export default Modal;
