import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Shared/Loading/Loading';
import {
  ticketsApi,
  useGetForwardProgressQuery
} from '../../components/TicketDisplay/TicketList/api/ticketDisplayListApi';
import { closeModal } from './SharedTicketModals/sharedTicketModalSlice';

const BulkRecallPendingModal = ({ gamePk, isBulkRecallToastOpen }) => {
  const [shouldDisplayBulkRecallToast, setShouldDisplayBulkRecallToast] = useState(true);
  const { data, isError, isLoading, isSuccess } = useGetForwardProgressQuery(
    { gamePk },
    { pollingInterval: isBulkRecallToastOpen || shouldDisplayBulkRecallToast ? 3000 : 0, skip: !gamePk }
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.isRecalling === false) {
      const timer = setTimeout(() => {
        dispatch(ticketsApi.util.invalidateTags(['Events', 'Tickets']));
        dispatch(closeModal('bulk_recall'));
        setShouldDisplayBulkRecallToast(false);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, data?.isRecalling, setShouldDisplayBulkRecallToast]);

  return (
    <Toast isToastOpen={true}>
      {isError ? (
        <output>{t('Error')}</output>
      ) : isLoading ? (
        <Loading />
      ) : isSuccess ? (
        <>
          <Loading />
          <output>{t('Non_Dismissible_Recall_Modal_Text')}</output>
        </>
      ) : (
        <output>{t('Error')}</output>
      )}
    </Toast>
  );
};

export default BulkRecallPendingModal;
