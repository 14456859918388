import GenericModal from '../../Shared/GenericModal/GenericModal';
import { useCallback, useEffect, useState } from 'react';
import RedWarningIcon from '../../../assets/icons/RedWarningIcon.svg';
import { isAccountVerified } from '../../../utils/accountUtils';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsVerifiedAccountModalOpen, setIsVerifiedAccountModalOpen } from '../../../features/modal/modalSlice';
import { userEvent } from '../../../utils/analytics';
import { useOktaAuth } from '@okta/okta-react';
import { useVerificationEmailMutation } from '../api/profileServiceApi';
import './VerifiedAccountModal.scss';

const VerifiedAccountModal = ({ data }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectIsVerifiedAccountModalOpen);
  const [hasResentEmail, setHasResentEmail] = useState(false);
  const { t } = useTranslation();
  const { oktaAuth, authState } = useOktaAuth();
  const userEmail = authState?.idToken?.claims?.email;
  const [sendEmail] = useVerificationEmailMutation();

  const setOpenModal = useCallback(
    (param) => {
      dispatch(setIsVerifiedAccountModalOpen(param));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data && data.length) {
      const accountIsVerified = data.every((event) => isAccountVerified(event));
      if (!accountIsVerified) {
        setOpenModal(!accountIsVerified);
        userEvent({
          event: 'modal-impression',
          page: {
            title: 'group tickets',
            type: 'event display',
            app: {
              name: 'bulk ticket management'
            }
          },
          modal: {
            title: 'unverified alert',
            option: {
              indexTotal: 1
            }
          }
        });
      }
    }
  }, [data, setOpenModal]);

  const handleLogoutClick = () => {
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 1,
          text: 'logout',
          targetUrl: 'https://ids.mlb.com/'
        }
      },
      page: {
        subpage: {
          title: 'event display'
        },
        title: 'group tickets',
        type: 'event display',
        app: {
          name: 'bulk ticket management'
        }
      },
      modal: {
        option: {
          indexTotal: 1
        },
        title: 'unverified alert'
      }
    });
    oktaAuth.signOut();
  };

  const handleResendClick = async () => {
    setHasResentEmail(true);
    await sendEmail(userEmail);
    userEvent({
      event: 'modal-click',
      action: {
        element: {
          index: 1,
          text: 'send verification email',
          targetUrl: '/events'
        }
      },
      page: {
        subpage: {
          title: 'event display'
        },
        title: 'group tickets',
        type: 'event display',
        app: {
          name: 'bulk ticket management'
        }
      },
      modal: {
        option: {
          indexTotal: 1
        },
        title: 'unverified alert'
      }
    });
  };

  return (
    <>
      {isModalOpen ? (
        <GenericModal isModalOpen={isModalOpen} setIsModalOpen={setOpenModal} closeable={false}>
          <div className="verified_accounts_modal">
            <img src={RedWarningIcon} alt={t('Warning_Icon')} />
            <span>{`${t('Unverified_Account_Warning')}${userEmail}`}</span>
            <div className="verified_button_container">
              <div>
                <button className="logout_button" aria-label={t('Logout')} onClick={handleLogoutClick}>
                  {t('Logout')}
                </button>
              </div>
              <div>
                <button
                  className="resend-button"
                  aria-label={t('Resend_Verification')}
                  onClick={handleResendClick}
                  disabled={hasResentEmail}
                >
                  {hasResentEmail ? t('Sent') : t('Resend_Verification')}
                </button>
              </div>
            </div>
          </div>
        </GenericModal>
      ) : null}
    </>
  );
};

export default VerifiedAccountModal;
