import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SharedTicket from '../Ticket/SharedTicket';

const SharedTicketSection = ({
  invalidEmailForwards,
  otherFailingForwards,
  pendingForwards,
  acceptedForwards,
  inProgressForwards,
  isOnStealthMode,
  handleTicketBtnClick,
  isManageBtnActive,
  recallableTickets,
  checkedTicketsByEmail,
  setcheckedTicketsByEmail,
  areThereForwardingTickets,
  invalidEmailMapping
}) => {
  const { t } = useTranslation();
  const forwardedTicketsBySection = inProgressForwards ?? pendingForwards ?? otherFailingForwards ?? acceptedForwards;
  const ticketCountByStatus = useMemo(() => {
    return forwardedTicketsBySection?.reduce((currentCount, row) => currentCount + row.length, 0);
  }, [forwardedTicketsBySection]);
  const ticketCountText =
    (ticketCountByStatus && ticketCountByStatus > 1) || invalidEmailForwards?.length > 1 ? t('Tickets') : t('Ticket');

  const handleCheckClick = useCallback(
    (email) => {
      recallableTickets?.forEach((ticket) => {
        if (email && ticket.recipientAddress === email) {
          if (!checkedTicketsByEmail?.includes(email)) {
            setcheckedTicketsByEmail((emails) => [...new Set([...emails, email])]);
          } else {
            setcheckedTicketsByEmail((emails) => emails.filter((recipientEmail) => recipientEmail !== email));
          }
        }
      });
    },
    [recallableTickets, checkedTicketsByEmail, setcheckedTicketsByEmail]
  );

  return (
    <section className="shared_tix_cont">
      <div className="status_header">
        <h6 className="status_text">
          {acceptedForwards?.length > 0 && t('Accepted')}
          {pendingForwards?.length > 0 && t('Processing')}
          {inProgressForwards?.length > 0 && t('Pending_Acceptance')}
          {invalidEmailForwards?.length > 0 && t('Invalid Email')}
          {otherFailingForwards?.length > 0 && t('Errors')}
          {!areThereForwardingTickets && (
            <span className="ticket_count_by_status">
              {` - ${
                invalidEmailForwards?.length > 0 ? invalidEmailForwards?.length : ticketCountByStatus
              } ${ticketCountText}`}
            </span>
          )}
        </h6>
      </div>
      {invalidEmailForwards?.length > 0 && invalidEmailMapping
        ? Object.values(invalidEmailMapping).map((tik, i) => (
            <div className="row_section_header_cont" key={i} tabIndex={isOnStealthMode ? '-1' : '0'}>
              <SharedTicket
                key={i}
                seat={{
                  ...tik[0],
                  name: tik[0].recipientName,
                  email: tik[0].recipientAddress,
                  ticketCount: tik.length
                }}
                handleTicketBtnClick={handleTicketBtnClick}
                fixable={invalidEmailForwards?.length > 0}
                isManageBtnActive={isManageBtnActive}
                handleCheckClick={handleCheckClick}
                checkedTicketsByEmail={checkedTicketsByEmail}
                isOnStealthMode={isOnStealthMode}
              />
            </div>
          ))
        : forwardedTicketsBySection?.map((ticket, index) => (
            <div className="row_section_header_cont" key={index} tabIndex={isOnStealthMode ? '-1' : '0'}>
              <div className="row_label_cont">
                <h6 className="row_section_label">
                  {ticket?.[0].access === 'PARKING' ? (
                    <span>{t('NonVenueTitle')}</span>
                  ) : (
                    <span>
                      {`${t('Section')} ${ticket?.[0]?.location?.section.value} - ${t('Row')} ${
                        ticket?.[0]?.location?.row.value
                      }`}
                    </span>
                  )}
                </h6>
              </div>
              {ticket?.map((tik, i) => (
                <SharedTicket
                  key={i}
                  seat={{
                    ...tik,
                    name: tik.recipientName,
                    email: tik.recipientAddress
                  }}
                  cancellable={pendingForwards?.length > 0 || inProgressForwards?.length > 0}
                  recallable={acceptedForwards?.length > 0}
                  viewable={otherFailingForwards?.length > 0}
                  isOnStealthMode={isOnStealthMode}
                  handleTicketBtnClick={handleTicketBtnClick}
                  isManageBtnActive={isManageBtnActive}
                  recallableTickets={recallableTickets}
                  handleCheckClick={handleCheckClick}
                  checkedTicketsByEmail={checkedTicketsByEmail}
                />
              ))}
            </div>
          ))}
    </section>
  );
};

export default SharedTicketSection;
