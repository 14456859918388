import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTicketId: '',
  currentForwardId: '',
  recallModal: {
    isModalOpen: false
  },
  cancelModal: {
    isModalOpen: false
  },
  viewModal: {
    isModalOpen: false,
    currentTicketInfo: {}
  },
  invalidEmailModal: { isModalOpen: false },
  bulkRecallToast: {
    isModalOpen: false
  }
};

const sharedTicketModalSlice = createSlice({
  name: 'sharedTicketModal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      if (action.payload === 'recall') {
        state.recallModal.isModalOpen = true;
      } else if (action.payload === 'cancel') {
        state.cancelModal.isModalOpen = true;
      } else if (action.payload === 'bulk_recall') {
        state.bulkRecallToast.isModalOpen = true;
      } else if (action.payload === 'invalid_email') {
        state.invalidEmailModal.isModalOpen = true;
      } else {
        state.viewModal.isModalOpen = true;
      }
    },
    closeModal: (state, action) => {
      if (action.payload === 'recall') {
        state.recallModal.isModalOpen = false;
      } else if (action.payload === 'cancel') {
        state.cancelModal.isModalOpen = false;
      } else if (action.payload === 'view') {
        state.viewModal.isModalOpen = false;
      } else if (action.payload === 'bulk_recall') {
        state.bulkRecallToast.isModalOpen = false;
      } else if (action.payload === 'invalid_email') {
        state.invalidEmailModal.isModalOpen = false;
      } else {
        state.recallModal.isModalOpen = false;
        state.cancelModal.isModalOpen = false;
        state.viewModal.isModalOpen = false;
        state.bulkRecallToast.isModalOpen = false;
        state.invalidEmailModal.isModalOpen = false;
      }
    },
    updateRecallTicketId: (state, action) => {
      if (action.payload) {
        state.recallModal.ticketId = action.payload;
      }
    },
    updateCancelTicketId: (state, action) => {
      if (action.payload) {
        state.cancelModal.ticketId = action.payload;
      }
    },
    updateSharedTicketId: (state, action) => {
      if (action.payload) {
        state.currentTicketId = action.payload;
      }
    },
    updateForwardId: (state, action) => {
      if (action.payload) {
        state.currentForwardId = action.payload;
      }
    },
    updateTicketInfoForViewModal: (state, action) => {
      if (action.payload) {
        state.viewModal.currentTicketInfo = action.payload;
      }
    }
  }
});

export const {
  openModal,
  closeModal,
  updateCancelTicketId,
  updateRecallTicketId,
  updateSharedTicketId,
  updateForwardId,
  updateTicketInfoForViewModal
} = sharedTicketModalSlice.actions;
export default sharedTicketModalSlice.reducer;
export const selectRecallModalOpen = (state) => state.sharedTicketModal.recallModal.isModalOpen;
export const selectCancelModalOpen = (state) => state.sharedTicketModal.cancelModal.isModalOpen;
export const selectViewModalOpen = (state) => state.sharedTicketModal.viewModal.isModalOpen;
export const selectInvalidEmailModalOpen = (state) => state.sharedTicketModal.invalidEmailModal.isModalOpen;
export const selectBulkRecallToastOpen = (state) => state.sharedTicketModal.bulkRecallToast.isModalOpen;
export const selectRecallTicketId = (state) => state.sharedTicketModal.recallModal.ticketId;
export const selectCancelTicketId = (state) => state.sharedTicketModal.cancelModal.ticketId;
export const selectCurrentTicketId = (state) => state.sharedTicketModal.currentTicketId;
export const selectCurrentForwardId = (state) => state.sharedTicketModal.currentForwardId;
export const selectTicketInfoForViewModal = (state) => state.sharedTicketModal.viewModal.currentTicketInfo;
