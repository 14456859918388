import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import { useTranslation } from 'react-i18next';
import './Spreadsheet.scss';
import 'handsontable/dist/handsontable.full.css';
import { emailValidator } from '../../utils/helpers/validators';
import { removeWhiteSpace } from '../../utils/helpers/formatters';

const ManualSpreadsheet = ({
  hotTableRef,
  validationActive,
  initData,
  setManualTicketRemainingAmount,
  addTooltipOnSelect,
  setIsSaveDisabled,
  modalRef
}) => {
  const hot = hotTableRef?.current?.hotInstance;
  const headers = ['Section', 'Row', 'Seat', 'Name', 'Email'];
  const { t } = useTranslation();

  const handleChanges = (changes) => {
    //This wrapper function is so that we can execute multiple functions on change
    //Handsontable's support of multiple functions this way causes the functions to be executed twice
    handleTicketInputChange(changes);
    validateSaveButton();
  };

  const handleTicketInputChange = (changes) => {
    if (changes) {
      const data = hotTableRef?.current?.hotInstance?.getData();
      let numberOfAvailableTickets = 0;
      data.forEach((row) => {
        if (row[3] !== '' || row[4] !== '') {
          numberOfAvailableTickets++;
        }
      });
      setManualTicketRemainingAmount(numberOfAvailableTickets);
    }
  };

  const validateSaveButton = () => {
    //Disables the save and continue button when the table is empty or there are too many tickets
    if (hotTableRef && hotTableRef.current && hotTableRef.current.hotInstance) {
      const hot = hotTableRef?.current?.hotInstance;
      if (hot.countEmptyCols() !== 2) {
        //If there are two empty columns, the name and email columns, then disable save button
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    }
  };

  const scrollTooltip = () => {
    //Hides the tooltip if it would scroll out of the view of the spreadsheet
    if (validationActive) {
      const selected = hot.getSelected();
      const tooltip = document.querySelector('.hot-tooltip');
      if (!selected) {
        tooltip.style.visibility = 'hidden';
        return;
      }
      const selectedCell = hot.getCell(selected[0][0], selected[0][1]);
      if (selected && selected.length === 1 && selected[0][0] === selected[0][2] && selected[0][1] === selected[0][3]) {
        if (
          (selected[0][1] !== 3 && selected[0][1] !== 4) ||
          (hot.getDataAtCell(selected[0][0], 3) === '' && hot.getDataAtCell(selected[0][0], 4) === '') ||
          (selected[0][1] === 3 && hot.getDataAtCell(selected[0][0], 3) !== '') ||
          (selected[0][1] === 4 && emailValidator.test(hot.getDataAtCell(selected[0][0], 4))) ||
          !selectedCell
        ) {
          tooltip.style.visibility = 'hidden';
          return;
        }
        const spreadsheetRect = document.querySelector('.wtHolder').getBoundingClientRect();
        const tdRect = selectedCell.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();
        const modalRect = modalRef.current.getBoundingClientRect();
        if (tdRect.top < spreadsheetRect.top + tdRect.height || tdRect.top > spreadsheetRect.bottom) {
          tooltip.style.visibility = 'hidden';
          return;
        }
        tooltip.style.visibility = 'visible';
        tooltip.style.top = `${tdRect.top - modalRect.top - 35}px`;
        tooltip.style.left = `${tdRect.x - modalRect.x + (tdRect.width - tooltipRect.width) / 2}px`;
      }
    }
  };

  const handleFocus = () => {
    if (hotTableRef && hotTableRef.current && hotTableRef.current.hotInstance) {
      hot?.selectCell(0, 0);
    }
  };

  const handleTabatLastCell = (e) => {
    const rowCount = hot?.countRows();
    const cellCoords = hot?.getSelectedLast();
    if (cellCoords && cellCoords[0] === rowCount - 1 && cellCoords[1] === 4) {
      if (e.shiftKey && e.key === 'Tab') {
        hot.selectCell(0, rowCount);
      } else if (e.key === 'Tab') {
        hot.deselectCell();
      }
    }
  };

  const customRenderer = (instance, td, row, col, prop, value) => {
    Handsontable.dom.empty(td);
    let text = document.createTextNode(value ? value : '');
    td.appendChild(text);
    if (validationActive) {
      if (!(instance.getDataAtCell(row, 3) === '' && instance.getDataAtCell(row, 4) === '')) {
        if (!value || value === '' || (col === 4 && !emailValidator.test(value))) {
          let img = document.createElement('IMG');
          img.src = `./ValidationErrorIcon.svg`;
          img.style.height = '16px';
          img.style.marginTop = '5px';
          img.style.float = 'right';
          img.setAttribute('alt', t('Validation_Error'));
          td.appendChild(img);
          td.setAttribute('aria-describedby', 'tooltip');
        }
      }
    }
    return td;
  };

  return (
    <>
      <div tabIndex={0} onFocus={handleFocus} />
      <HotTable
        data={initData}
        id="embedded_spreadsheet"
        ref={hotTableRef}
        colHeaders={headers}
        width={610}
        height={400}
        afterScrollVertically={scrollTooltip}
        afterSelection={addTooltipOnSelect}
        afterDocumentKeyDown={handleTabatLastCell}
        maxRows={initData?.length}
        columns={[
          {
            width: 85,
            readOnly: true,
            className: 'htReadOnly'
          },
          {
            width: 58,
            readOnly: true,
            className: 'htReadOnly'
          },
          {
            width: 51,
            readOnly: true,
            className: 'htReadOnly'
          },
          {
            width: 169,
            renderer: customRenderer
          },
          {
            width: 232,
            renderer: customRenderer
          },
          {
            width: 1,
            readOnly: true,
            className: 'hidden'
          }
        ]}
        beforeChange={onBeforeChangeWrapper}
        afterChange={handleChanges}
      />
    </>
  );
};

export default ManualSpreadsheet;

export const onBeforeChangeWrapper = (changes) => {
  spreadsheetFormatter(changes);
};

const spreadsheetFormatter = (changes) => {
  //Removes newlines and whitespace from spreadsheet changes
  changes.forEach((element) => {
    element[3] = removeWhiteSpace(element[3]);
  });
};
