import { Navigate } from 'react-router-dom';

const AuthError = () => {
  return (
    <>
      <h1>Navigating</h1>
      <Navigate to="/events" />
    </>
  );
};

export default AuthError;
