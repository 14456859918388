import { useDispatch, useSelector } from 'react-redux';
import { areAllSeatsChecked, checkAllBoxes, uncheckAllBoxes } from '../../../stores/allocationSlice';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../Checkbox/Checkbox';

const ShareAll = () => {
  const allSeatsChecked = useSelector(areAllSeatsChecked);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleShareAllClick = () => {
    if (allSeatsChecked) {
      dispatch(uncheckAllBoxes());
      return;
    }
    dispatch(checkAllBoxes());
  };

  return (
    <div className="row_section_header_cont">
      <div className="share_all">
        <Checkbox handleCheckClick={handleShareAllClick} isChecked={allSeatsChecked} checkBoxId="select_all_btn" />
        <span className="share_all_text">{t('Select_All')}</span>
      </div>
    </div>
  );
};

export default ShareAll;
