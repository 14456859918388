import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchTerm: '',
    isSearchOpen: false
  },
  reducers: {
    clearSearch: (state) => {
      state.searchTerm = '';
    },
    updateSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setSearchIsOpen: (state, action) => {
      state.isSearchOpen = action.payload;
    }
  }
});

export default searchSlice.reducer;
export const { clearSearch, updateSearchTerm, setSearchIsOpen } = searchSlice.actions;

export const selectSearchTerm = (state) => state.search?.searchTerm;
export const selectIsSearchOpen = (state) => state.search?.isSearchOpen;
