import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import NotificationDot from '../../Shared/Icons/NotificationDot';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search_Icon.svg';
import { selectIsSearchOpen, setSearchIsOpen, clearSearch } from '../../../stores/searchSlice';
import { userEvent } from '../../../utils/analytics';
import './TabList.scss';
import { selectTimeOfInventoryRefresh, setTimeOfRefresh } from '../../../stores/allocationSlice';
import RefreshButton from './RefreshButton';

const TabList = ({
  activeTab,
  isOnStealthMode,
  gamePk,
  forwardingError,
  isRefreshBtnClicked,
  setIsRefreshBtnClicked
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const timeOfInventoryRefresh = useSelector(selectTimeOfInventoryRefresh);
  const lastUpdated = timeOfInventoryRefresh[gamePk];

  const handleSearchClick = () => {
    dispatch(setSearchIsOpen(!isSearchOpen));
    dispatch(clearSearch());
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'open search'
        }
      },
      page: {
        title: 'group tickets',
        app: {
          name: 'bulk ticket management'
        },
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        }
      }
    });
  };

  const handleRefreshBtnClick = () => {
    setIsRefreshBtnClicked(true);
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'refresh: ticket display'
        }
      },
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        subPage: {
          title: activeTab === 'tixTab1' ? 'available tickets' : 'shared tickets'
        },
        app: {
          name: 'bulk ticket management'
        }
      }
    });
  };

  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (isRefreshBtnClicked) {
      const timer = setTimeout(() => {
        dispatch(setTimeOfRefresh({ gamePk: gamePk, time: Date.now() }));
        setIsRefreshBtnClicked(false);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isRefreshBtnClicked, setIsRefreshBtnClicked, dispatch, gamePk]);

  useEffect(() => {
    if (lastUpdated) {
      const timer = setInterval(() => {
        const remainingTime = (Date.now() - lastUpdated) / 1000; // the refresh button remains disabled for 90 secs unless user forces a browser refresh
        if (remainingTime > 90) {
          dispatch(setTimeOfRefresh({ gamePk: gamePk, time: '' }));
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [dispatch, gamePk, lastUpdated, timeOfInventoryRefresh]);

  return (
    <ul className="tickets_tab_list" aria-label="tickets tab list">
      <li className={'tab_header' + (activeTab === 'tixTab1' ? ' active_tab' : '')}>
        <Link
          id="tixTab1"
          to={`/events/${gamePk}/available-tickets`}
          tabIndex={isOnStealthMode ? '-1' : '0'}
          aria-label={t('Available_Tickets_Tab')}
        >
          {t('Available_Tickets')}
        </Link>
      </li>
      <li className={'tab_header' + (activeTab === 'tixTab2' ? ' active_tab' : '')}>
        <Link
          id="tixTab2"
          to={`/events/${gamePk}/shared-tickets`}
          tabIndex={isOnStealthMode ? '-1' : '0'}
          aria-label={t('Shared_Tickets_Tab')}
        >
          {t('Shared_Tickets')}
        </Link>
        {forwardingError && <NotificationDot />}
      </li>
      <li className="tab_header refresh_tab">
        <RefreshButton
          lastUpdated={lastUpdated}
          isTabbable={isOnStealthMode ? '-1' : '0'}
          handleRefreshBtnClick={handleRefreshBtnClick}
          optionalClass={isRefreshBtnClicked ? ' refreshing' : ''}
        />
      </li>
      <li
        className={'search_tab tab_header' + (isSearchOpen ? ' active_tab' : '')}
        onKeyDown={keyDownHandler}
        onClick={handleSearchClick}
        tabIndex={isOnStealthMode ? '-1' : '0'}
      >
        <div>
          <span>{t('Search')}</span>
          <div>
            <SearchIcon id="searchIcon" alt={t('Search_Icon')} />
          </div>
        </div>
      </li>
    </ul>
  );
};

export default TabList;
