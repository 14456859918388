import { useSelector } from 'react-redux';
import { areNoSeatsChecked } from '../../../stores/allocationSlice';
import { useTranslation } from 'react-i18next';

const SidePanelManageList = ({ handleRemoveSelectedBtnClick }) => {
  const { t } = useTranslation();
  const noTicketsSelected = useSelector(areNoSeatsChecked);

  return (
    <button
      id="remove_tix_selected_btn"
      aria-label="remove selected"
      disabled={noTicketsSelected}
      onClick={handleRemoveSelectedBtnClick}
    >
      {t('Remove_Selected')}
    </button>
  );
};

export default SidePanelManageList;
