import ShareAll from './ShareAll';
import { useTranslation } from 'react-i18next';
import './SelectAllHeader.scss';
import ManageListButton from './ManageListButton';

const SelectAllHeader = ({ handleCancelBtnClick, isShareActive, isOnStealthMode, isBulkRecallActive }) => {
  const { t } = useTranslation();

  return (
    <div className={'select_all_tix_header' + (isBulkRecallActive ? ' cancel_only' : '')}>
      {!isBulkRecallActive && <ShareAll />}
      {isShareActive ? (
        <ManageListButton isOnStealthMode={isOnStealthMode} />
      ) : (
        <button id="cancel_select_all_tix_btn" onClick={handleCancelBtnClick} tabIndex={isOnStealthMode ? '-1' : '0'}>
          {t('Cancel')}
        </button>
      )}
    </div>
  );
};

export default SelectAllHeader;
