import { useTranslation } from 'react-i18next';
import ManageListButton from './ManageListButton';

const TicketsRowContainer = ({
  children,
  tik,
  leading,
  manageable,
  handleManageListBtnClick,
  isManageBtnActive,
  isShareActive,
  isOnStealthMode,
  ticketCountByRow,
  hasNonVenueTickets
}) => {
  const { t } = useTranslation();
  const ticketText = ticketCountByRow > 1 ? t('Tickets') : t('Ticket');

  return (
    <section className="row_section_header_cont">
      <div className="row_label_cont">
        {!hasNonVenueTickets && (
          <h6 className="row_section_label">
            {t('Section')} {tik[0]?.location.section.value} - {t('Row')} {tik[0]?.location.row.value}
            {!isManageBtnActive && !isShareActive && (
              <span className="ticket_count_by_row">{` - ${ticketCountByRow} ${ticketText}`}</span>
            )}
          </h6>
        )}
        {leading && !isManageBtnActive && !isShareActive && (
          <ManageListButton
            manageable={manageable}
            handleManageListBtnClick={handleManageListBtnClick}
            isOnStealthMode={isOnStealthMode}
          />
        )}
      </div>
      {children}
    </section>
  );
};

export default TicketsRowContainer;
