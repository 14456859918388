import './Navbar.scss';
import MLBlogo from '../../../assets/icons/MLB_logo.svg';
import { Link } from 'react-router-dom';
import { MenuIcon } from '../Icons/Icons';
import Logout from '../../../features/logout/Logout';
import { useSelector } from 'react-redux';
import { selectLogout } from '../../../features/logout/logoutSlice';
import { useTranslation } from 'react-i18next';
import {
  selectPeopleModalOpen,
  selectPersonModalOpen,
  selectManualAllocationModalOpen,
  selectRemoveTicketsModalState
} from '../../../features/modal/modalSlice';
import {
  selectBulkRecallToastOpen,
  selectCancelModalOpen,
  selectInvalidEmailModalOpen,
  selectRecallModalOpen,
  selectViewModalOpen
} from '../../../features/modal/SharedTicketModals/sharedTicketModalSlice';

const Navbar = () => {
  const { t } = useTranslation();
  const logoutOpened = useSelector(selectLogout);
  const isPeopleModalOpen = useSelector(selectPeopleModalOpen);
  const isPersonModalOpen = useSelector(selectPersonModalOpen);
  const isRecallModalOpen = useSelector(selectRecallModalOpen);
  const isCancelModalOpen = useSelector(selectCancelModalOpen);
  const isManualAllocModalOpen = useSelector(selectManualAllocationModalOpen);
  const isViewModalOpen = useSelector(selectViewModalOpen);
  const isRemoveTicketsModalOpen = useSelector(selectRemoveTicketsModalState);
  const isBulkRecallToastOpen = useSelector(selectBulkRecallToastOpen);
  const isInvalidEmailModalOpen = useSelector(selectInvalidEmailModalOpen);

  const isOnStealthMode =
    logoutOpened ||
    isPeopleModalOpen ||
    isPersonModalOpen ||
    isRecallModalOpen ||
    isCancelModalOpen ||
    isViewModalOpen ||
    isRemoveTicketsModalOpen ||
    isManualAllocModalOpen ||
    isBulkRecallToastOpen ||
    isInvalidEmailModalOpen;

  return (
    <>
      <header className="nav_bar" aria-label={t('Navbar_GroupTickets')} id="header">
        <MenuIcon />
        <img className="navbar_logo" src={MLBlogo} alt={t('Navbar_LogoAlt')} />
        <nav>
          <Link className="homepage_link" tabIndex={isOnStealthMode ? -1 : 0} to="/">
            <h3>{t('Navbar_GroupTickets')}</h3>
          </Link>
        </nav>
      </header>
      <Logout />
    </>
  );
};

export default Navbar;
