import GenericModal from '../../Shared/GenericModal/GenericModal';
import RedWarningIcon from '../../../assets/icons/RedWarningIcon.svg';
import { userEvent } from '../../../utils/analytics';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import './InvalidSaveModal.scss';

const InvalidSaveModal = ({ setIsInvalidSaveModalOpen, gamePk }) => {
  const { t } = useTranslation();

  useEffect(() => {
    userEvent({
      event: 'modal-impression',
      page: {
        title: 'group tickets',
        gamePk: gamePk,
        app: {
          name: 'bulk ticket management'
        },
        subPage: {
          title: 'ticket display'
        }
      },
      modal: {
        title: 'inventory change alert',
        option: {
          indexTotal: 1
        }
      }
    });
  });

  const handleViewClick = () => {
    userEvent({
      event: 'modal-click',
      page: {
        title: 'group tickets',
        type: 'ticket display',
        gamePk: gamePk,
        app: {
          name: 'bulk ticket management'
        },
        subPage: {
          title: 'ticket display'
        }
      },
      modal: {
        title: 'inventory change alert',
        option: {
          indexTotal: 1
        }
      },
      action: {
        element: {
          index: 1,
          text: 'view available tickets',
          targetUrl: `/events/${gamePk}/available-tickets`
        }
      }
    });
    setIsInvalidSaveModalOpen(false);
  };

  return (
    <GenericModal isModalOpen={true} closeable={false}>
      <div className="invalid_save_modal">
        <img src={RedWarningIcon} alt={t('Warning_Icon')} />
        <span>{t('Invalid_Save')}</span>
        <button className="view_tickets_btn" aria-label={t('View_Available_Tickets')} onClick={handleViewClick}>
          {t('View_Available_Tickets')}
        </button>
      </div>
    </GenericModal>
  );
};

export default InvalidSaveModal;
