import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import RequiredAuth from './SecureRoute';
import EventDisplay from '../components/EventDisplay/EventDisplay';
import Navbar from '../../src/components/Shared/Navbar/Navbar';
import Loading from '../../src/components/Shared/Loading/Loading';
import AuthError from '../../src/components/Shared/AuthError/AuthError';
import TicketDisplay from '../components/TicketDisplay/TicketDisplay';
import environmentConfig from '../config/config';
import Status from '../components/Shared/Status/Status';

export default function GTMRoutes() {
  const oktaAuth = new OktaAuth({
    issuer: environmentConfig.oktaIssuer,
    clientId: environmentConfig.oktaClientId,
    redirectUri: environmentConfig.oktaRedirectUri
  });

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={environmentConfig.oktaRedirectUri}>
        <Navbar />
        <Routes>
          <Route
            path={environmentConfig.callbackRoute}
            element={<LoginCallback errorComponent={AuthError} loadingElement={<Loading />} />}
          />
          <Route path="/events" element={<RequiredAuth />}>
            <Route path="" element={<EventDisplay />} />
            <Route path="/events/:gamePk/available-tickets" element={<TicketDisplay currentTab={'tixTab1'} />} />
            <Route path="/events/:gamePk/shared-tickets" element={<TicketDisplay currentTab={'tixTab2'} />} />
          </Route>
          <Route path="/status" element={<Status />} />
          <Route path="*" element={<Navigate to="/events" />} />
        </Routes>
      </Security>
    </BrowserRouter>
  );
}
