import './EventCard.scss';
import { selectLogout } from '../../../features/logout/logoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userEvent } from '../../../utils/analytics';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EventHeader from './EventHeader/EventHeader';
import { useGetSaveQuery, useGetTicketsQuery } from '../../TicketDisplay/TicketList/api/ticketDisplayListApi';
import { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import {
  openModal,
  selectShouldEventsRefresh,
  selectShouldTicketsRefresh,
  setShouldTicketsRefresh,
  setIsVerifiedAccountModalOpen
} from '../../../features/modal/modalSlice';
import useResize from '../../../utils/hooks/useResize';
import SkeletonLoader from '../../Shared/SkeletonLoader/SkeletonLoader';
import { isAccountVerified } from '../../../utils/accountUtils';

const EventCard = ({ data, id, hideTicketCounts }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLogoutOpen = useSelector(selectLogout);
  const { gamePk, homeTeamId } = data;
  const [btnClicked, setBtnClicked] = useState(false);
  const shouldTicketsRefresh = useSelector(selectShouldTicketsRefresh);
  const tickets = useGetTicketsQuery(
    { gamePk: gamePk, homeTeamId: homeTeamId, refresh: shouldTicketsRefresh },
    { skip: !gamePk || !homeTeamId || !btnClicked }
  );
  const savedData = useGetSaveQuery({ gamePk }, { skip: !btnClicked });
  const dispatch = useDispatch();
  const { innerWidth } = useResize();
  const [browserWidth, setBrowserWidth] = useState();
  const isPageLoading = savedData?.isLoading || tickets?.isLoading;
  const shouldEventsRefresh = useSelector(selectShouldEventsRefresh);

  useEffect(() => {
    setBrowserWidth(innerWidth);
  }, [innerWidth]);

  useEffect(() => {
    if (
      shouldEventsRefresh &&
      btnClicked &&
      data?.ticketCount + data?.forwardCompletedCount !==
        tickets?.data?.availableTickets.length + tickets?.data?.sharedTickets.length &&
      tickets?.data?.availableTickets.length !== undefined &&
      tickets?.data?.sharedTickets.length !== undefined
    ) {
      dispatch(setShouldTicketsRefresh(true));
    }
  }, [
    data?.ticketCount,
    data?.forwardCompletedCount,
    dispatch,
    tickets?.data?.availableTickets,
    tickets?.data?.sharedTickets,
    btnClicked,
    shouldEventsRefresh
  ]);

  const handleInviteButtonClick = useCallback(() => {
    if (tickets && tickets.data && btnClicked) {
      tickets.data.sharedTickets?.length && tickets.data.availableTickets?.length === 0
        ? navigate(`/events/${gamePk}/shared-tickets`)
        : navigate(`/events/${gamePk}/available-tickets`);
      if (
        (savedData?.data?.recipients?.length === 0 || !savedData?.data?.recipients) &&
        tickets.data.sharedTickets?.length === 0 &&
        tickets.data.availableTickets?.some((ticket) => ticket.forwardable === true)
      ) {
        browserWidth >= 481 ? dispatch(openModal('spreadsheet')) : dispatch(openModal('person'));
      }
      userEvent({
        event: 'button-click',
        action: {
          gamePk: gamePk,
          section: {
            title: 'upcoming events'
          },
          element: {
            index: 0,
            text: t('EventCard_ButtonText'),
            targetUrl: `/events/${gamePk}/available-tickets`
          }
        }
      });
    }
  }, [gamePk, navigate, t, tickets, browserWidth, dispatch, btnClicked, savedData]);

  const handleButtonClick = () => {
    if (!isAccountVerified(data)) {
      dispatch(setIsVerifiedAccountModalOpen(true));
      return;
    }
    setBtnClicked(true);
  };

  useLayoutEffect(() => {
    if (btnClicked) {
      handleInviteButtonClick();
    }
  }, [btnClicked, handleInviteButtonClick, savedData]);

  if (isPageLoading) {
    return <SkeletonLoader />;
  } // we need to have the loader here to not lose all the fetching functionalities

  return (
    <article className="event_card_container" id={id}>
      <div className="event_header_cont">
        <EventHeader hideTicketCounts={hideTicketCounts} isLogoutOpen={isLogoutOpen} eventData={data} />
      </div>
      <div className="event_card_footer">
        <button
          id={`event_${id}_invite_btn`}
          type="button"
          className="invite_btn"
          tabIndex={isLogoutOpen ? '-1' : '0'}
          onClick={() => {
            handleButtonClick();
          }}
        >
          {t('EventCard_ButtonText')}
        </button>
      </div>
    </article>
  );
};
export default EventCard;
