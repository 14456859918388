import { useTranslation } from 'react-i18next';

const SidePanelRecallTickets = ({ isBulkRecallActive, handleBtnClick, isOnStealthMode }) => {
  const { t } = useTranslation();

  return (
    <>
      <button
        id="recall_tix_btn"
        type="button"
        disabled={!isBulkRecallActive}
        aria-label="remove tickets"
        onClick={handleBtnClick}
        tabIndex={isOnStealthMode ? '-1' : '0'}
      >
        {t('Recall_Tickets')}
      </button>
    </>
  );
};

export default SidePanelRecallTickets;
