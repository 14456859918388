import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectManualAllocationModalOpen, selectRemoveTicketsModalState } from '../../../../features/modal/modalSlice';
import TEAM_THEMES from '../../../../utils/team-colors';
import './EventHeader.scss';
import {
  selectBulkRecallToastOpen,
  selectInvalidEmailModalOpen,
  selectViewModalOpen
} from '../../../../features/modal/SharedTicketModals/sharedTicketModalSlice';
import { SharedTicketProgress } from './SharedTicketProgress/SharedTicketProgress';
import { formatDate, formatTime } from '../../../../utils/helpers/formatters';
import { dateFormatValidator, timeFormatValidator } from '../../../../utils/helpers/validators';

const EventHeader = ({
  eventData,
  isLogoutOpen,
  logoutOpened,
  hideTicketCounts,
  availableTicketsCount,
  sharedTicketsCount
}) => {
  const {
    gamePk,
    awayTeamName,
    homeTeamName,
    name,
    dateTime,
    ticketCount,
    forwardCompletedCount,
    homeTeamId,
    ticketBgVidPosterUrl,
    ifNecessary,
    hasPendingForwards
  } = eventData;
  const { venue, date, time } = eventData.displaySummary;

  const { t } = useTranslation();
  const isManualAllocModalOpen = useSelector(selectManualAllocationModalOpen);
  const isRemoveTicketsModalOpen = useSelector(selectRemoveTicketsModalState);
  const isViewModalOpen = useSelector(selectViewModalOpen);
  const isBulkRecallToastOpen = useSelector(selectBulkRecallToastOpen);
  const isInvalidEmailModalOpen = useSelector(selectInvalidEmailModalOpen);
  const isOnStealthMode =
    isLogoutOpen ||
    logoutOpened ||
    isManualAllocModalOpen ||
    isRemoveTicketsModalOpen ||
    isViewModalOpen ||
    isBulkRecallToastOpen ||
    isInvalidEmailModalOpen;

  return (
    <div
      className="event_stadium_img event_card_header mobile"
      tabIndex={isOnStealthMode ? '-1' : '0'}
      style={{ backgroundImage: ticketBgVidPosterUrl ? `url(${ticketBgVidPosterUrl})` : `none` }}
    >
      <div
        className="team_color_overlay"
        style={{ backgroundColor: TEAM_THEMES[homeTeamId]?.teamColor1 ? TEAM_THEMES[homeTeamId].teamColor1 : '#000' }}
      />
      <div className="event_date">
        {date && date === '' ? null : date && dateFormatValidator.test(date) ? (
          <>{formatDate(dateTime)}</>
        ) : (
          <>{date}</>
        )}
        {date && time && date !== '' && time !== '' ? <> • </> : null}
        {time && time === '' ? null : time && timeFormatValidator.test(time) ? (
          <>{`${formatTime(dateTime)}`}</>
        ) : (
          <>{time}</>
        )}
      </div>
      <h3 className="event_teams">
        {name ? (
          <>{name + (ifNecessary === 'Y' || ifNecessary === 'y' ? t('If_Necessary') : '')}</>
        ) : (
          <>
            {awayTeamName} @ {homeTeamName} {ifNecessary === 'Y' || ifNecessary === 'y' ? t('If_Necessary') : ''}
          </>
        )}
      </h3>
      {hasPendingForwards ? (
        <SharedTicketProgress gamePk={gamePk} />
      ) : (
        <div className="event_home_team_logo">
          {homeTeamId && (
            <img
              src={`https://img.mlbstatic.com/mlb-photos/image/upload/w_44,f_png,q_auto/v1/team/${homeTeamId}/logo/cap/dark/current`}
              alt={t('EventCard_logo_alt')}
            />
          )}
        </div>
      )}
      <div className="event_header_bottom">
        {venue ? <div className="event_stadium">{venue}</div> : null}
        {!hideTicketCounts && !hasPendingForwards && (
          <div className="event_ticket_numbers">
            {availableTicketsCount > 0 && !sharedTicketsCount > 0 && (
              <span>
                <b>{availableTicketsCount}</b> {t('EventCard_remaining')}
              </span>
            )}
            {sharedTicketsCount > 0 && (
              <span>
                {availableTicketsCount > 0 && (
                  <>
                    <b>{availableTicketsCount}</b> {t('EventCard_remaining')},{' '}
                  </>
                )}
                <b>{sharedTicketsCount}</b> {t('EventCard_shared')}
              </span>
            )}
          </div>
        )}
        {hasPendingForwards ? (
          <div className="event_processing_text" data-testid="processing_text">
            {t('Processing')}...
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EventHeader;
