import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectIsSearchOpen, updateSearchTerm, selectSearchTerm, clearSearch } from '../../../stores/searchSlice';
import SearchClear from '../../../assets/icons/Search_Clear.svg';
import { userEvent } from '../../../utils/analytics';
import { useLocation } from 'react-router-dom';
import './TicketSearch.scss';

const TicketSearch = ({ gamePk, isOnStealthMode }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const searchTerm = useSelector(selectSearchTerm);
  const location = useLocation();
  const isAvailableTicketsTab = location.pathname.includes('available-tickets');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchTerm === '') {
      setIsButtonDisabled(true);
    }
    setSearchValue(searchTerm);
  }, [searchTerm]);

  const handleInputChange = (e) => {
    if (e.target.value === '') {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
    setSearchValue(e.target.value);
  };

  const handleButtonClick = () => {
    dispatch(updateSearchTerm(searchValue));
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'search'
        }
      },
      page: {
        title: 'group tickets',
        app: {
          name: 'bulk ticket management'
        },
        type: 'ticket detail',
        gamePk: gamePk,
        subPage: {
          title: isAvailableTicketsTab ? 'available tickets' : 'shared tickets'
        }
      }
    });
  };

  const handleSearchClear = (e) => {
    dispatch(clearSearch());
  };

  const handleClearPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClear();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  return (
    <>
      <div className={'search_wrapper' + (isSearchOpen ? '' : ' closed')}>
        <div className="search_container">
          <input
            id="searchInput"
            placeholder={t('Search_Placeholder')}
            onChange={handleInputChange}
            onKeyUp={handleKeyPress}
            value={searchValue}
            tabIndex={isOnStealthMode ? '-1' : '0'}
          ></input>
          <button
            id="searchButton"
            data-testid="searchButton"
            onClick={handleButtonClick}
            disabled={isButtonDisabled}
            tabIndex={isOnStealthMode ? '-1' : '0'}
          >
            {t('Search')}
          </button>
        </div>
        {searchTerm !== '' ? (
          <div className="result_text">
            <span id="searchResultText" data-testid="searchResultText">
              {t('Search_Results')} "{searchTerm}"
            </span>
            <img
              src={SearchClear}
              tabIndex={isOnStealthMode ? '-1' : '0'}
              onKeyUp={handleClearPress}
              onClick={handleSearchClear}
              alt={t('Clear_Search')}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TicketSearch;
