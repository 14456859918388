import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logoutOpen: false
};
const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    logoutToggle: (state) => {
      state.logoutOpen = !state.logoutOpen;
    },
    logoutClose: (state) => {
      state.logoutOpen = false;
    }
  }
});

export const { logoutToggle, logoutClose } = logoutSlice.actions;
export default logoutSlice.reducer;
export const selectLogout = (state) => state.logout.logoutOpen;
