import GTMRoutes from './routes/routes';
import './config/dataDog';
import './i18n';

function App() {
  return (
    <div className="App">
      <GTMRoutes />
    </div>
  );
}

export default App;
