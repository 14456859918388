import './EventTutorial.scss';
import { selectLogout } from '../../../features/logout/logoutSlice';
import tutorialImg from '../../../assets/images/image15.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userEvent } from '../../../utils/analytics';

const EventTutorial = () => {
  const { t } = useTranslation();
  const logoutOpened = useSelector(selectLogout);

  const handleTutorialBtnClick = (e) => {
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'read tutorial',
          targetUrl: '/GroupTickets_Step-By-StepGuide.1.0.3.pdf'
        }
      }
    });
  };

  return (
    <aside className="event_tutorial" id="event_tutorial_1">
      <img className="tutorial_img" src={tutorialImg} alt="tutorial" />
      <div className="tutorial_intro">
        <h3 id="read_tutorial_heading" className="tutorial_heading">
          {t('First_Event')}?
        </h3>
        <p id="read_tutorial_1_desc">{t('Event_Tutorial_Intro')}</p>
        <a
          id="tutorial_1_btn"
          aria-labelledby="read_tutorial_1_desc"
          className="tutorial_btn"
          tabIndex={logoutOpened ? -1 : 0}
          href="/Group-Tickets_Step-by-StepGuide_2.10.pdf"
          download="Group-Tickets_Step-by-StepGuide_2.10.pdf"
          onClick={handleTutorialBtnClick}
        >
          {t('Read_Tutorial')}
        </a>
      </div>
    </aside>
  );
};

export default EventTutorial;
