import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as TicketEditIcon } from '../../../assets/icons/TicketEditButton.svg';
import { ReactComponent as ManualAllocationIcon } from '../../../assets/icons/ManualAllocation.svg';
import { enableEditMode, openModal, enableManualAllocationEditMode } from '../../../features/modal/modalSlice';
import { checkboxesByRecipientId } from '../../../stores/allocationSlice';
import { userEvent } from '../../../utils//analytics';
import Checkbox from '../../Checkbox/Checkbox';
import './Ticket.scss';

const Ticket = ({
  seat,
  recipients,
  isOnStealthMode,
  gamePk,
  isShareActive,
  setCurrentSeat,
  unassignable,
  accounts,
  isManageBtnActive
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isManuallyAssigned = seat?.assigned;
  const targetRecipient =
    seat.seatCount !== 1 && recipients?.filter((recipient) => recipient.recipientId === seat.recipientId); // for auto allocation only
  const showEditIcon = (targetRecipient && seat?.name === targetRecipient[0]?.name) || seat.seatCount === 1;
  const shouldDisplayTicket = seat?.name || (!isShareActive && !isManageBtnActive);
  const isNonVenueTicket = seat?.access !== 'VENUE';

  const handleEditButtonClick = useCallback(() => {
    if (recipients?.length > 0) {
      isManuallyAssigned && setCurrentSeat(seat);
      isManuallyAssigned
        ? dispatch(enableManualAllocationEditMode(seat.recipientId))
        : dispatch(enableEditMode(seat.recipientId));
      userEvent({
        event: 'button-click',
        action: {
          element: {
            index: isManuallyAssigned ? '' : 1, // this field for manual allocation is labeled as optional in GROUP-111
            text: isManuallyAssigned ? 'edit ticket' : 'edit person'
          }
        },
        page: {
          app: {
            name: 'bulk ticket management'
          },
          gamePk: gamePk,
          subPage: {
            title: 'available tickets'
          },
          title: 'group tickets',
          type: 'ticket detail'
        }
      });
    }
  }, [dispatch, recipients, gamePk, seat, setCurrentSeat, isManuallyAssigned]);

  const handlePlusIconClick = useCallback(() => {
    setCurrentSeat(seat);
    dispatch(openModal('manual'));
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: '', // this will be blank for now
          text: 'assign ticket'
        }
      },
      page: {
        app: {
          name: 'bulk ticket management',
          version: '1.0'
        },
        gamePk: gamePk,
        subPage: {
          title: 'available tickets'
        },
        title: 'group tickets',
        type: 'ticket detail'
      }
    });
  }, [dispatch, seat, setCurrentSeat, gamePk]);

  const handleClick = () => {
    dispatch(checkboxesByRecipientId(seat.recipientId));
  };

  return shouldDisplayTicket ? (
    <div className="seat_cont">
      {(isShareActive || isManageBtnActive) && seat.name && !accounts?.includes(seat.email.toLowerCase()) ? (
        <span>
          <Checkbox handleCheckClick={handleClick} isChecked={seat.isChecked} />
        </span>
      ) : null}
      <div className="ticket_seat">
        {isNonVenueTicket ? (
          <span data-testid="parking_ticket_text">{t('Parking')}</span>
        ) : (
          <span data-testid="seat_number">
            {t('Seat')} {seat?.location.seat.value}
          </span>
        )}
        {seat.assigned ? <span className="ticket_badge">{t('Assigned')}</span> : null}
        {accounts && accounts.length && accounts.includes(seat?.email?.toLowerCase()) ? (
          <span className="ticket_badge">{t('Self')}</span>
        ) : null}
      </div>
      {seat?.name ? (
        <article className="ticket_assigned_cont">
          <div className="ticket_info">
            <div className="ticket_name">{seat.name}</div>
            <div className="ticket_email">{seat.email}</div>
          </div>
          {showEditIcon && !isShareActive && !isManageBtnActive && (
            <button
              id="ticket_edit_btn"
              aria-label={t('Ticket_Edit')}
              aria-haspopup="true"
              onClick={handleEditButtonClick}
              tabIndex={isOnStealthMode ? '-1' : '0'}
            >
              <TicketEditIcon />
            </button>
          )}
        </article>
      ) : (
        <article className="ticket_cont">
          {unassignable ? (
            <span className="unassignable_text">{t('NonForwardableText')}</span>
          ) : (
            <>
              {t('Unassigned')}
              <button
                id="manual_alloc_btn"
                aria-label="manual allocation"
                aria-haspopup="true"
                onClick={handlePlusIconClick}
                tabIndex={isOnStealthMode ? '-1' : '0'}
              >
                <ManualAllocationIcon />
              </button>
            </>
          )}
        </article>
      )}
    </div>
  ) : null;
};

export default Ticket;
