import { useState, useEffect, useRef, useCallback } from 'react';
import { ReactComponent as WarningIcon } from '../../../assets/icons/ValidationErrorIcon.svg';
import { emailValidator } from '../../../utils/helpers/validators';
import CloseBtn from '../../../assets/icons/CloseButton.svg';
import { useTranslation } from 'react-i18next';
import { userEvent } from '../../../utils/analytics';
import './InvalidEmailModalMain.scss';

const InvalidEmailModalMain = ({
  setHasBeenEdited,
  invalidEmailInfo,
  handleCloseBtnClick,
  formData,
  setFormData,
  handleRemoveBtnClick,
  handleRetryBtnClick,
  formErrors,
  setFormErrors,
  gamePk
}) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const nameField = useRef();
  const emailField = useRef();
  const { name, email } = formErrors || {};
  const { t } = useTranslation();

  useEffect(() => {
    if (invalidEmailInfo?.length > 0) {
      const { name, email } = formData || {};
      if (name === '' && email === '') {
        !isBtnDisabled && setIsBtnDisabled(true);
      } else if (email === '' || email === invalidEmailInfo[0].recipientAddress) {
        setIsBtnDisabled(true);
      } else {
        setIsBtnDisabled(false);
      }
    }
  }, [formData, invalidEmailInfo, isBtnDisabled]);

  useEffect(() => {
    if (name && nameField?.current) {
      nameField.current.focus();
    } else if (email && emailField?.current) {
      emailField.current.focus();
    }
  }, [name, email]);

  useEffect(() => {
    if (invalidEmailInfo?.length > 0) {
      let ids = [];
      invalidEmailInfo.forEach((ticket) => ids.push(ticket.ticketId));
      setFormData({
        name: invalidEmailInfo[0].recipientName,
        email: invalidEmailInfo[0].recipientAddress,
        ticketIds: ids
      });
    }
  }, [invalidEmailInfo, setFormData]);

  const handleInputChange = useCallback(
    (e) => {
      let { name, value } = e.target;
      if (formErrors[name] && value) {
        setFormErrors({ [name]: '' });
      }
      setFormData({
        ...formData,
        [name]: value
      });
    },
    [formData, formErrors, setFormData, setFormErrors]
  );

  const handleSubmit = useCallback(
    (e, leftBtnClicked) => {
      let isValid = true;
      const validateInvalidEmailForm = (formData) => {
        Object.keys(formData).forEach((key) => {
          if (formData[key] === '') {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Required_field') };
            });
            isValid = false;
          } else if (key === 'email' && !emailValidator.test(formData[key])) {
            setFormErrors((formErrors) => {
              return { ...formErrors, [key]: t('Email_invalid') };
            });
            isValid = false;
          }
        });
      };
      e.preventDefault();
      userEvent({
        event: 'modal-click',
        action: {
          element: {
            index: '1',
            text: leftBtnClicked ? 'remove recipient (invalid email)' : 'save changes and retry (invalid email)'
          }
        },
        page: {
          app: {
            name: 'bulk ticket management'
          },
          gamePk: gamePk,
          subPage: {
            title: 'shared tickets'
          },
          title: 'group tickets',
          type: 'ticket display'
        },
        modal: {
          title: 'invalid email',
          option: {
            indexTotal: '1'
          }
        }
      });
      if (leftBtnClicked) {
        handleRemoveBtnClick(); // Per standup, we don't need validation for removals
        setHasBeenEdited(true);
      } else {
        validateInvalidEmailForm(formData);
        if (isValid) {
          handleRetryBtnClick();
          setHasBeenEdited(true);
        }
      }
    },
    [formData, gamePk, handleRemoveBtnClick, handleRetryBtnClick, setFormErrors, setHasBeenEdited, t]
  );

  return (
    <>
      <div className="invalid_email_modal_header">
        <h4 id="invalid_email_modal_title">{t('Invalid_Email')}</h4>
        <button
          id="invalid_email_modal_close_btn"
          aria-label={t('Modal_Close_Btn')}
          className="modal_close"
          type="button"
          onClick={handleCloseBtnClick}
        >
          <img className="modal_close_icon" src={CloseBtn} alt={t('Modal_Close_Btn')} />
        </button>
      </div>
      <form className="invalid_email_form">
        <div className="input_cont">
          <label className={'invalid_email_modal_label' + (name ? ' error' : '')} htmlFor="invalid_email_person_name">
            {t('Name')}
          </label>
          <div className="input_wrapper">
            <input
              id="invalid_email_person_name"
              className={'invalid_email_modal_input' + (name ? ' error' : '')}
              name="name"
              value={formData?.name}
              aria-label={t('Name')}
              aria-required={true}
              onChange={handleInputChange}
              ref={nameField}
              aria-invalid={name ? true : false}
              aria-describedby="invalid_email_name_error"
            />
            {name && (
              <span className="validationError">
                <WarningIcon />
              </span>
            )}
          </div>
          {name && (
            <span id="invalid_email_name_error" className="form_error_message" role="alert">
              {name}
            </span>
          )}
        </div>
        <div className="input_cont">
          <label className={'invalid_email_modal_label' + (email ? ' error' : '')} htmlFor="invalid_email_person_email">
            {t('Email')}
          </label>
          <div className="input_wrapper">
            <input
              id="invalid_email_person_email"
              className={'invalid_email_modal_input' + (email ? ' error' : '')}
              name="email"
              value={formData?.email}
              aria-label={t('Email')}
              aria-required={true}
              onChange={handleInputChange}
              ref={emailField}
              aria-invalid={email ? true : false}
              aria-describedby="invalid_email_email_error"
            />
            {email && (
              <span className="validationError">
                <WarningIcon />
              </span>
            )}
          </div>
          {email && (
            <span id="invalid_email_email_error" className="form_error_message" role="alert">
              {email}
            </span>
          )}
        </div>
        <div className="modal_footer form_footer">
          <button
            className="modal_footer_left_btn"
            id="invalid_email_modal_footer_left_btn"
            aria-label="remove recipient"
            onClick={(e) => handleSubmit(e, true)}
          >
            {t('Remove_Person_Btn')}
          </button>
          <button
            className="modal_footer_right_btn"
            disabled={isBtnDisabled}
            id="invalid_email_modal_footer_right_btn"
            aria-label="save changes and retry"
            onClick={(e) => handleSubmit(e, false)}
          >
            {t('Save_Changes_and_Retry')}
          </button>
        </div>
      </form>
    </>
  );
};

export default InvalidEmailModalMain;
