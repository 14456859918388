import { forwardRef } from 'react';

const ModalOverlay = forwardRef((props, ref) => {
  return (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  );
});

export default ModalOverlay;
