import { ReactComponent as CheckboxImg } from '../../assets/images/Checkbox.svg';
import { ReactComponent as CheckboxCheckedImg } from '../../assets/images/Checkbox-checked.svg';
import './checkbox.scss';

function Checkbox({ handleCheckClick, isChecked, checkBoxId = '' }) {
  return (
    <label className="checkbox">
      <input type="checkbox" onClick={handleCheckClick} value={isChecked} {...(checkBoxId ? { id: checkBoxId } : {})} />
      {isChecked ? (
        <CheckboxCheckedImg data-testid="checked" aria-hidden="true" />
      ) : (
        <CheckboxImg data-testid="unchecked" aria-hidden="true" />
      )}
    </label>
  );
}

export default Checkbox;
