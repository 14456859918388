import { useSelector } from 'react-redux';
import { selectLogout } from '../../../features/logout/logoutSlice';
import { ReactComponent as Refresh } from '../../../assets/images/Refresh.svg';
import { userEvent } from '../../../utils/analytics';
import './Icons.scss';
import { t } from 'i18next';

export const RefreshIcon = (props) => {
  const logoutOpened = useSelector(selectLogout);

  const handleClick = () => {
    userEvent({
      event: 'button-click',
      action: {
        element: {
          index: 0,
          text: 'refresh',
          target: '/events'
        }
      }
    });
    props.refetch();
  };

  return (
    <button
      id="events_refresh_btn"
      aria-label={t('Refresh')}
      type="button"
      className="event_refresh_btn"
      tabIndex={logoutOpened ? -1 : 0}
      onClick={handleClick}
    >
      <Refresh />
    </button>
  );
};
