import React from 'react';
import './Loading.scss';

const Loading = () => {
  return (
    <div data-testid="loading-spinner" className="lds-spinner-container">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
};

export default Loading;
